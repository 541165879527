import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { LegumeInterface } from "../../../_api/_legumes"
import TextHelper from "./TextHelper"

interface VuePlantationProps {
    legume: LegumeInterface
}
const VuePlantation = ({ legume }: VuePlantationProps) => {
    return (
        <Box>
            {legume.plantation && <TextHelper value={legume.plantation} divider />}
            <Grid container spacing={2}>
                {legume.plantationDate && ((legume.plantationDate.du && legume.plantationDate.du.m) || (legume.plantationDate.au && legume.plantationDate.au.m))
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Date</Typography>
                        {(legume.plantationDate.du && legume.plantationDate.du.m)
                            ? <Typography variant="body2" component="span">du {
                                new Intl.DateTimeFormat('fr-FR', { month: 'long', day: legume.plantationDate.du.j ? 'numeric' : undefined }).format(new Date(
                                    new Date().getFullYear(),
                                    legume.plantationDate.du.m,
                                    legume.plantationDate.du.j || 1
                                ))
                            } </Typography>
                            : ''}
                        {(legume.plantationDate.au && legume.plantationDate.au.m)
                            ? <Typography variant="body2" component="span">au {
                                new Intl.DateTimeFormat('fr-FR', { month: 'long', day: legume.plantationDate.au.j ? 'numeric' : undefined }).format(new Date(
                                    new Date().getFullYear(),
                                    legume.plantationDate.au.m,
                                    legume.plantationDate.au.j || 1
                                ))
                            }</Typography>
                            : ''}
                    </Grid>
                    : ''}
                {legume.plantationSaisons && legume.plantationSaisons.length > 0
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Saison</Typography>
                        <Typography variant="body2">{legume.plantationSaisons.map((item, index) => item.value + (index + 1 < legume.plantationSaisons.length ? ', ' : ''))}</Typography>
                    </Grid>
                    : ''}
                {legume.plantationTasks && legume.plantationTasks.length > 0
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Travaux avant plantation</Typography>
                        <Typography variant="body2">{legume.plantationTasks.map((item, index) => item.value + (index + 1 < legume.plantationTasks.length ? ', ' : ''))}</Typography>
                    </Grid>
                    : ''}

                {legume.arrosageFreq || legume.arrosageVol
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Arrosage</Typography>
                        <Typography variant="body2">{legume.arrosageVol && `${legume.arrosageVol}L`}{legume.arrosageFreq && ` ${legume.arrosageFreq ? 't' : 'T'}ous les ${legume.arrosageFreq}j`}</Typography>
                    </Grid>
                    : ''}
            </Grid>
        </Box>
    )
}

export default VuePlantation