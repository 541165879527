import { CarnetInterface, ListItemInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    post = (): Promise<Response> => this._fetch.post(`/api/sante/carnets`)
    getAll = (): Promise<Response> => this._fetch.get(`/api/sante/carnets`)

    get = (id: CarnetInterface['id']): Promise<Response> => this._fetch.get(`/api/sante/carnets/${id}`)
    put = (data: CarnetInterface): Promise<Response> => this._fetch.put(`/api/sante/carnets/${data.id}`, data)
    delete = (id: CarnetInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/carnets/${id}`)
    toggleEi = (journalId: CarnetInterface['id'], listId: ListItemInterface['id']): Promise<Response> => this._fetch.get(`/api/sante/carnets/journaux/${journalId}/list/${listId}`)

}