import { ArticleInterface, JournalInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    post = (journalId: JournalInterface['id']): Promise<Response> => this._fetch.post(`/api/sante/carnets/journaux/${journalId}/articles`)

    put = async (articleId: ArticleInterface['id'], data: Partial<ArticleInterface>): Promise<Response> => this._fetch.put(`/api/sante/carnets/journaux/articles/${articleId}`, data)

    delete = (articleId: ArticleInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/carnets/journaux/articles/${articleId}`)

    // put = (data: CarnetInterface): Promise<Response> => this._fetch.put(`/api/sante/carnets/${data.id}`, data)

}