import { BehaviorSubject, map } from "rxjs"
import { ListItemInterface, ListsInterface } from "../useApp"
import Idb from "../__api_v2/_Idb"
import Http from './http/lists'
import React from "react"


export const Lists$ = new BehaviorSubject<ListItemInterface[]>([])

export default class ApiConstantes {
    private _idb!: Idb<'lists'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('lists')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Lists$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: ListItemInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Lists$.next(data)))
                }
            }))
    }
}

export const useLists = () => {
    const [lists, setLists] = React.useState<ListsInterface>({ ei: [], mdpType: [], siteType: [], jardin: { legumeType: [], exigences: [], }, full: [] })

    React.useEffect(() => {
        Lists$
            .pipe(
                map((items) => items.sort((a, b) => `${a.value} ${a.secondary}`.toLocaleLowerCase() < `${b.value} ${b.secondary}`.toLocaleLowerCase() ? -1 : 1)),
                map((items) => ({
                    ei: items.filter(i => i.type === 'ei'),
                    mdpType: items.filter(i => i.type === 'mdpType'),
                    siteType: items.filter(i => i.type === 'siteType'),
                    jardin: {
                        legumeType: items.filter(i => i.type === 'jarLegType'),
                        exigences: items.filter(i => i.type === 'jarExi'),
                    },
                    full: items
                }))
            )
            .subscribe(l => setLists(l))

    }, [])

    return lists

}

// namespace ApiConstantes { /* empty */ }