import { TabPanel } from "@mui/lab";
import { Box, Grid, TextField, InputAdornment, Rating, Typography } from "@mui/material";
import React from "react"
import AppContext from "../../../AppContext";
import { CuisineInterface } from "../../../_api/_cuisines"
import ImageUploader from "../../ImageUploader";
import { SelectCustomEnum } from "../../SelectCustom";
import NbPerson from "../NbPerson";

interface TabDefaultProps {
    data: CuisineInterface;
    setData: (cuisine: CuisineInterface) => void
}
const TabDefault = ({ data, setData }: TabDefaultProps) => {
    const { api } = React.useContext(AppContext)

    const handleChangeName = (event: any) => setData({ ...data, name: event.target.value })
    const handleChangeType = (value: string) => setData({ ...data, type: value as CuisineInterface['type'] })
    const handleChangeFacilite = (newValue: number | null) => setData({ ...data, facilite: (newValue && newValue.toString() as CuisineInterface['facilite']) || null })
    const handleChangePersonne = (nb: number) => setData({ ...data, personne: nb })
    const handleChangeDescription = (event: any) => setData({ ...data, description: event.target.value })
    const handleChangePrepTime = (event: any) => setData({ ...data, preparationTime: event.target.value || null })
    const handleChangeCookTime = (event: any) => setData({ ...data, cookingTime: event.target.value || null })

    return (
        <TabPanel value="Généralitées" sx={{ maxHeight: '100%' }}>

            {
                data &&
                <Box>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <TextField
                                label="Nom de la recette"
                                fullWidth
                                value={data.name}
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <SelectCustomEnum
                                label={"Type de recette"}
                                value={data.type || null}
                                options={['Apéritif', 'Entrée', 'Plat', 'Sauce', 'Boisson', 'Petit-déjeuner', 'Dessert']}
                                onChange={handleChangeType} />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                            <Typography component="legend">Facilitée</Typography>
                            <Rating
                                value={(data.facilite && +data.facilite) || null}
                                onChange={(event, newValue) => {
                                    handleChangeFacilite(newValue);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3} >
                            <Box justifyContent="center">
                                <NbPerson nbPers={data.personne} handleChange={handleChangePersonne} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} xl={2}>
                            <TextField
                                label="Temps de préparation"
                                fullWidth
                                multiline
                                value={data.preparationTime || ''}
                                onChange={handleChangePrepTime}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">min</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} xl={2}>
                            <TextField
                                label="Temps de cuisson"
                                fullWidth
                                multiline
                                value={data.cookingTime || ''}
                                onChange={handleChangeCookTime}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">min</InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                fullWidth
                                multiline
                                value={data.description || ''}
                                onChange={handleChangeDescription}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ImageUploader data={data} callApi={api.cuisine} />
                        </Grid>
                    </Grid>
                </Box>
            }
        </TabPanel>
    )
}

export default TabDefault