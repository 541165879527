import { BehaviorSubject } from "rxjs"
import Http from './http/chimiques'
import { ListItemInterface } from "../useApp";
import Idb from "../__api_v2/_Idb"

export interface ChimiqueInterface {
    id: string;
    name: string;
    imageId: string | null;
    type: string;
    ensoleil: string;
    composition: { K: number | null, N: number | null, P: number | null };
    modesUsage: ListItemInterface[];
    momentsUsage: ListItemInterface[];
}

export const Chimiques$ = new BehaviorSubject<ChimiqueInterface[]>([])
export const Chimique$ = new BehaviorSubject<ChimiqueInterface | null>(null)

export default class ApiChimique {
    private _idb!: Idb<'chimiques'>
    private _http!: Http
    React: any;
    constructor() {
        this._idb = new Idb('chimiques')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Chimiques$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: ChimiqueInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Chimiques$.next(data)))
                }
            }))
    }

    get = (id: ChimiqueInterface['id']): void => {
        this._idb.get(id).then(data => data && Chimique$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: ChimiqueInterface) => {
                    this._idb.post(data)
                    Chimique$.next(data)
                })
            }
        })
    }

    post = (): Promise<ChimiqueInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((engrais: ChimiqueInterface) => {
                this._idb.post(engrais);
                Chimique$.next(engrais)
                return engrais.id
            })
        } else {
            return null;
        }
    }).catch(() => null)

    put = (id: ChimiqueInterface['id'], purin: Partial<ChimiqueInterface>): Promise<number> => this._http.put(id, purin).then(resp => {
        if (resp.status === 200) {
            const newEngrais = { ...(Chimique$.getValue() as ChimiqueInterface), ...purin }
            this._idb.post(newEngrais)
            Chimique$.next(newEngrais)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: ChimiqueInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newEngrais = { ...(Chimique$.getValue() as ChimiqueInterface), imageId: id }
                    this._idb.post(newEngrais)
                    Chimique$.next(newEngrais)
                })
            }
        })
    }

    deleteImage = async (id: ChimiqueInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newEngrais = { ...(Chimique$.getValue() as ChimiqueInterface), imageId: null }
            this._idb.post(newEngrais)
            Chimique$.next(newEngrais)
        }
    })

    delete = async (id: ChimiqueInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Chimique$.next(null)
            return true
        }
        return false
    })
}