import { Add, Language, Person, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Grid, LinearProgress, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import AppContext from "../../AppContext";
import { SiteInterface, UserDbInterface, UserInterface } from "../../useApp";
import { Site$ } from "../../_api/_sites";
import { Users$ } from "../../_api/_users";
import Page from "../Page/Page";
import AddContent from "./AddContent";
import EditSite from "./EditSite";
import UserSelector from "./UserSelector";
import VueFile from "./VueFile";
import VueItem from "./VueItem";

const VueSite = () => {
    const params = useParams()
    const { api, user } = React.useContext(AppContext)
    const [data, setData] = React.useState<SiteInterface>()
    const [users, setUsers] = React.useState<UserDbInterface[]>()
    const [adder, toggleAdder] = React.useReducer(s => !s, false)
    const [selectedUser, setSelectedUser] = React.useState<UserDbInterface['id']>()
    const [edit, toggleEdit] = React.useReducer(s => !s, false)
    const [showOld, toggleShowOld] = React.useReducer(s => !s, false)
    const inputElement = React.createRef<HTMLInputElement>()

    const handleFileOpen = () => {
        inputElement && inputElement.current && inputElement.current.click()
    }

    const uploadFile = (event: any) => {
        let file = event.target.files[0]
        event.target.value = ''
        api.sites.uploadFile((data as SiteInterface).id, file)
    }

    const navigateTo = (url: SiteInterface['url'] | SiteInterface['urlConnect']) => {
        const w = window.open(url.startsWith('http') ? url : `http://${url}`, '_blank')
        if (w) w.focus()
    }

    const handleSelectedUser = (id: UserDbInterface['id']) => setSelectedUser(id)

    React.useEffect(() => {
        Site$.subscribe(d => d && setData(d))
        api.sites.get(params.id as string)
        Users$.subscribe(u => {
            if (u && u.length > 0) {
                setUsers(u)
                if (!selectedUser) setSelectedUser((u.find(m => m.email === (user as UserInterface).email) as UserDbInterface).id)
            }
        })
        api.users.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page
            title={{
                title: data ? data.name : "Vue du site",
                subtitle: data && data.categories ? data.categories.map((c, i) => c.value).join(', ') : undefined,
                toggleEdit: toggleEdit,
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            loading={!data}
        >
            {!data
                ? <LinearProgress />
                : <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <EditSite site={data} open={edit} toggleOpen={toggleEdit} />
                        <ButtonGroup size="small" aria-label="small button group">
                            {data.url && <Button title={data.url} onClick={() => navigateTo(data.url)}><Language />  Site web</Button>}
                            {data.urlConnect && <Button title={data.urlConnect} onClick={() => navigateTo(data.urlConnect)} ><Person />  Espace client</Button>}
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box>
                            {users && users.length > 0 && selectedUser
                                ? <UserSelector selectedUser={selectedUser} setSelectedUser={handleSelectedUser} validUsers={data.contents.map(c => c.ownerId)} />
                                : <LinearProgress />}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Button color="primary" variant="outlined" onClick={toggleAdder}>
                                <Add />  Ajouter un item
                            </Button>
                            <Tooltip title={"Afficher/masquer les valeurs non utilisées."} >
                                <ToggleButtonGroup
                                    value={showOld}
                                    exclusive
                                    onChange={toggleShowOld}
                                    color="primary"
                                    size="small"
                                >
                                    <ToggleButton value={true} size="small">
                                        <Visibility />
                                    </ToggleButton>
                                    <ToggleButton value={false} size="small">
                                        <VisibilityOff />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Tooltip>

                        </Box>
                        <Grid container spacing={2} direction="column" sx={{ mt: 2 }}>
                            {data.contents && data.contents.length > 0 && selectedUser
                                ? data.contents.filter(u => u.ownerId === selectedUser)
                                    .filter(item => showOld === true || item.disable === false)
                                    .map(item => <VueItem item={item} key={item.id} />)
                                : <Typography variant="caption">Aucun élement</Typography>}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button color="primary" variant="outlined" onClick={handleFileOpen}>
                            <Add />  Ajouter un fichier
                            <input ref={inputElement} type="file" hidden accept=".pdf,image/*" onChange={uploadFile} />
                        </Button>
                        <Box>
                            {data.files && data.files.length > 0
                                ? data.files.map(file => <VueFile file={file} key={file.id} />)
                                : <Typography variant="caption">Aucun fichier</Typography>}
                        </Box>
                    </Grid>
                    <AddContent open={adder} toggleOpen={toggleAdder} siteId={data.id} selectedUser={selectedUser as UserDbInterface['id']} />
                </Grid>}
        </Page>
    )
}

export default VueSite