import React from "react"
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material"
import { ListItemInterface, ListItemType } from "../useApp"
import { Lists$ } from "../_api/_lists"

interface SelectCustomProps {
    label: string
    value: ListItemInterface['id'] | null
    type: ListItemType
    onChange: (option: ListItemInterface) => void
}
const SelectCustom = ({ label, value, type, onChange }: SelectCustomProps) => {
    const options: ListItemInterface[] = Lists$.getValue().filter(o => o.type === type);

    const handleChange = (event: any) => {
        const { target: { value: localValue } } = event
        if (localValue === -1) return;

        const newValue = options.find(o => o.id === localValue) as ListItemInterface
        if (newValue) onChange(newValue);
    }
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || ''}
                label={label}
                onChange={handleChange}
            >
                {options.sort((a, b) => a.value.toLocaleLowerCase() < b.value.toLocaleLowerCase() ? -1 : 1).map(o => <MenuItem key={o.id} value={o.id}>{o.value}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

interface SelectCustomEnumProps {
    label: string
    value: string | null
    options: string[]
    onChange: (option: string) => void
    maxOptions?: number
}
export const SelectCustomEnum = ({ label, value, options, onChange, maxOptions = undefined }: SelectCustomEnumProps) => {

    const handleChange = (event: any) => {
        const { target: { value: localValue } } = event
        onChange(localValue)
    }

    const MenuProps = { style: { maxHeight: maxOptions ? (maxOptions + 1) * 36 : undefined, } }
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || ''}
                label={label}
                onChange={handleChange}
                MenuProps={MenuProps}
            >
                {options.map((o, i) => <MenuItem key={i} value={o}>{o}</MenuItem>)}
            </Select>
        </FormControl>
    )
}


interface SelectCustomMultipleProps {
    label: string
    value: ListItemInterface['id'][] | null;
    type: ListItemType;
    onChange: (option: ListItemInterface[]) => void;
}
export const SelectCustomMultiple = ({ label, value, type, onChange }: SelectCustomMultipleProps) => {
    const options: ListItemInterface[] = Lists$.getValue().filter(o => o.type === type);

    const handleChange = (event: any) => {
        const { target: { value: localValue } } = event
        if (localValue.includes(-1)) return;
        const newValue: ListItemInterface[] = []
        localValue.map((v: ListItemInterface['id']) => newValue.push(options.find(o => o.id === v) as ListItemInterface))
        onChange(newValue);
    }

    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value || []}
                label={label}
                onChange={handleChange}
                multiple
            >
                {options.map(o => <MenuItem key={o.id} value={o.id}>{o.value}</MenuItem>)}
            </Select>
        </FormControl>
    )
}

export default SelectCustom