interface DateHelperInterface {
}

class DateHelper implements DateHelperInterface {
    #date!: Date

    constructor(...args: any[]) {
        if (args.length === 0) {
            this.#date = new Date()
        } else if (args.length === 1) {
            this.#date = new Date(args[0])
        } else {
            this.#date = new Date(args[0], args[1], args[2] ?? 0, args[3] ?? 0, args[4] ?? 0, args[5] ?? 0, args[6] ?? 0)
        }
    }

    static now = Date.now()

    value() {
        return this.#date
    }

    setTime(h: number = 0, m: number = 0, s: number = 0, ms: number = 0) {
        let localDate = new Date(this.#date)
        localDate.setHours(h);
        localDate.setMinutes(m);
        localDate.setSeconds(s);
        localDate.setMilliseconds(ms);
        this.#date = localDate
        return this;
    }

    getWeekNumber(d: Date = this.#date) {
        const initialDate = d;
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        //@ts-ignore
        var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);

        if (weekNo >= 52 && initialDate.getMonth() === 0) {
            weekNo = 0;
        }

        // Return array of year and week number
        return weekNo;
    }

    toString() {
        return this.#date.toISOString()
    }

    info() {
        return {
            date: this.#date,
            year: this.#date.getFullYear(),
            month: this.#date.getMonth() + 1,
            week: this.getWeekNumber(),
            month_name: new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(this.#date),
            day: this.#date.getDate(),
            day_name: new Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(this.#date),
            hour: this.#date.getHours(),
            minute: this.#date.getMinutes(),
            second: this.#date.getSeconds(),
            ms: this.#date.getMilliseconds(),
            iso: this.toString(),
            msTimeStamp: this.#date.getTime()
        }
    }
}

export default DateHelper