import { useSensors, useSensor, PointerSensor, KeyboardSensor, DndContext, closestCenter } from "@dnd-kit/core";
import { sortableKeyboardCoordinates, arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Add, DragHandle, Delete } from "@mui/icons-material";
import { List, ListSubheader, Box, Tooltip, IconButton, ListItem, ListItemText, Menu, MenuItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import React from "react";
import AppContext from "../AppContext";
import { ListItemInterface } from "../useApp";


interface SortableListProps {
    title: string
    activeItems: ListItemInterface[]
    setItems: (items: ListItemInterface[]) => void
}
const SortableList = ({ title, activeItems: items, setItems }: SortableListProps) => {
    const { lists } = React.useContext(AppContext)
    const possibleItems = lists.full.filter(l => l.type === 'jarTask' && !items.map(a => a.id).includes(l.id))
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const addItem = (item: ListItemInterface) => setItems([...items, item])
    const deleteItem = (item: ListItemInterface) => setItems(items.filter(f => f.id !== item.id))

    const handleDragEnd = (event: any) => {
        const { active: { data: { current: { sortable: { index: oldIndex } } } }, over: { data: { current: { sortable: { index: newIndex } } } } } = event;
        if (oldIndex !== newIndex) setItems(arrayMove(items, oldIndex, newIndex))
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
        >
            <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
            >
                <List
                    subheader={<ListSubheader>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            {title}
                            {possibleItems.length > 0 && <Box>
                                <Tooltip title="Ajouter une tâche…">
                                    <IconButton onClick={handleClick}>
                                        <Add />
                                    </IconButton>
                                </Tooltip>
                            </Box>}
                            <TasksListMenu items={possibleItems} anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} addItem={addItem} />
                        </Box>

                    </ListSubheader>}
                >
                    {items.map(item => <TaskItem item={item} key={item.id} deleteItem={deleteItem} />)}
                    {items.length === 0 && <ListItem><ListItemText secondary="Aucune tâche…" /></ListItem>}
                </List>
            </SortableContext>
        </DndContext>
    )
}
interface TasksListMenuProps {
    items: ListItemInterface[]
    anchorEl: null | HTMLElement
    handleClose: () => void
    addItem: (item: ListItemInterface) => void
}
const TasksListMenu = ({ items, anchorEl, handleClose, addItem }: TasksListMenuProps) => {
    const open = Boolean(anchorEl)
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {items.map((a) => <MenuItem key={a.id} onClick={() => addItem(a)}>{a.value}</MenuItem>)}
        </Menu>
    )
}

interface TaskItemProps {
    item: ListItemInterface
    deleteItem: (item: ListItemInterface) => void
}
const TaskItem = ({ item, deleteItem }: TaskItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: item.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };
    return (
        <ListItem ref={setNodeRef} style={style} {...attributes} >
            <ListItemIcon className="hoverContainer">
                <DragHandle {...listeners} />
            </ListItemIcon>
            <ListItemText primary={item.value} />
            <ListItemSecondaryAction className="hoverContainer" style={style} >
                <IconButton color="error" onClick={() => deleteItem(item)}>
                    <Delete />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );


}

export default SortableList