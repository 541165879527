import { UserDbInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    getAll = (): Promise<Response> => this._fetch.get(`/api/users`)

    putMe = (data: Partial<UserDbInterface>): Promise<Response> => this._fetch.put(`/api/users/me`, data)

    uploadMeImage = (form: FormData): Promise<Response> => this._fetch.upload(`/api/users/me`, form)

    deleteMeImage = (): Promise<Response> => this._fetch.delete(`/api/users/me`)


    // get = (id: UserDbInterface['id']): Promise<Response> => this._fetch.get(`/api/users/${id}`)
    // put = (id: UserDbInterface['id'], data: Partial<UserDbInterface>): Promise<Response> => this._fetch.put(`/api/users/${id}`, data)

    // uploadFile = (id: UserDbInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`/api/users/${id}/files`, form)
    // deleteFile = (siteId: UserDbInterface['id'], fileId: FileInterface['id']): Promise<Response> => this._fetch.delete(`/api/users/${siteId}/files/${fileId}`)

    // uploadImage = (id: UserDbInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`/api/users/${id}`, form)
    // deleteImage = (id: UserDbInterface['id']): Promise<Response> => this._fetch.get(`/api/users/${id}/delete-image`)

    // setCategories = (siteId: UserDbInterface['id'], categoriesId: ListItemInterface['id'][]): Promise<Response> => this._fetch.post(`/api/users/${siteId}/categories`, true, { categoriesId })

    // addCategorie = (siteId: UserDbInterface['id'], categorieId: ListItemInterface['id']): Promise<Response> => this._fetch.post(`/api/users/${siteId}/categories/${categorieId}`)
    // removeCategorie = (siteId: UserDbInterface['id'], categorieId: ListItemInterface['id']): Promise<Response> => this._fetch.delete(`/api/users/${siteId}/categories/${categorieId}`)

    // post = (): Promise<Response> => this._fetch.post(`/api/sante/constantes`)
    // delete = (id: UserDbInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/constantes/${id}`)

}