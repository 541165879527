import { Close } from "@mui/icons-material";
import { AppBar, Box, Button, CircularProgress, Dialog, Grid, IconButton, Paper, TextField, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { Ingredient$, IngredientInterface } from "../../../_api/_ingredients";
import ImageUploader from "../../ImageUploader";
import Page from "App/Components/Page/Page";

const EditIngredient = () => {
    const params = useParams<'id'>()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [ingredient, setIngredient] = React.useState<IngredientInterface>()

    const hasChange = () => {
        const originalData = Ingredient$.getValue();
        if (!originalData || !ingredient) return false;
        if (originalData.name !== ingredient.name) return true
        return false
    }

    const handleSave = () => {
        const originalData = Ingredient$.getValue();
        const newData: Partial<IngredientInterface> = {}
        if (!originalData || !ingredient) return false;
        if (originalData.name !== ingredient.name) newData.name = ingredient.name
        api.cuisine.ingredients.put(ingredient.id, newData)

    }

    const handleChangeName = (event: any) => ingredient && setIngredient({ ...ingredient, name: event.target.value })

    React.useEffect(() => {
        Ingredient$.subscribe(i => i && setIngredient(i))
        api.cuisine.ingredients.get(params.id as IngredientInterface['id'])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <Page
            title={{
                title: ingredient?.name || 'Edition d\'un ingrédient'
            }}
            loading={false}
            footer={{
                handleSave: handleSave,
                disabledSave: hasChange(),
                handleCancel: () => navigate('/cuisine/ingredients'),
            }}
        >
            {ingredient && <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Nom de l'ingrédient"
                        value={ingredient.name}
                        onChange={handleChangeName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ImageUploader data={ingredient} callApi={api.cuisine.ingredients} />
                    {ingredient.imageId && <ImageModifier open={false} imageId={ingredient.imageId} />}
                </Grid>
            </Grid>}

        </Page>
    )
}

interface ImageModifierProps {
    open: boolean;
    imageId: string;
}

const ImageModifier = ({ open, imageId }: ImageModifierProps) => {
    const [loading, setLoading] = React.useState<boolean>(true)
    const [timeStamp, setTimeStamp] = React.useState<number>(new Date().getTime())
    const handleClose = () => { }
    const onLoad = () => setLoading(false)
    const simulate = () => {
        setLoading(true)
        setTimeStamp(new Date().getTime())
    }
    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Sound
                    </Typography>
                    <Button autoFocus color="inherit" onClick={handleClose}>
                        save
                    </Button>
                </Toolbar>
            </AppBar>
            <Box sx={{ flexGrow: 1, display: 'flex', flexWrap: 'nowrap', flexDirection: 'row', p: 2 }}>
                <Box sx={{ flexGrow: 1, maxWidth: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                    <Box component={Paper} sx={{ position: 'relative' }}>
                        <img src={`/download/${imageId}?time=${timeStamp}`} alt={'titre'} style={{ objectFit: 'contain', maxWidth: '100%' }} onLoad={onLoad} />
                        {loading &&
                            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, opacity: .6, backgroundColor: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <CircularProgress />
                            </Box>}
                    </Box>
                </Box>
                <Box sx={{ flexGrow: 1, maxWidth: '50%', display: 'flex', flexDirection: 'column' }}>
                    <Button onClick={simulate}>Simulate</Button>
                </Box>
            </Box>
        </Dialog>
    )
}
export default EditIngredient