import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import { TableCell, Tooltip, Box, Typography } from "@mui/material"

interface CellProps {
    value: string | number | null
    min?: number
    max?: number
}
const Cell = ({ value, min, max }: CellProps) => {
    return (
        <TableCell align="center">
            <Tooltip title={`norme: ${min} < value < ${max}`} disableHoverListener={!min || !max}>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center' }}>
                    {value && max && value > max ? <KeyboardArrowUp color="error" /> : ''}
                    {value && min && value < min ? <KeyboardArrowDown color="error" /> : ''}
                    {value && <Typography >{value}</Typography>}
                </Box>
            </Tooltip>
        </TableCell>
    )
}

export default Cell