import React from "react"
import { TabPanel } from "@mui/lab"
import { Grid, TextField } from "@mui/material"
import { ListItemInterface } from "../../../../useApp"
import { LegumeInterface } from "../../../../_api/_legumes"
import { SelectCustomMultiple } from "../../../SelectCustom"

interface TabGenProps {
    legume: LegumeInterface
    setLegume: (l: LegumeInterface) => void
}
const TabSemis = ({ legume, setLegume }: TabGenProps) => {

    const handleSemisSaisonChange = (options: ListItemInterface[]) => setLegume({ ...legume, semisSaisons: options })
    const handleSemisTypeChange = (options: ListItemInterface[]) => setLegume({ ...legume, semisTypes: options })
    const handleChangeSemisProf = (event: any) => setLegume({ ...legume, semisProf: +event.target.value })
    const handleChangeSemisTemp = (event: any) => setLegume({ ...legume, semisTemp: +event.target.value })
    const handleChangeCondCulture = (event: any) => setLegume({ ...legume, condCulture: event.target.value })

    return (
        <TabPanel value="Semis">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Informations libres"
                        multiline
                        fullWidth
                        value={legume.condCulture || ''}
                        onChange={handleChangeCondCulture}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectCustomMultiple label="Saison de semis" value={legume.semisSaisons.map(s => s.id)} type="jarSaison" onChange={handleSemisSaisonChange} />
                </Grid>
                <Grid item xs={6}>
                    <SelectCustomMultiple label="Type de semis" value={legume.semisTypes.map(s => s.id)} type="jarSemType" onChange={handleSemisTypeChange} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Profondeur de semis"
                        fullWidth
                        defaultValue={legume.semisProf || ''}
                        onChange={handleChangeSemisProf}
                        type="number"
                        inputProps={{
                            min: 0,
                            step: 1,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Température de germination"
                        fullWidth
                        defaultValue={legume.semisTemp || ''}
                        type="number"
                        inputProps={{
                            step: 1,
                        }}
                        onChange={handleChangeSemisTemp}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default TabSemis