import { Chat } from "@mui/icons-material"
import { TableCell, Tooltip, Typography } from "@mui/material"
import { ConstanteInterface } from "App/_api/_constantes"
import React from "react"
import Cell from "./Cell"
import { avr, PAm } from "./DataTable"

interface GroupeRowDataCellsProps {
    constantes: ConstanteInterface[]
}
const GroupeRowDataCells = ({ constantes }: GroupeRowDataCellsProps) => {

    const comments = constantes.map(c => c.value.comment).filter(c => c)

    const pniGs = avr(constantes.map(c => c.value.pni.g.s))
    const pniGd = avr(constantes.map(c => c.value.pni.g.d))
    const poulsG = avr(constantes.map(c => c.value.pouls.g))
    const pniDs = avr(constantes.map(c => c.value.pni.d.s))
    const pniDd = avr(constantes.map(c => c.value.pni.d.d))
    const poulsD = avr(constantes.map(c => c.value.pouls.d))
    const masse = avr(constantes.map(c => c.value.masse))
    const temp = avr(constantes.map(c => c.value.temp))

    const ecartG = pniGs && pniGd ? pniGs - pniGd : ''
    const ecartD = pniDs && pniDd ? pniDs - pniDd : ''

    const PamG = PAm(pniGs ?? 0, pniGd ?? 0) ?? null
    const PamD = PAm(pniDs ?? 0, pniDd ?? 0) ?? null


    return (
        <React.Fragment>
            <Cell value={pniGs} min={100} max={140} />
            <Cell value={pniGd} min={80} max={90} />
            <Cell value={poulsG} min={50} max={80} />
            <TableCell />
            <Cell value={pniDs} min={100} max={140} />
            <Cell value={pniDd} min={80} max={90} />
            <Cell value={poulsD} min={50} max={80} />
            <TableCell />
            <Cell value={masse} />
            <Cell value={temp} min={36} max={37.5} />
            <TableCell />
            <Cell value={ecartG} />
            <Cell value={ecartD} />
            <TableCell />
            <Cell value={PamG ?? null} min={60} max={100} />
            <Cell value={PamD ?? null} min={60} max={100} />
            <TableCell>
                {comments.length > 0 &&
                    <Tooltip title={<React.Fragment>
                        {comments.map(comment => <Typography key={comment}>{comment}</Typography>)}
                    </React.Fragment>}>
                        <Chat />
                    </Tooltip>}
            </TableCell>
        </React.Fragment>
    )
}

export default GroupeRowDataCells