import React from "react";

import { IconButton, Toolbar, Typography } from "@mui/material";
import { Menu } from '@mui/icons-material'
import AppContext from "../AppContext";

const Header = () => {
    const { ui } = React.useContext(AppContext)
    return (
        <Toolbar>
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => ui.drawer.toggle()}
                edge="start"
                sx={{ mr: 2, ...(ui.drawer.open && { display: 'none' }) }}
            >
                <Menu />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
                Famille Lucas
            </Typography>
        </Toolbar>
    )
}

export default Header