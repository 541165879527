
import React from "react";
import LandingPage from "../LandingPage";

const chiniqueImage = require('../../assets/chimique.jpg')
const engraisImage = require('../../assets/engrais.jpg')
const fleursImage = require('../../assets/fleurs.jpg')
const legumesImage = require('../../assets/legumes.jpg')
const purinsImage = require('../../assets/purins.jpg')

const LandingJardin = () =>
    <LandingPage
        title="Jardin"
        tuiles={[
            {
                url: "/jardin/engrais",
                img: engraisImage,
                primary: "Engrais"
            },
            {
                url: "/jardin/fleurs",
                img: fleursImage,
                primary: "Fleurs"
            },
            {
                url: "/jardin/legumes",
                img: legumesImage,
                primary: "Legumes"
            },
            {
                url: "/jardin/produits-chimiques",
                img: chiniqueImage,
                primary: "Produits chimiques"
            },
            {
                url: "/jardin/purins",
                img: purinsImage,
                primary: "Purins"
            }
        ]}
    />
export default LandingJardin