import { FileInterface, ListItemInterface, SiteInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    getAll = (): Promise<Response> => this._fetch.get(`/api/sites`)
    get = (id: SiteInterface['id']): Promise<Response> => this._fetch.get(`/api/sites/${id}`)
    put = (id: SiteInterface['id'], data: Partial<SiteInterface>): Promise<Response> => this._fetch.put(`/api/sites/${id}`, data)
    post = (): Promise<Response> => this._fetch.post(`/api/sites`)

    uploadFile = (id: SiteInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`/api/sites/${id}/files`, form)
    deleteFile = (siteId: SiteInterface['id'], fileId: FileInterface['id']): Promise<Response> => this._fetch.delete(`/api/sites/${siteId}/files/${fileId}`)

    uploadImage = (id: SiteInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`/api/sites/${id}`, form)
    deleteImage = (id: SiteInterface['id']): Promise<Response> => this._fetch.get(`/api/sites/${id}/delete-image`)

    setCategories = (siteId: SiteInterface['id'], categoriesId: ListItemInterface['id'][]): Promise<Response> => this._fetch.post(`/api/sites/${siteId}/categories`, true, { categoriesId })

    // addCategorie = (siteId: SiteInterface['id'], categorieId: ListItemInterface['id']): Promise<Response> => this._fetch.post(`/api/sites/${siteId}/categories/${categorieId}`)
    // removeCategorie = (siteId: SiteInterface['id'], categorieId: ListItemInterface['id']): Promise<Response> => this._fetch.delete(`/api/sites/${siteId}/categories/${categorieId}`)

    delete = (id: SiteInterface['id']): Promise<Response> => this._fetch.delete(`/api/sites/${id}`)

}