import { BehaviorSubject } from "rxjs"
import { FileInterface, ListItemInterface, SiteInterface } from "../useApp"
import Idb from "../__api_v2/_Idb"
import Http from './http/sites'
import ApiSiteContents from "./_sitesContents"
import { Lists$ } from "./_lists"

export const Sites$ = new BehaviorSubject<SiteInterface[]>([])
export const Site$ = new BehaviorSubject<SiteInterface | null>(null)

export default class ApiSites {
    private _idb!: Idb<'sites'>
    private _http!: Http
    contents!: ApiSiteContents
    constructor() {
        this._idb = new Idb('sites')
        this._http = new Http()
        this.contents = new ApiSiteContents()
    }



    getAll = (): void => {
        this._idb.getAll().then(data => Sites$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: SiteInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Sites$.next(data)))
                }
            }))
    }
    get = (id: SiteInterface['id']): void => {
        this._idb.get(id).then(data => data && Site$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: SiteInterface) => {
                    this._idb.post(data)
                    Site$.next(data)
                })
            }
        })
    }

    post = async (): Promise<string> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((data: SiteInterface) => {
                this._idb.post(data)
                return data.id
            })
        } else {
            return ''
        }
    }).catch(() => '')

    put = (id: SiteInterface['id'], data: Partial<SiteInterface>): void => {
        const oldData = Site$.getValue();
        this._http.put(id, data).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((newData: SiteInterface) => {
                    Site$.next(newData)
                    this._idb.post(newData)
                })
            } else {
                Site$.next(oldData)
            }
        })
    }

    delete = async (id: SiteInterface['id']): Promise<Boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Sites$.next((Sites$.getValue() as SiteInterface[]).filter(f => f.id !== id))
        }
        return resp.status === 204
    })

    deleteFile = (id: FileInterface['id']) => this._http.deleteFile((Site$.getValue() as SiteInterface).id, id).then(resp => {
        if (resp.status === 204) {
            Site$.next({ ...(Site$.getValue() as SiteInterface), files: (Site$.getValue() as SiteInterface).files.filter(f => f.id !== id) })
        }
    })

    uploadFile = (id: SiteInterface['id'], file: File) => {
        let form = new FormData();
        form.append('file', file)
        this._http.uploadFile(id, form).then((resp) => {
            if (resp.status === 200) {
                resp.json().then((file: FileInterface) => {
                    let newSite = { ...Site$.getValue() as SiteInterface, Files: [...(Site$.getValue() as SiteInterface).files, file] }
                    this._idb.post(newSite)
                    Site$.next(newSite)
                })
            }
        })
    }

    uploadImage = (id: SiteInterface['id'], file: File) => {
        var form = new FormData()
        form.append('file', file)
        this._http.uploadImage(id, form).then((resp) => {
            if (resp.status === 200) resp.json().then(resp => {
                const newSite = { ...Site$.getValue() as SiteInterface }
                newSite.imageId = resp
                this._idb.post(newSite)
                Site$.next(newSite)
            })
        })
    }

    deleteImage = (id: SiteInterface['id']) => this._http.deleteImage(id).then((resp) => {
        if (resp.status === 204) {
            const newSite = { ...Site$.getValue() as SiteInterface }
            newSite.imageId = ''
            this._idb.post(newSite)
            Site$.next(newSite)
        }
    })

    setCategories = (siteId: SiteInterface['id'], categorieIds: ListItemInterface['id'][]) => {
        let newCategories = Lists$.getValue().filter(c => categorieIds.includes(c.id))
        Site$.next({ ...Site$.getValue() as SiteInterface, categories: newCategories })
        this._http.setCategories(siteId, categorieIds)
            .then(resp => {
                if (resp.status === 200) {
                    resp.json().then((site: SiteInterface) => {
                        this._idb.post(site)
                        Site$.next(site)
                    })
                }
            })
    }
}

// namespace ApiConstantes { /* empty */ }