import { BehaviorSubject } from "rxjs"
import Idb from "../__api_v2/_Idb"
import Http from './http/legumes'
import { ListItemInterface } from "../useApp";
import { FleurInterface } from "./_fleurs";

export interface LegumeInterface {
    id: string;
    name: string;
    latin: string;
    variety: string;
    imageId: string | null;
    exigence: string;
    type: string;
    description: string;
    condCulture: string;
    plantation: string;
    entretiens: string;
    protection: string;
    recolte: string;
    semisTypes: ListItemInterface[],
    semisSaisons: ListItemInterface[],
    semisDate: LegumeDate,
    semisProf: number;
    semisTemp: number;
    plantationDate: LegumeDate,
    plantationSaisons: ListItemInterface[],
    plantationTasks: ListItemInterface[],
    arrosageFreq: number;
    arrosageVol: number;
    legumesAmis: LegumeInterface[];
    fleursAmies: FleurInterface[];
    legumesEnnemis: LegumeInterface[];
}

interface LegumeDate {
    du: { j: number, m: number };
    au: { j: number, m: number };
}


export const Legumes$ = new BehaviorSubject<LegumeInterface[]>([])
export const Legume$ = new BehaviorSubject<LegumeInterface | null>(null)

export default class ApiLegumes {
    private _idb!: Idb<'legumes'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('legumes')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Legumes$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: LegumeInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Legumes$.next(data)))
                }
            }))
    }
    get = (id: LegumeInterface['id']): void => {
        this._idb.get(id).then(data => data && Legume$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: LegumeInterface) => {
                    this._idb.post(data)
                    Legume$.next(data)
                })
            }
        })
    }
    post = (): Promise<LegumeInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((el: LegumeInterface) => {
                this._idb.post(el);
                Legume$.next(el)
                return el.id
            })
        } else {
            return null;
        }
    }).catch(() => null)
    put = (id: LegumeInterface['id'], legume: Partial<LegumeInterface>): Promise<number> => this._http.put(id, legume).then(resp => {
        if (resp.status === 200) {
            const newLegume = { ...(Legume$.getValue() as LegumeInterface), ...legume }
            this._idb.post(newLegume)
            Legume$.next(newLegume)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: LegumeInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newLegume = { ...(Legume$.getValue() as LegumeInterface), imageId: id }
                    this._idb.post(newLegume)
                    Legume$.next(newLegume)
                })
            }
        })
    }
    deleteImage = async (id: LegumeInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newLegume = { ...(Legume$.getValue() as LegumeInterface), imageId: null }
            this._idb.post(newLegume)
            Legume$.next(newLegume)
        }
    })

    delete = async (id: LegumeInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Legume$.next(null)
            return true
        }
        return false
    })
}


namespace ApiLegumes { /* empty */ }