import React from "react"
import { LegumeInterface } from "../../../_api/_legumes"
import TextHelper from "./TextHelper"

interface VueRecolteProps {
    legume: LegumeInterface
}
const VueRecolte = ({ legume }: VueRecolteProps) => {
    return (
        <>
            {legume.recolte && <TextHelper value={legume.recolte} divider />}
        </>
    )
}

export default VueRecolte