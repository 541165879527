import { Grid, Typography } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import Page from "../Page/Page"
import ListItemCard, { ListItemCardProps } from "./ListItemCard"

interface CommonListInterface {
    data: any[] | undefined
    title: string,
    image: string,
    apiCall: any
    type: ListItemCardProps['type']
}
const CommonList = ({
    data,
    title, image,
    apiCall,
    type
}: CommonListInterface) => {
    const navigate = useNavigate()
    const [search, setSearch] = React.useState<string>('')
    const [sort, setSort] = React.useState<'asc' | 'desc'>('asc')
    const sortOrder = sort === 'asc' ? -1 : 1

    return (
        <Page
            title={{
                title: title,
                subtitle: 'Jardin',
                imageUrl: image,
            }}
            loading={!data}
            searchable={{
                sortOrder: sort,
                changeSortOrder: setSort,
                search, setSearch,
                add: () => apiCall.post().then((id: string) => navigate(`/jardin/${type}/${id}`))
            }}
        >
            {data &&
                <Grid container spacing={2}>
                    {data.length === 0 && <Typography variant="caption">Aucun élément</Typography>}
                    {data
                        .filter(el => search ? el.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) : true)
                        .sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? sortOrder : -sortOrder)
                        .map((el) => <ListItemCard key={el.id} produit={el} type={type} />)}
                </Grid>}
        </Page>
    )
}

export default CommonList