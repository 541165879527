import { Box, Card, CardActionArea, CardContent, CardMedia, Collapse, Grid, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../AppContext";
import { IngredientInterface, Ingredients$ } from "../../../_api/_ingredients";
import Page from "../../Page/Page";
import { SelectCustomEnum } from "../../SelectCustom";

const ingredientDefault = require('../../../assets/ingredientDefault.webp')

const ListIngredients = () => {
    const { api } = React.useContext(AppContext)
    const [ingredients, setIngredients] = React.useState<IngredientInterface[]>()
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc')
    const [search, setSearch] = React.useState<string>('')
    const [letters, setLetters] = React.useState<string[]>()
    const [letter, setLetter] = React.useState<string>()
    const isMd = useMediaQuery('(min-width:900px)')

    React.useEffect(() => {
        Ingredients$.subscribe(i => {
            if (i) {
                setIngredients(i)
                setLetters(i.map((ingredient) => ingredient.name[0].toLocaleUpperCase()).filter((l, i, s) => s.indexOf(l) === i).sort((a, b) => a < b ? -1 : 1))
            }
        })
        api.cuisine.ingredients.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (letters && letters.length > 0) setLetter(letters[0])
    }, [letters])

    const order = sortOrder === 'asc' ? -1 : 1

    const filteredIngredients = ingredients && letter ?
        search && search.length > 2
            ? ingredients.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? order : -order)
                .filter(i => i.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
            : ingredients.sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? order : -order)
                .filter(ingredient => ingredient.name.toLocaleUpperCase().startsWith(letter))
        : []

    return (
        <Page
            title={{ title: "Liste des ingrédients" }}
            loading={!filteredIngredients}
            searchable={{
                sortOrder: sortOrder, changeSortOrder: setSortOrder,
                search: search, setSearch: setSearch,
                customRenderPosition: isMd ? 'newLine' : 'afterSort',
                customRender: letters && letter ? <Collapse in={search === ""}>
                    <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                        <SelectCustomEnum label={""} value={letter} options={letters} onChange={setLetter} />
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                        <ToggleButtonGroup exclusive value={letter} onChange={(e, n) => n && setLetter(n)} fullWidth>
                            {letters.map(letter => <ToggleButton value={letter} key={letter}>{letter}</ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </Collapse> : undefined
            }}
        >

            {filteredIngredients && filteredIngredients.length === 0 && <Typography variant="caption" color="text.secondary">Aucun ingrédient…</Typography>}


            <Grid container spacing={2}>
                {filteredIngredients
                    .map(ingredient =>
                        <Grid item key={ingredient.id} xs={6} sm={4} md={3} lg={2} >
                            <Card sx={{ m: 1, height: 'unset' }} >
                                <CardActionArea component={Link} to={`/cuisine/ingredients/${ingredient.id}`}>
                                    <CardMedia
                                        component="img"
                                        height={100}
                                        sx={{ objectFit: 'contain' }}
                                        image={(ingredient.imageId && `/download/${ingredient.imageId}`) || ingredientDefault}
                                    />
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="div">{ingredient.name}</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>)}
            </Grid>
        </Page>
    )
}

export default ListIngredients