import { LaboInterface } from "App/_api/_labos";
import { DBSchema, openDB, StoreNames } from "idb";
import { CarnetInterface, ListItemInterface, SiteInterface, UserDbInterface } from "../useApp";
import { ChimiqueInterface } from "../_api/_chimiques";
import { ConstanteInterface } from "../_api/_constantes";
import { CuisineInterface } from "../_api/_cuisines";
import { EngraisInterface } from "../_api/_engrais";
import { FleurInterface } from "../_api/_fleurs";
import { IngredientInterface } from "../_api/_ingredients";
import { LegumeInterface } from "../_api/_legumes";
import { PurinInterface } from "../_api/_purins";

interface DB extends DBSchema {
    'carnets': {
        key: CarnetInterface['id'];
        value: CarnetInterface;
    };
    'chimiques': {
        key: ChimiqueInterface['id'];
        value: ChimiqueInterface;
    };
    'constantes': {
        key: ConstanteInterface['id'];
        value: ConstanteInterface;
    };
    'cuisines': {
        key: CuisineInterface['id'];
        value: CuisineInterface;
    };
    'engrais': {
        key: EngraisInterface['id'];
        value: EngraisInterface;
    };
    'fleurs': {
        key: FleurInterface['id'];
        value: FleurInterface;
    };
    'labos': {
        key: LaboInterface['id'];
        value: LaboInterface;
    }
    'ingredients': {
        key: IngredientInterface['id'];
        value: IngredientInterface;
    };
    'legumes': {
        key: LegumeInterface['id'];
        value: LegumeInterface;
    };
    'lists': {
        key: ListItemInterface['id'];
        value: ListItemInterface;
    };
    'purins': {
        key: PurinInterface['id'];
        value: PurinInterface;
    };
    'sites': {
        key: SiteInterface['id'];
        value: SiteInterface;
    };
    'users': {
        key: UserDbInterface['id'];
        value: UserDbInterface;
    };
};

type DBNames = StoreNames<DB>

const db = openDB<DB>('fam-lucas.fr', 2, {
    upgrade(db, oldVersion) {
        if (oldVersion < 1) {

            db.createObjectStore('carnets', {
                keyPath: 'id',
            });
            db.createObjectStore('chimiques', {
                keyPath: 'id',
            });
            db.createObjectStore('constantes', {
                keyPath: 'id',
            });
            db.createObjectStore('cuisines', {
                keyPath: 'id',
            });
            db.createObjectStore('engrais', {
                keyPath: 'id',
            });
            db.createObjectStore('fleurs', {
                keyPath: 'id',
            });
            db.createObjectStore('ingredients', {
                keyPath: 'id',
            });
            db.createObjectStore('legumes', {
                keyPath: 'id',
            });
            db.createObjectStore('lists', {
                keyPath: 'id',
            });
            db.createObjectStore('purins', {
                keyPath: 'id',
            });
            db.createObjectStore('sites', {
                keyPath: 'id',
            });
            db.createObjectStore('users', {
                keyPath: 'id',
            });
        } else if (oldVersion <= 2) {
            db.createObjectStore('labos', {
                keyPath: 'id'
            })
        }

    },
});

type Id<T extends DBNames> = DB[T]['key']
type EntityType<T extends DBNames> = DB[T]['value']

class Idb<T extends DBNames>{
    #db = db
    #storeName!: DBNames
    constructor(entityName: T) {
        this.#storeName = entityName
    }

    async getAll(): Promise<EntityType<T>[] | []> {
        return (await this.#db).getAll(this.#storeName) || []
    }

    async get(id: Id<T>): Promise<EntityType<T> | undefined> {
        return (await this.#db).get(this.#storeName, id)
    }

    async post(item: EntityType<T>) {
        return (await this.#db).put(this.#storeName, item)
    }

    async posts(items: EntityType<T>[]) {
        const tx = (await db).transaction(this.#storeName, 'readwrite');
        const store = tx.store;
        store.clear()
        await Promise.all([
            ...items.map(item => store.put(item)),
            tx.done
        ])
    }

    async delete(id: Id<T>) {
        return (await this.#db).delete(this.#storeName, id)
    }

    async clear() {
        return (await this.#db).clear(this.#storeName);
    }

}

export default Idb