import React from "react";
import darkTheme from "./dark";
import lightTheme from "./light";
import { useThemesInterface } from "./useTheme";

const ThemeContext = React.createContext<ReturnType<useThemesInterface>>({
    theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? darkTheme : lightTheme,
    name: window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
    toggle: () => { }
})

export default ThemeContext