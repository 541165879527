import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { LegumeInterface, Legume$ } from "../../../../_api/_legumes"
import Page from "../../../Page/Page"
import TabAmisEnnemis from "./TabAmisEnnemis"
import TabGen from "./TabGen"
import TabPlantation from "./TabPlantation"
import TabRecolte from "./TabRecolte"
import TabSemis from "./TabSemis"

const VueLegume = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<LegumeInterface>()


    const hasChange = () => {
        if (!data) return false
        let oldLegume = Legume$.getValue() as LegumeInterface;
        if (oldLegume.name !== data.name) return true
        if (oldLegume.latin !== data.latin) return true
        if (oldLegume.variety !== data.variety) return true
        if (oldLegume.description !== data.description) return true
        if (oldLegume.type !== data.type) return true
        if (oldLegume.exigence !== data.exigence) return true
        if (oldLegume.semisSaisons.length !== data.semisSaisons.length || oldLegume.semisSaisons.map(s => s.id).join(',') !== data.semisSaisons.map(s => s.id).join(',')) return true
        if (oldLegume.semisTypes.length !== data.semisTypes.length || oldLegume.semisTypes.map(s => s.id).join(',') !== data.semisTypes.map(s => s.id).join(',')) return true
        if (oldLegume.semisProf !== data.semisProf) return true
        if (oldLegume.semisTemp !== data.semisTemp) return true
        if (oldLegume.condCulture !== data.condCulture) return true
        if (oldLegume.plantationSaisons.length !== data.plantationSaisons.length || oldLegume.plantationSaisons.map(s => s.id).join(',') !== data.plantationSaisons.map(s => s.id).join(',')) return true
        if (oldLegume.arrosageFreq !== data.arrosageFreq) return true
        if (oldLegume.arrosageVol !== data.arrosageVol) return true
        if (oldLegume.plantationTasks.length !== data.plantationTasks.length || oldLegume.plantationTasks.map(s => s.id).join(',') !== data.plantationTasks.map(s => s.id).join(',')) return true
        if (oldLegume.plantation !== data.plantation) return true
        if (oldLegume.recolte !== data.recolte) return true
        if (oldLegume.protection !== data.protection) return true
        if (oldLegume.legumesAmis.length !== data.legumesAmis.length || oldLegume.legumesAmis.map(s => s.id).join(',') !== data.legumesAmis.map(s => s.id).join(',')) return true
        if (oldLegume.legumesEnnemis.length !== data.legumesEnnemis.length || oldLegume.legumesEnnemis.map(s => s.id).join(',') !== data.legumesEnnemis.map(s => s.id).join(',')) return true
        if (oldLegume.fleursAmies.length !== data.fleursAmies.length || oldLegume.fleursAmies.map(s => s.id).join(',') !== data.fleursAmies.map(s => s.id).join(',')) return true



        return false;
    }

    const handleSave = () => {
        if (!data) return
        let newLegume: Partial<LegumeInterface> = {}
        let oldLegume = Legume$.getValue() as LegumeInterface;
        if (oldLegume.name !== data.name) newLegume.name = data.name
        if (oldLegume.latin !== data.latin) newLegume.latin = data.latin
        if (oldLegume.variety !== data.variety) newLegume.variety = data.variety
        if (oldLegume.description !== data.description) newLegume.description = data.description
        if (oldLegume.type !== data.type) { newLegume.type = data.type }
        if (oldLegume.exigence !== data.exigence) { newLegume.exigence = data.exigence }
        if (oldLegume.semisSaisons.length !== data.semisSaisons.length || oldLegume.semisSaisons.map(s => s.id).join(',') !== data.semisSaisons.map(s => s.id).join(',')) newLegume.semisSaisons = data.semisSaisons
        if (oldLegume.semisTypes.length !== data.semisTypes.length || oldLegume.semisTypes.map(s => s.id).join(',') !== data.semisTypes.map(s => s.id).join(',')) newLegume.semisTypes = data.semisTypes
        if (oldLegume.semisProf !== data.semisProf) newLegume.semisProf = data.semisProf
        if (oldLegume.semisTemp !== data.semisTemp) newLegume.semisTemp = data.semisTemp
        if (oldLegume.condCulture !== data.condCulture) newLegume.condCulture = data.condCulture
        if (oldLegume.plantationSaisons.length !== data.plantationSaisons.length || oldLegume.plantationSaisons.map(s => s.id).join(',') !== data.plantationSaisons.map(s => s.id).join(',')) newLegume.plantationSaisons = data.plantationSaisons
        if (oldLegume.arrosageFreq !== data.arrosageFreq) newLegume.arrosageFreq = data.arrosageFreq
        if (oldLegume.arrosageVol !== data.arrosageVol) newLegume.arrosageVol = data.arrosageVol
        if (oldLegume.plantationTasks.length !== data.plantationTasks.length || oldLegume.plantationTasks.map(s => s.id).join(',') !== data.plantationTasks.map(s => s.id).join(',')) newLegume.plantationTasks = data.plantationTasks
        if (oldLegume.plantation !== data.plantation) newLegume.plantation = data.plantation
        if (oldLegume.recolte !== data.recolte) newLegume.recolte = data.recolte
        if (oldLegume.protection !== data.protection) newLegume.protection = data.protection
        if (oldLegume.legumesAmis.length !== data.legumesAmis.length || oldLegume.legumesAmis.map(s => s.id).join(',') !== data.legumesAmis.map(s => s.id).join(',')) newLegume.legumesAmis = data.legumesAmis
        if (oldLegume.legumesEnnemis.length !== data.legumesEnnemis.length || oldLegume.legumesEnnemis.map(s => s.id).join(',') !== data.legumesEnnemis.map(s => s.id).join(',')) newLegume.legumesEnnemis = data.legumesEnnemis
        if (oldLegume.fleursAmies.length !== data.fleursAmies.length || oldLegume.fleursAmies.map(s => s.id).join(',') !== data.fleursAmies.map(s => s.id).join(',')) newLegume.fleursAmies = data.fleursAmies

        api.jardin.legumes.put(data.id, newLegume)
    }

    const handleDelete = () => api.jardin.legumes.delete(params.id as LegumeInterface['id']).then(success => success && navigate(`/jardin/legumes`))

    React.useEffect(() => {
        Legume$.subscribe(l => {
            if (l) {
                setData(l)
            }
        })
        api.jardin.legumes.getAll()
        api.jardin.fleurs.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.legumes.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.variety ? `${data.name} – ${data.variety}` : data.name : "Légume",
                subtitle: data ? data.latin || undefined : "Jardin",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            tabs={['Généralités', 'Semis', 'Plantation', 'Recolte', 'Amis/Ennemis']}
            loading={!data}
            footer={{
                handleSave: handleSave,
                disabledSave: hasChange(),
                handleDelete: handleDelete,
                handleCancel: () => hasChange() ? setData(Legume$.getValue() as LegumeInterface) : navigate(`/jardin/legumes/${params.id}`),
                cancelPlaceholder: hasChange() ? 'Annuler' : 'Retour'
            }}
        >


            {data &&
                <>
                    <TabGen legume={data} setLegume={setData} />
                    <TabSemis legume={data} setLegume={setData} />
                    <TabPlantation legume={data} setLegume={setData} />
                    <TabRecolte legume={data} setLegume={setData} />
                    <TabAmisEnnemis legume={data} setLegume={setData} />
                </>
            }

        </Page>
    )
}

export default VueLegume