import { Table, TableBody, TableContainer, TableHead } from "@mui/material"
import AppContext from "App/AppContext"
import Page from "App/Components/Page/Page"
import { LaboInterface, Labos$ } from "App/_api/_labos"
import React from "react"
import { Examin } from "../Exams"
import FilterTable from "./FilterTable"
import Head from "./Head"
import LaboLine from "./LaboLine"



const List = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<LaboInterface[]>()
    const [sort, setSort] = React.useState<'asc' | 'desc'>('asc')
    const [hidden, setHidden] = React.useState<Examin['id'][]>([])
    const size = "medium"

    const handleSelect = (id: Examin['id']) => {
        setHidden(hidden.includes(id) ? hidden.filter(i => i !== id) : [...hidden, id])
    }


    React.useEffect(() => {
        Labos$.subscribe((l) => {
            if (l) {
                setData(l)
            }
        })
        api.sante.labos.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const sortOp = sort === 'asc' ? -1 : 1
    return (
        <Page
            title={{
                title: 'Biologie'
            }}
            searchable={{
                sortOrder: sort, changeSortOrder: setSort,
                filterEl: (anchor, handleClose) => <FilterTable anchor={anchor} handleClose={handleClose} onSelect={handleSelect} selected={hidden} />
            }}
            loading={!data}
        >
            <TableContainer>

                <Table stickyHeader size={size}>
                    <TableHead><Head selected={hidden} size={size} /></TableHead>
                    <TableBody>
                        {data && data
                            .sort((a, b) => a.date < b.date ? sortOp : -sortOp)
                            .map(labo => <LaboLine key={labo.id} labo={labo} selected={hidden} />)}
                    </TableBody>
                </Table>
            </TableContainer>
        </Page >
    )
}

export default List