import { SiteContentInterface, SiteInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    post = (siteId: SiteInterface['id'], data: Partial<SiteContentInterface>): Promise<Response> => this._fetch.post(`/api/sites/${siteId}/contents`, true, data)

    put = (id: SiteContentInterface['id'], data: Partial<SiteContentInterface>): Promise<Response> => this._fetch.put(`/api/sites/contents/${id}`, data)

    delete = (id: SiteContentInterface['id']): Promise<Response> => this._fetch.delete(`/api/sites/contents/${id}`)

    // getAll = (): Promise<Response> => this._fetch.get(`/api/sites`)
    // get = (id: SiteInterface['id']): Promise<Response> => this._fetch.get(`/api/sites/${id}`)

}