import { BehaviorSubject } from "rxjs"
import Idb from "../__api_v2/_Idb"
import Http from './http/purins'

export interface PurinInterface {
    id: string;
    name: string;
    masse: number;
    dilutionPulv: number;
    dilutionArros: number;
    temp: number;
    imageId: string | null;
}

export const Purins$ = new BehaviorSubject<PurinInterface[]>([])
export const Purin$ = new BehaviorSubject<PurinInterface | null>(null)

export default class ApiPurins {
    private _idb!: Idb<'purins'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('purins')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Purins$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: PurinInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Purins$.next(data)))
                }
            }))
    }

    get = (id: PurinInterface['id']): void => {
        this._idb.get(id).then(data => data && Purin$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: PurinInterface) => {
                    this._idb.post(data)
                    Purin$.next(data)
                })
            }
        })
    }

    post = (): Promise<PurinInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((el: PurinInterface) => {
                this._idb.post(el);
                Purin$.next(el)
                return el.id
            })
        } else {
            return null;
        }
    }).catch(() => null)

    put = (id: PurinInterface['id'], purin: Partial<PurinInterface>): Promise<number> => this._http.put(id, purin).then(resp => {
        if (resp.status === 200) {
            const newPurin = { ...(Purin$.getValue() as PurinInterface), ...purin }
            this._idb.post(newPurin)
            Purin$.next(newPurin)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: PurinInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newPurin = { ...(Purin$.getValue() as PurinInterface), imageId: id }
                    this._idb.post(newPurin)
                    Purin$.next(newPurin)
                })
            }
        })
    }

    deleteImage = async (id: PurinInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newPurin = { ...(Purin$.getValue() as PurinInterface), imageId: null }
            this._idb.post(newPurin)
            Purin$.next(newPurin)
        }
    })

    delete = async (id: PurinInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Purin$.next(null)
            return true
        }
        return false
    })
}


// namespace ApiLegumes { /* empty */ }