import { TabPanel } from "@mui/lab"
import { Grid, TextField } from "@mui/material"
import React from "react"
import AppContext from "../../../../AppContext"
import { LegumeInterface } from "../../../../_api/_legumes"
import ImageUploader from "../../../ImageUploader"
import { SelectCustomEnum } from "../../../SelectCustom"

interface TabGenProps {
    legume: LegumeInterface
    setLegume: (l: LegumeInterface) => void
}
const TabGen = ({ legume, setLegume }: TabGenProps) => {
    const { api } = React.useContext(AppContext)

    const handleChangeName = (event: any) => setLegume({ ...legume, name: event.target.value })
    const handleChangeLatin = (event: any) => setLegume({ ...legume, latin: event.target.value })
    const handleChangeVariety = (event: any) => setLegume({ ...legume, variety: event.target.value })
    const handleChangeDescription = (event: any) => setLegume({ ...legume, description: event.target.value })
    const handleLegumeType = (option: string) => setLegume({ ...legume, type: option })
    const handleLegumeExigence = (option: string) => setLegume({ ...legume, exigence: option })


    return (
        <TabPanel value="Généralités">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="Nom du légume"
                        fullWidth
                        value={legume.name}
                        onChange={handleChangeName}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Nom latin du légume"
                        fullWidth
                        defaultValue={legume.latin}
                        onChange={handleChangeLatin}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Variété du légume"
                        fullWidth
                        defaultValue={legume.variety}
                        onChange={handleChangeVariety}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ImageUploader data={legume} callApi={api.jardin.legumes} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Description du légume"
                        multiline
                        fullWidth
                        value={legume.description || ''}
                        onChange={handleChangeDescription}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectCustomEnum label="Type de légume" value={legume.type} options={['Fleur', 'Feuille', 'Fruit', 'Racine']} onChange={handleLegumeType} />
                </Grid>
                <Grid item xs={6}>
                    <SelectCustomEnum label="Exigence du légume" value={legume.exigence} options={['Faible', 'Moyenne', 'Forte']} onChange={handleLegumeExigence} />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default TabGen