import { Box, Grid, TextField } from "@mui/material"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { FleurInterface, Fleur$ } from "../../../../_api/_fleurs"
import { LegumeInterface } from "../../../../_api/_legumes"
import ImageUploader from "../../../ImageUploader"
import Page from "../../../Page/Page"
import { SelectCustomEnum } from "../../../SelectCustom"
import { ListAmisEnnemisEdit } from "../../ListAmisEnnemis"




const EditFleur = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<FleurInterface>()

    const handleChangeName = (event: any) => setData({ ...(data as FleurInterface), name: event.target.value })
    const handleChangeLatin = (event: any) => setData({ ...(data as FleurInterface), latin: event.target.value })
    const handleChangeVariety = (event: any) => setData({ ...(data as FleurInterface), variety: event.target.value })
    const handleFleurType = (option: string) => setData({ ...(data as FleurInterface), type: option })
    const handleChangeLegumeAmis = (items: LegumeInterface[]) => setData({ ...(data as FleurInterface), legumesAmis: items })

    const hasChange = () => {
        if (!data) return false
        let oldFleur = Fleur$.getValue() as FleurInterface;
        if (oldFleur.name !== data.name) return true
        if (oldFleur.latin !== data.latin) return true
        if (oldFleur.variety !== data.variety) return true
        if (oldFleur.type !== data.type) return true
        if (oldFleur.legumesAmis.length !== data.legumesAmis.length || oldFleur.legumesAmis.map(s => s.id).join(',') !== data.legumesAmis.map(s => s.id).join(',')) return true

        return false;
    }

    const handleSave = () => {
        if (!data) return false
        let newFleur: Partial<FleurInterface> = {}
        let oldFleur = Fleur$.getValue() as FleurInterface;
        if (oldFleur.name !== data.name) newFleur.name = data.name
        if (oldFleur.latin !== data.latin) newFleur.latin = data.latin
        if (oldFleur.variety !== data.variety) newFleur.variety = data.variety
        if (oldFleur.type !== data.type) { newFleur.type = data.type }
        if (oldFleur.legumesAmis.length !== data.legumesAmis.length || oldFleur.legumesAmis.map(s => s.id).join(',') !== data.legumesAmis.map(s => s.id).join(',')) newFleur.legumesAmis = data.legumesAmis

        api.jardin.fleurs.put(data.id, newFleur)
    }
    const handleDelete = () => api.jardin.fleurs.delete(params.id as LegumeInterface['id']).then(success => success && navigate(`/jardin/fleurs`))

    React.useEffect(() => {
        Fleur$.subscribe(l => {
            if (l) {
                setData(l)
            }
        })
        api.jardin.fleurs.getAll()
        api.jardin.legumes.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.fleurs.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{

                title: data ? data.variety ? `${data.name} – ${data.variety}` : data.name : "Fleurs",
                subtitle: data ? data.latin || undefined : "Jardin",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            loading={!data}
            footer={{
                handleSave: handleSave,
                disabledSave: hasChange(),
                handleDelete: handleDelete,
                handleCancel: () => hasChange() ? setData(Fleur$.getValue() as FleurInterface) : navigate(`/jardin/fleurs/${params.id}`),
                cancelPlaceholder: hasChange() ? 'Annuler' : 'Retour'
            }}
        >
            {data &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Nom de la fleur"
                                fullWidth
                                value={data.name}
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Nom latin"
                                fullWidth
                                defaultValue={data.latin}
                                onChange={handleChangeLatin}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Variété"
                                fullWidth
                                defaultValue={data.variety}
                                onChange={handleChangeVariety}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectCustomEnum label="Type de fleur" value={data.type} options={['Anti-ravageur', 'Purin']} onChange={handleFleurType} />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageUploader data={data} callApi={api.jardin.fleurs} />
                        </Grid>
                        <Grid item xs={6}>
                            <ListAmisEnnemisEdit title="Légumes amis" items={data.legumesAmis || []} type="légume" setItems={(items: LegumeInterface[] | FleurInterface[]) => handleChangeLegumeAmis(items as LegumeInterface[])} />
                        </Grid>

                    </Grid>
                </Box>}

        </Page>
    )
}

export default EditFleur