import { Sort, Close, Add, Search, FilterList } from "@mui/icons-material";
import { Box, ToggleButtonGroup, ToggleButton, TextField, InputAdornment, IconButton, Tooltip, Menu } from "@mui/material";
import React from "react";

export interface SearchableHeaderProps {
    sortOrder?: 'asc' | 'desc',
    search?: string,
    searchPlaceholder?: string;
    changeSortOrder?: (sort: Exclude<SearchableHeaderProps['sortOrder'], undefined>) => void
    setSearch?: (s: string) => void
    filter?: JSX.Element[];
    filterEl?: (anchorEl: null | HTMLElement, handleClose: () => void) => JSX.Element;
    filterActive?: boolean;
    add?: () => void
    addPlaceholder?: string
    customRender?: JSX.Element | undefined
    customRenderPosition?: 'start' | 'afterSort' | 'beforeAdd' | 'end' | 'newLine'
}
const SearchableHeader = ({
    sortOrder, changeSortOrder = undefined,
    search, setSearch = undefined, searchPlaceholder = 'Rechercher',
    filter = undefined, filterActive = false, filterEl = undefined,
    add = undefined, addPlaceholder = 'Créer',
    customRender,
    customRenderPosition = 'beforeAdd'
}: SearchableHeaderProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const handleChangeSort = (event: any, newValue: 'asc' | 'desc') => changeSortOrder && newValue && changeSortOrder(newValue)

    const handleMenuFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <>
            <Box sx={{ mb: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                {customRender && customRenderPosition === 'start' && customRender}
                {changeSortOrder && <Box><ToggleButtonGroup
                    value={sortOrder}
                    exclusive
                    onChange={handleChangeSort}
                >
                    <ToggleButton value="asc">
                        <Tooltip title="Trier A-Z">
                            <Sort sx={{ transform: 'scaleX(-1) rotate(180deg)' }} />
                        </Tooltip>
                    </ToggleButton>
                    <ToggleButton value="desc">
                        <Tooltip title="Trier Z-A">
                            <Sort />
                        </Tooltip>
                    </ToggleButton>
                </ToggleButtonGroup></Box>}
                {customRender && customRenderPosition === 'afterSort' && <Box>{customRender}</Box>}
                {setSearch && <TextField
                    sx={{ flexGrow: 1 }}
                    fullWidth
                    variant="outlined"
                    label={searchPlaceholder}
                    value={search}
                    onChange={(event: any) => setSearch(event.target.value)}
                    InputProps={{
                        startAdornment: search ? <InputAdornment position="start">
                            <Search />
                        </InputAdornment> : undefined,
                        endAdornment: search ? <InputAdornment position="end">
                            <IconButton onClick={() => setSearch('')}>
                                <Close />
                            </IconButton>
                        </InputAdornment> : undefined,
                    }}
                />}
                {customRender && customRenderPosition === 'beforeAdd' && customRender}
                {(filter || filterEl) && <Box>
                    <IconButton size="large" onClick={handleMenuFilter} color={filterActive ? 'primary' : undefined}>
                        <FilterList fontSize="large" />
                    </IconButton>
                    {filter && <FilterMenu anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} children={filter} />}
                    {filterEl && filterEl(anchorEl, () => setAnchorEl(null))}

                </Box>}
                {add && <Box>
                    <Tooltip title={addPlaceholder}>
                        <IconButton color="primary" size="large" onClick={add}>
                            <Add />
                        </IconButton>
                    </Tooltip>
                </Box>}
                {customRender && customRenderPosition === 'end' && customRender}
            </Box>
            {customRender && customRenderPosition === 'newLine' && <Box sx={{ mb: 2 }}>{customRender}</Box>}
        </>

    )
}

interface FilterMenuProps {
    anchorEl: null | HTMLElement,
    handleClose: () => void
    children: JSX.Element[]
}
const FilterMenu = ({ anchorEl, handleClose, children }: FilterMenuProps) => {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
        >{children}</Menu>
    )
}


export default SearchableHeader