import { TableRow, TableCell, Box } from "@mui/material"
import DeleteButtonConfirmation from "App/Components/DeleteButtonConfirmation"
import EditButton from "App/Components/EditButton"
import ThemeContext from "App/themes/ThemeContext"
import { LaboInterface, LaboValues } from "App/_api/_labos"
import React from "react"
import { labos } from "../Exams"
import LaboCell from "./LaboCell"

interface LaboLineProps {
    labo: LaboInterface
    selected: LaboValues['type'][]
}
const LaboLine = ({ labo, selected }: LaboLineProps) => {
    const { theme } = React.useContext(ThemeContext)
    const groups = labos.filter(L => L.examins.filter(E => !selected.includes(E.id)).length > 0)
    return (
        <TableRow className="hoverStickyContainer">
            <TableCell sx={{ position: 'sticky', left: 0, background: theme.palette?.background?.default ?? undefined }}>
                {new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date(labo.date))}
            </TableCell>

            {groups.map((G, index) =>
                <React.Fragment key={labo.id + G.id}>
                    {G.examins.filter(E => !selected.includes(E.id)).map(examin =>
                        <LaboCell key={examin.id} labos={labo.LaboValues.filter(V => !selected.includes(V.type))} examin={examin} />)}
                    {index + 1 < labos.length && <TableCell />}
                </React.Fragment>)}
            <TableCell sx={{ right: 0, background: theme.palette?.background?.default ?? undefined }} className="hoverTarget">
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <EditButton to={`/sante/biologie/${labo.id}`} />
                    <DeleteButtonConfirmation
                        rounded
                        onDelete={function (): void {
                            throw new Error("Function not implemented.")
                        }} />
                </Box>
            </TableCell>
        </TableRow>
    )
}
export default LaboLine