
import React from "react";
import { useParams, useNavigate, } from "react-router-dom";
import AppContext from "../../../AppContext";
import { CuisineInterface, Cuisine$ } from "../../../_api/_cuisines";
import Page from "../../Page/Page";
import TabDefault from "./PanelDefault";
import TabIngredients from "./PanelIngredients";
import TabPreparation from "./PanelPreparation";

const CuisineImage = require('../../../assets/cuisine.jpeg')

const EditCuisine = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<CuisineInterface>()

    const hasChanged = (): boolean => {
        if (!data) return false
        const oldCuisine = Cuisine$.getValue() as CuisineInterface

        if (oldCuisine.name !== data.name) return true
        if (oldCuisine.facilite !== data.facilite) return true
        if (oldCuisine.type !== data.type) return true
        if (oldCuisine.description !== data.description) return true
        if (oldCuisine.preparationTime !== data.preparationTime) return true
        if (oldCuisine.cookingTime !== data.cookingTime) return true
        if (oldCuisine.personne !== data.personne) return true
        if (JSON.stringify(oldCuisine.ingredients) !== JSON.stringify(data.ingredients)) return true
        if (JSON.stringify(oldCuisine.preparations) !== JSON.stringify(data.preparations)) return true

        return false
    }

    const handleSave = (): void => {
        if (!data) return
        const oldCuisine = Cuisine$.getValue() as CuisineInterface
        const newCuisine = {} as Partial<CuisineInterface>

        if (oldCuisine.name !== data.name) newCuisine.name = data.name
        if (oldCuisine.name !== data.name) newCuisine.name = data.name
        if (oldCuisine.type !== data.type) newCuisine.type = data.type
        if (oldCuisine.facilite !== data.facilite) newCuisine.facilite = data.facilite
        if (oldCuisine.description !== data.description) newCuisine.description = data.description
        if (oldCuisine.preparationTime !== data.preparationTime) newCuisine.preparationTime = data.preparationTime
        if (oldCuisine.cookingTime !== data.cookingTime) newCuisine.cookingTime = data.cookingTime
        if (oldCuisine.personne !== data.personne) newCuisine.personne = data.personne
        if (JSON.stringify(oldCuisine.ingredients) !== JSON.stringify(data.ingredients)) newCuisine.ingredients = data.ingredients
        if (JSON.stringify(oldCuisine.preparations) !== JSON.stringify(data.preparations)) newCuisine.preparations = data.preparations

        api.cuisine.put(data.id, newCuisine)
    }

    const deleteCuisine = () => api.cuisine.delete(params.id as CuisineInterface['id']).then(success => success && navigate(`/cuisine`))

    const cancelModifications = () => setData(Cuisine$.getValue() as CuisineInterface)

    React.useEffect(() => {
        Cuisine$.subscribe(c => c && setData(c))
    }, [])

    React.useEffect(() => {
        api.cuisine.get(params.id as string)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])

    return (
        <Page
            title={{
                title: data ? data.name : 'Recette de cuisine',
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : CuisineImage,
            }}
            loading={!data}
            overflow="hidden"
            tabs={['Généralitées', 'Ingrédients', 'Préparation']}
            footer={{
                handleSave: handleSave, disabledSave: hasChanged(),
                handleCancel: () => hasChanged() ? cancelModifications() : navigate(`/cuisine/recettes/${params.id}`), cancelPlaceholder: hasChanged() ? 'Annuler' : 'Fermer',
                handleDelete: deleteCuisine, deleteText: ['Vous êtes sur le point de supprimer une recette.', 'Cette action sera définitive…']
            }}
        >
            {data && <>
                <TabDefault data={data} setData={setData} />
                <TabIngredients data={data} setData={setData} />
                <TabPreparation data={data} setData={setData} />
            </>}
        </Page >
    )
}


export default EditCuisine
