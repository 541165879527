import { ArrowBackIosNew, Cancel, Save } from "@mui/icons-material";
import { Box, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { Constante$, ConstanteInterface } from "../../../_api/_constantes";
import Page from "../../Page/Page";

const EditConstante = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<ConstanteInterface>()
    const [saving, setSaving] = React.useState<boolean>(false)
    const pniGs = React.createRef<HTMLInputElement>()
    const pniGd = React.createRef<HTMLInputElement>()
    const poulsG = React.createRef<HTMLInputElement>()
    const pniDs = React.createRef<HTMLInputElement>()
    const pniDd = React.createRef<HTMLInputElement>()
    const poulsD = React.createRef<HTMLInputElement>()
    const masse = React.createRef<HTMLInputElement>()
    const temp = React.createRef<HTMLInputElement>()

    const hasChange = JSON.stringify(Constante$.getValue()) !== JSON.stringify(data);

    const onChangeDate = (event: any) =>
        setData({ ...data as ConstanteInterface, mesureAt: event.target.value as string + 'T' + (data as ConstanteInterface).mesureAt.split('T')[1] })
    const onChangeTime = (event: any) =>
        setData({ ...data as ConstanteInterface, mesureAt: (data as ConstanteInterface).mesureAt.split('T')[0] + 'T' + event.target.value as string })

    const onChangePni = (lat: 'g' | 'd', pres: 's' | 'd', event: any) => setData({ ...data as ConstanteInterface, value: { ...(data as ConstanteInterface).value, pni: { ...(data as ConstanteInterface).value.pni, [lat]: { ...(data as ConstanteInterface).value.pni[lat], [pres]: event.target.value } } } })

    const onChangePouls = (lat: 'g' | 'd', event: any) => setData({ ...data as ConstanteInterface, value: { ...(data as ConstanteInterface).value, pouls: { ...(data as ConstanteInterface).value.pouls, [lat]: event.target.value } } })

    const onChangeMasse = (event: any) => setData({ ...data as ConstanteInterface, value: { ...(data as ConstanteInterface).value, masse: event.target.value } })

    const onChangeTemp = (event: any) => setData({ ...data as ConstanteInterface, value: { ...(data as ConstanteInterface).value, temp: event.target.value } })

    const onChangeComment = (event: any) => setData({ ...data as ConstanteInterface, value: { ...(data as ConstanteInterface).value, comment: event.target.value } })

    const onSave = () => {
        setSaving(true);
        api.sante.constantes.put(data as ConstanteInterface)
    }

    const preventWheelEvent = (event: any) => {
        event.preventDefault()
    }

    React.useEffect(() => {
        if (data) {
            setSaving(false)
        }
    }, [data])

    React.useEffect(() => {
        Constante$.subscribe(data => data && setData(data))
        api.sante.constantes.get(params.id ? params.id : '')

        const localpniGs = pniGs.current ?? null;
        const localpniGd = pniGd.current ?? null;
        const localpoulsG = poulsG.current ?? null;
        const localpniDs = pniDs.current ?? null;
        const localpniDd = pniDd.current ?? null;
        const localpoulsD = poulsD.current ?? null;
        const localmasse = masse.current ?? null;
        const localtemp = temp.current ?? null;
        return () => {
            Constante$.next(null)
            if (localpniGs) localpniGs.removeEventListener('wheel', preventWheelEvent)
            if (localpniGd) localpniGd.removeEventListener('wheel', preventWheelEvent)
            if (localpoulsG) localpoulsG.removeEventListener('wheel', preventWheelEvent)
            if (localpniDs) localpniDs.removeEventListener('wheel', preventWheelEvent)
            if (localpniDd) localpniDd.removeEventListener('wheel', preventWheelEvent)
            if (localpoulsD) localpoulsD.removeEventListener('wheel', preventWheelEvent)
            if (localmasse) localmasse.removeEventListener('wheel', preventWheelEvent)
            if (localtemp) localtemp.removeEventListener('wheel', preventWheelEvent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (pniGs.current) pniGs.current.addEventListener('wheel', preventWheelEvent)
        if (pniGd.current) pniGd.current.addEventListener('wheel', preventWheelEvent)
        if (poulsG.current) poulsG.current.addEventListener('wheel', preventWheelEvent)
        if (pniDs.current) pniDs.current.addEventListener('wheel', preventWheelEvent)
        if (pniDd.current) pniDd.current.addEventListener('wheel', preventWheelEvent)
        if (poulsD.current) poulsD.current.addEventListener('wheel', preventWheelEvent)
        if (masse.current) masse.current.addEventListener('wheel', preventWheelEvent)
        if (temp.current) temp.current.addEventListener('wheel', preventWheelEvent)
    }, [pniGs, pniGd, poulsG, pniDs, pniDd, poulsD, masse, temp])
    return (
        <Page
            title={{
                title: `Edition de constantes`,
                subtitle: data ? new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(data.mesureAt)) : undefined
            }}
            loading={!data || saving}
            footer={{
                handleSave: onSave,
                disabledSave: !data || hasChange || saving,
                savePlaceholder: <><Save fontSize="small" sx={{ mr: 1 }} />Enregistrer</>,
                handleCancel: () => hasChange ? setData(Constante$.getValue() as ConstanteInterface) : navigate('/sante/constantes?' + params.id),
                cancelPlaceholder: hasChange ? <><Cancel fontSize="small" sx={{ mr: 1 }} />Annuler</> : <><ArrowBackIosNew fontSize="small" sx={{ mr: 1 }} />Retour</>,
                disabledCancel: !data || saving,
                handleDelete: () => api.sante.constantes.delete(params.id as string).then(() => navigate('/sante/constantes')),
                disabledDelete: !data || saving,
            }}
        >

            {data &&

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} >
                        <TextField
                            disabled={saving}
                            id="date"
                            label="Date"
                            type="date"
                            defaultValue={data.mesureAt.split('T')[0]}
                            onChange={onChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={saving}
                            id="date"
                            label="Heure"
                            type="time"
                            defaultValue={new Intl.DateTimeFormat('fr-FR', { timeStyle: 'medium' }).format(new Date(data.mesureAt))}
                            onChange={onChangeTime}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ pt: { xs: 1, md: 2, lg: 4, xl: 8 } }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <Typography variant="h6" gutterBottom>Pression arterielle</Typography>
                                    <Typography variant="subtitle1" gutterBottom>Gauche</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                disabled={saving}
                                                ref={pniGs}
                                                value={data.value.pni.g.s || ''}
                                                onChange={(event) => onChangePni('g', 's', event)}
                                                type="number"
                                                fullWidth
                                                label="Systole"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                disabled={saving}
                                                ref={pniGd}
                                                value={data.value.pni.g.d || ''}
                                                onChange={(event) => onChangePni('g', 'd', event)}
                                                type="number"
                                                fullWidth
                                                label="Diastole"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                value={data.value.pni.g.s && data.value.pni.g.d
                                                    ? Math.round((+data.value.pni.g.s + 2 * data.value.pni.g.d) / 3)
                                                    : ''}
                                                disabled
                                                type="number"
                                                fullWidth
                                                label="Moyenne"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle1" gutterBottom>Droite</Typography>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                disabled={saving}
                                                ref={pniDs}
                                                value={data.value.pni.d.s || ''}
                                                onChange={(event) => onChangePni('d', 's', event)}
                                                type="number"
                                                fullWidth
                                                label="Systole"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                ref={pniDd}
                                                disabled={saving}
                                                value={data.value.pni.d.d || ''}
                                                onChange={(event) => onChangePni('d', 'd', event)}
                                                type="number"
                                                fullWidth
                                                label="Diastole"
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <TextField
                                                value={data.value.pni.d.s && data.value.pni.d.d
                                                    ? Math.round((+data.value.pni.d.s + 2 * data.value.pni.d.d) / 3)
                                                    : ''}
                                                disabled
                                                type="number"
                                                fullWidth
                                                label="Moyenne"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Typography variant="h6" gutterBottom>Pouls</Typography>
                                    <Typography variant="subtitle1" gutterBottom>Gauche</Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                disabled={saving}
                                                ref={poulsG}
                                                value={data.value.pouls.g || ''}
                                                onChange={(event) => onChangePouls('g', event)}
                                                type="number"
                                                fullWidth
                                                label="Pouls gauche"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Typography variant="subtitle1" gutterBottom>Droite</Typography>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <TextField
                                                ref={poulsD}
                                                disabled={saving}
                                                value={data.value.pouls.d || ''}
                                                onChange={(event) => onChangePouls('d', event)}
                                                type="number"
                                                fullWidth
                                                label="Pouls droite"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={3}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6" gutterBottom>Masse</Typography>
                                            <Typography variant="subtitle1" gutterBottom>(en kg)</Typography>
                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        ref={masse}
                                                        disabled={saving}
                                                        value={data.value.masse || ''}
                                                        onChange={onChangeMasse}
                                                        type="number"
                                                        fullWidth
                                                        label="Masse"
                                                    />
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6" gutterBottom>Température</Typography>
                                            <Typography variant="subtitle1" gutterBottom>(en °C)</Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} >
                                                    <TextField
                                                        ref={temp}
                                                        disabled={saving}
                                                        value={data.value.temp || ''}
                                                        onChange={onChangeTemp}
                                                        type="number"
                                                        fullWidth
                                                        label="Température"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={{ pt: { xs: 1, md: 2, lg: 4, xl: 8 } }}>
                            <TextField
                                multiline
                                disabled={saving}
                                value={data.value.comment || ''}
                                onChange={onChangeComment}
                                type="number"
                                fullWidth
                                label="Commentaire"
                            />
                        </Box>
                    </Grid>
                </Grid >}
        </Page >
    )
}

export default EditConstante