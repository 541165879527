import { Grid, Avatar, Button } from "@mui/material";
import React from "react";
import ApiChimique from "../_api/_chimiques";
import ApiCuisine from "../_api/_cuisines";
import ApiEngrais from "../_api/_engrais";
import ApiFleurs from "../_api/_fleurs";
import ApiIngredient from "../_api/_ingredients";
import ApiLegumes from "../_api/_legumes";
import ApiPurins from "../_api/_purins";
import ApiUsers from "../_api/_users";

interface ImageUploaderProps {
    data: any;
    callApi: ApiEngrais | ApiFleurs | ApiLegumes | ApiChimique | ApiPurins | ApiCuisine | ApiIngredient | ApiUsers,
    customMethods?: {
        set: (file: File) => void
        delete: () => void
    }
}
const ImageUploader = ({ data, callApi, customMethods = undefined }: ImageUploaderProps) => {
    const inputRef = React.createRef<HTMLInputElement>()

    const handleUploadFile = (event: any) => {
        const file: File = event.target.files[0]
        if (!file) return
        if (customMethods) {
            customMethods.set(file)
        } else {
            (callApi as Exclude<ImageUploaderProps['callApi'], ApiUsers>).uploadImage(data.id, file)
        }
    }

    const handleDeleteImage = () => {
        if (customMethods) {
            customMethods.delete()
        } else {
            (callApi as Exclude<ImageUploaderProps['callApi'], ApiUsers>).deleteImage(data.id as string)
        }
    }

    const openFileDialog = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = ''
            inputRef.current.click()
        }
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={false}>
                <Avatar sx={{ width: 100, height: 100 }} src={data.imageId ? `/download/${data.imageId}` : undefined}>
                    {data.name.includes(' ') ? data.name.split(' ').splice(0, 2).map((s: string) => s[0]).join('').toUpperCase() : data.name[0]}
                </Avatar>
                <input type="file" accept="image/*" hidden ref={inputRef} onChange={handleUploadFile} />
            </Grid>
            <Grid item xs={false}>
                <Grid container spacing={2} direction="column">
                    {!data.imageId
                        ? <Grid item>
                            <Button color="primary" variant="outlined" size="small" onClick={openFileDialog}>Ajouter une image</Button>
                        </Grid>
                        : <Grid item>
                            <Button color="secondary" variant="outlined" size="small" onClick={openFileDialog}>Changer l'image</Button>
                        </Grid>}
                    {data.imageId && <Grid item>
                        <Button color="error" variant="outlined" size="small" onClick={handleDeleteImage}>Supprimer l'image</Button>
                    </Grid>}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ImageUploader