import { BehaviorSubject } from "rxjs"
import { CarnetInterface } from "../useApp"
import Idb from "../__api_v2/_Idb"
import Http from './http/carnets'
import ApiJournals from "./_carnets_journals"

export const Carnets$ = new BehaviorSubject<CarnetInterface[]>([])
export const Carnet$ = new BehaviorSubject<CarnetInterface | null>(null)


export default class ApiCarnets {
    private _idb!: Idb<'carnets'>
    private _http!: Http
    journals!: ApiJournals
    constructor() {
        this._idb = new Idb('carnets')
        this._http = new Http()
        this.journals = new ApiJournals()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Carnets$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: CarnetInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Carnets$.next(data)))
                }
            }))
    }
    get = (id: CarnetInterface['id']): void => {
        this._idb.get(id).then(data => data && Carnet$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: CarnetInterface) => {
                    this._idb.post(data)
                    Carnet$.next(data)
                })
            }
        })
    }
    put = (data: CarnetInterface): void => {
        const oldData = Carnet$.getValue();
        this._http.put(data).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((newData: CarnetInterface) => {
                    Carnet$.next(newData)
                    this._idb.post(newData)
                })
            } else {
                Carnet$.next(oldData)
            }
        })
    }
    post = async (): Promise<CarnetInterface['id'] | null> => this._http.post().then((resp) => {
        if (resp.status === 200) {
            return resp.json().then((json: CarnetInterface) => {
                this._idb.post(json)
                Carnet$.next(json)
                return json.id
            })
        } else {
            return null
        }
    })
    delete = async (id: CarnetInterface['id']): Promise<void> => {
        const oldValue = Carnets$.getValue();
        const newValue = oldValue.filter(f => f.id !== id)
        Carnets$.next([...newValue])
        this._http.delete(id).then(resp => {
            if (resp.status === 204) {
                this._idb.delete(id)
            } else {
                Carnets$.next([...oldValue])
            }
        })
    }

}