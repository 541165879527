
import { useSensors, useSensor, PointerSensor, DndContext, closestCenter, TouchSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'
import { Add, DragHandle, } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import { Box, Table, TableHead, TableRow, TableCell, Typography, Tooltip, IconButton, TableBody, TextField, FormControlLabel, Checkbox, TableContainer } from '@mui/material';
import React from 'react';
import { ListItemInterface } from '../../../useApp';
import { CuisineInterface, CuisineIngredientInterface } from '../../../_api/_cuisines';
import DeleteButtonConfirmation from '../../DeleteButtonConfirmation';
import SelectCustom from '../../SelectCustom';
import IngredientDialog from './IngredientsDialog';

interface TabIngredientsProps {
    data: CuisineInterface;
    setData: (cuisine: CuisineInterface) => void
}
const TabIngredients = ({ data, setData }: TabIngredientsProps) => {
    const [open, setOpen] = React.useState<boolean>(false)
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
        }),
        useSensor(TouchSensor, {

        })
    );


    const handleMenu = () => setOpen(!open)

    const handleChangeValue = (id: CuisineIngredientInterface['id'], value: CuisineIngredientInterface['value']) => setData({
        ...data,
        ingredients: data.ingredients.map(ingredient => ingredient.id === id ? ({ ...ingredient, value }) : ingredient)
    })

    const handleChangeUnit = (id: CuisineIngredientInterface['id'], option: ListItemInterface) => setData({
        ...data,
        ingredients: data.ingredients.map(ingredient => ingredient.id === id ? ({ ...ingredient, unit: option, unitId: option.id }) : ingredient)
    })

    const handleChangeFacultatif = (id: CuisineIngredientInterface['id'], facultatif: CuisineIngredientInterface['facultatif']) => setData({
        ...data,
        ingredients: data.ingredients.map(ingredient => ingredient.id === id ? ({ ...ingredient, facultatif }) : ingredient)
    })

    const handleDragEnd = (event: any) => {
        const { active: { data: { current: { sortable: { index: oldIndex } } } }, over: { data: { current: { sortable: { index: newIndex } } } } } = event;
        if (oldIndex !== newIndex) setData({ ...data, ingredients: arrayMove(data.ingredients, oldIndex, newIndex) })
    }
    // const handleDragEnd = (event: any) => {
    //     const { active: { data: { current: { sortable: { index: oldIndex } } } }, over: { data: { current: { sortable: { index: newIndex } } } } } = event;
    //     if (oldIndex !== newIndex) setData({ ...data, preparations: arrayMove(data.preparations, oldIndex, newIndex) })
    // }

    const handleDeleteIngredient = (id: CuisineIngredientInterface['id']) => setData({ ...data, ingredients: data.ingredients.filter(f => f.id !== id) })

    return (
        <TabPanel value="Ingrédients" sx={{ maxHeight: '100%' }}>
            {data &&
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext
                        items={data.ingredients}
                        strategy={verticalListSortingStrategy}
                    >
                        <TableContainer sx={{ maxHeight: '100%' }}>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size="small" />
                                        <TableCell><Typography variant="subtitle1">Ingredient</Typography></TableCell>
                                        <TableCell><Typography variant="subtitle1">Quantitée</Typography></TableCell>
                                        <TableCell size="small"><Typography variant="subtitle1">Unité</Typography></TableCell>
                                        <TableCell size="small"><Typography variant="subtitle1">Facultatif</Typography></TableCell>
                                        <TableCell size="small">
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <Tooltip title="Ajouter un ingrédient">
                                                    <IconButton color="primary" onClick={handleMenu}>
                                                        <Add />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.ingredients.map(ingredient => <SortableLine key={ingredient.id} ingredient={ingredient} handleChangeValue={handleChangeValue} handleChangeUnit={handleChangeUnit} handleDeleteIngredient={handleDeleteIngredient} handleChangeFacultatif={handleChangeFacultatif} />)}
                                    {data.ingredients.length === 0 && <TableRow><TableCell colSpan={6}><Typography>Aucun ingrédient…</Typography></TableCell></TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </SortableContext>
                </DndContext>}
            <IngredientDialog data={data} setData={setData} handleClose={handleMenu} open={open} />
        </TabPanel>
    )
}
interface SortableLineProps {
    ingredient: CuisineIngredientInterface
    handleChangeValue: (id: CuisineIngredientInterface['id'], value: CuisineIngredientInterface['value']) => void
    handleChangeFacultatif: (id: CuisineIngredientInterface['id'], facultatif: CuisineIngredientInterface['facultatif']) => void
    handleDeleteIngredient: (id: CuisineIngredientInterface['id']) => void
    handleChangeUnit: (id: CuisineIngredientInterface['id'], unit: ListItemInterface) => void
}
const SortableLine = ({ ingredient, handleChangeValue, handleDeleteIngredient, handleChangeFacultatif, handleChangeUnit }: SortableLineProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: ingredient.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleDelete = () => handleDeleteIngredient(ingredient.id)

    return (
        <TableRow key={ingredient.id} ref={setNodeRef} style={style} {...attributes}>
            <TableCell size="small"><DragHandle {...listeners} sx={{ touchAction: 'none' }} /></TableCell>
            <TableCell><Typography variant="subtitle1">{ingredient.ingredient.name}</Typography></TableCell>
            {ingredient.value !== -1 &&
                <TableCell>
                    <TextField
                        type="number"
                        value={ingredient.value || ''}
                        onChange={(event: any) => handleChangeValue(ingredient.id, +event.target.value)}
                        fullWidth
                    />
                </TableCell>}
            <TableCell size="small" colSpan={ingredient.value === -1 ? 2 : undefined}>
                <Typography>{ingredient.ingredient.unit && ingredient.ingredient.unit.value}</Typography>
                <SelectCustom
                    label="Unité de mesure"
                    value={ingredient.unitId || ''}
                    type="cuisineUnit"
                    onChange={(option: any) => handleChangeUnit(ingredient.id, option)}
                />
            </TableCell>
            <TableCell size="small" >
                <FormControlLabel control={<Checkbox checked={ingredient.facultatif} onChange={(event: any) => handleChangeFacultatif(ingredient.id, event.target.checked)} />} label="" />
            </TableCell>
            <TableCell size="small">
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DeleteButtonConfirmation rounded onDelete={handleDelete} toolTip="Supprimer un ingrédient" />
                </Box>
            </TableCell>
        </TableRow>
    )
}

export default TabIngredients