import { createTheme, ThemeOptions } from "@mui/material";

const definition: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#136598',
        },
        secondary: {
            main: '#4bf7e2',
        },
        info: {
            main: '#a12ffd',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: '#136598',
                    color: '#fff',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    boxShadow: 'rgb(0 0 0 / 12%) 1px 0 10px 0',
                    backgroundColor: '#424242'
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(18, 18, 18, .5)',
                    height: '100%'
                }
            }
        },
    },
};
const darkTheme = createTheme(definition)

export default darkTheme