import { BehaviorSubject } from "rxjs"
import Http from './http/cuisines'
import ApiIngredient, { IngredientInterface } from "./_ingredients";
import { ListItemInterface } from "../useApp";
import Idb from "../__api_v2/_Idb"

export interface CuisineInterface {
    id: string;
    imageId: string | null;
    name: string;
    type: 'Apéritif' | 'Entrée' | 'Plat' | 'Sauce' | 'Boisson' | 'Petit-déjeuner' | 'Dessert' | null;
    facilite: '1' | '2' | '3' | '4' | '5' | null;
    personne: number;
    description: string;
    preparationTime: number;
    cookingTime: number;
    ingredients: CuisineIngredientInterface[];
    preparations: CuisinePreparationInterface[];
}
export interface CuisineIngredientInterface {
    id: number;
    value: number | null;
    ingredientId: string;
    ingredient: IngredientInterface;
    facultatif: boolean;
    unitId: string | null;
    unit: null | ListItemInterface;
}
export interface CuisinePreparationInterface {
    id: number;
    value: string;
}

export const Cuisines$ = new BehaviorSubject<CuisineInterface[]>([])
export const Cuisine$ = new BehaviorSubject<CuisineInterface | null>(null)

export default class ApiCuisine {
    private _idb!: Idb<'cuisines'>
    private _http!: Http
    ingredients!: ApiIngredient
    constructor() {
        this._idb = new Idb('cuisines')
        this._http = new Http()
        this.ingredients = new ApiIngredient()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Cuisines$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: CuisineInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Cuisines$.next(data)))
                }
            }))
    }

    get = (id: CuisineInterface['id']): void => {
        this._idb.get(id).then(data => data && Cuisine$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: CuisineInterface) => {
                    this._idb.post(data)
                    Cuisine$.next(data)
                })
            }
        })
    }

    post = (): Promise<CuisineInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((cuisine: CuisineInterface) => {
                this._idb.post(cuisine);
                Cuisine$.next(cuisine)
                return cuisine.id
            })
        }
        return null
    })

    put = (id: CuisineInterface['id'], purin: Partial<CuisineInterface>): Promise<number> => this._http.put(id, purin).then(resp => {
        if (resp.status === 200) {
            const newCuisine = { ...(Cuisine$.getValue() as CuisineInterface), ...purin }
            this._idb.post(newCuisine)
            Cuisine$.next(newCuisine)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: CuisineInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newCuisine = { ...(Cuisine$.getValue() as CuisineInterface), imageId: id }
                    this._idb.post(newCuisine)
                    Cuisine$.next(newCuisine)
                })
            }
        })
    }

    deleteImage = async (id: CuisineInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newCuisine = { ...(Cuisine$.getValue() as CuisineInterface), imageId: null }
            this._idb.post(newCuisine)
            Cuisine$.next(newCuisine)
        }
    })

    delete = async (id: CuisineInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id);
            Cuisine$.next(null);
            return true
        }
        return false
    })
}


namespace ApiCuisine { /* empty */ }