import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { PurinInterface, Purin$ } from "../../../../_api/_purins"
import Page from "../../../Page/Page"



const VuePurin = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<PurinInterface>()

    const handleEdit = () => navigate(`/jardin/purins/${data && data.id}/edit`)

    React.useEffect(() => {
        Purin$.subscribe(l => l && setData(l))
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.purins.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.name : "Purins",
                subtitle: data ? undefined : "Jardin",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
                toggleEdit: handleEdit
            }}
            loading={!data}
        >

            {data &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                {data.masse &&
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Masse pour 10L d'eau</Typography>
                                        <Typography variant="body2">{data.masse}kg</Typography>
                                    </Grid>}
                                {data.temp &&
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Temps de macération</Typography>
                                        <Typography variant="body2">{data.temp}j</Typography>
                                    </Grid>}
                                {data.dilutionPulv &&
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Dilution pour pulvérisation</Typography>
                                        <Typography variant="body2">{data.dilutionPulv}%</Typography>
                                    </Grid>}
                                {data.dilutionArros &&
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h6">Dilution pour arrosage</Typography>
                                        <Typography variant="body2">{data.dilutionArros}%</Typography>
                                    </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                        </Grid>
                    </Grid>
                </Box>}
        </Page>
    )
}

export default VuePurin