import Http from './http/files'


export default class ApiFiles {
    private _http!: Http
    constructor() {
        this._http = new Http()
    }

    // patch = this._http.patch


}


namespace ApiFiles { /* empty */ }