import { Box, Divider, Typography } from "@mui/material";
import React from "react";

interface TextHelperProps {
    value: string
    divider?: boolean
}

const TextHelper = ({ value, divider }: TextHelperProps) => {
    return (
        <Box>
            {value.split("\n").map((desc, index) => <Typography key={index} variant="body1" gutterBottom>{desc}</Typography>)}
            {divider && <Divider />}
        </Box>
    )
}

export default TextHelper