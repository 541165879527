import React from "react"
import { Box, Divider, Button } from "@mui/material"
import DeleteButtonConfirmation from "./../DeleteButtonConfirmation"

export interface FooterButtonsProps {
    handleSave?: () => void
    handleDelete?: () => void
    handleCancel?: () => void
    disabledSave?: boolean
    disabledCancel?: boolean
    disabledDelete?: boolean
    savePlaceholder?: string | JSX.Element
    cancelPlaceholder?: string | JSX.Element
    deleteText?: string[]
    noDivider?: boolean
}
const FooterButtons = ({
    handleSave, handleDelete, handleCancel,
    disabledSave, disabledCancel, disabledDelete,
    savePlaceholder = 'Enregistrer', cancelPlaceholder = 'Annuler',
    deleteText = undefined,
    noDivider
}: FooterButtonsProps) => {
    const margin = { mr: { xs: 1, md: 2 } }
    return (
        <Box sx={{ mt: noDivider ? undefined : 4 }}>
            {!noDivider && <Divider sx={{ mb: 2 }} />}
            {handleSave && <Button color="primary" variant="outlined" onClick={handleSave} disabled={disabledSave === false} sx={margin}>
                {savePlaceholder}
            </Button>}
            {handleCancel && <Button color="secondary" variant="outlined" onClick={handleCancel} disabled={disabledCancel === true} sx={margin}>
                {cancelPlaceholder}
            </Button>}
            {handleDelete && <DeleteButtonConfirmation onDelete={handleDelete} disabled={disabledDelete === true} text={deleteText} />}
        </Box>
    )
}

export default FooterButtons