import { TabContext, TabList } from "@mui/lab";
import { Box, Divider, Paper, Tab } from "@mui/material";
import React from "react";
import FooterButtons, { FooterButtonsProps } from "./FooterButtons";
import PageTitle, { PageTitleProps } from "./PageTitle";
import SearchableHeader, { SearchableHeaderProps } from "./SearchableHeader";

interface PageProps {
    title?: PageTitleProps
    children: (JSX.Element | undefined | false)[] | JSX.Element | undefined | false
    footer?: FooterButtonsProps
    tabs?: string[];
    overflow?: 'hidden' | 'auto';
    loading: boolean
    noMargin?: boolean
    searchable?: SearchableHeaderProps
}
const Page = ({ title, loading, children, footer, overflow = 'auto', tabs = [], noMargin = false, searchable }: PageProps) => {
    const [tabValue, setTabValue] = React.useState(tabs.length > 0 ? tabs[0] : '')

    React.useEffect(() => {
        if (!tabValue && tabs.length > 0) {
            setTabValue(tabs[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabs])

    return (
        <TabContext value={tabValue}>
            <Box sx={{ display: 'flex', flexDirection: 'column', overflowY: { xs: 'auto', md: 'hidden' }, overflowX: 'hidden', flexGrow: 1, justifyContent: 'space-between', margin: noMargin ? -4 : 0 }}>
                {(title || searchable) && <Box sx={{ pl: { xs: 1, md: 2, lg: 4 }, pr: { xs: 1, md: 2, lg: 4 }, mt: 2, elevation: 2 }}>
                    {title && <PageTitle {...{ ...title, loading }} noDivider />}
                    {searchable &&
                        <Box sx={{ mt: 0.6 }}>
                            <SearchableHeader
                                sortOrder={searchable.sortOrder}
                                search={searchable.search}
                                searchPlaceholder={searchable.searchPlaceholder}
                                changeSortOrder={searchable.changeSortOrder}
                                setSearch={searchable.setSearch}
                                filter={searchable.filter}
                                filterEl={searchable.filterEl}
                                filterActive={searchable.filterActive}
                                add={searchable.add}
                                addPlaceholder={searchable.addPlaceholder}
                                customRender={searchable.customRender}
                                customRenderPosition={searchable.customRenderPosition}
                            />
                        </Box>}
                </Box>}
                <Box sx={{ overflowY: 'hidden', overflowX: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column', margin: 0, borderRadius: 0, pl: { xs: 1, md: 2, lg: 4 }, pr: { xs: 1, md: 2, lg: 4 } }} component={Paper}>
                    {tabs.length > 0 &&
                        <TabList onChange={(event: any, value: string) => setTabValue(value)} variant="scrollable">
                            {tabs.map(tab => <Tab key={tab} value={tab} label={tab} />)}
                            <Tab value={''} label={''} sx={{ position: 'fixed', top: -1000 }} />
                        </TabList>}

                    <Box sx={{ overflowY: overflow, overflowX: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column', mb: 1, mt: 1, p: 2 }} >
                        {!loading && children}
                    </Box>
                </Box>
                {footer && <>
                    <Divider />
                    <Box sx={{ p: 2 }}>

                        <FooterButtons
                            handleSave={footer.handleSave} disabledSave={!loading && footer.disabledSave} savePlaceholder={footer.savePlaceholder}
                            handleCancel={footer.handleCancel} disabledCancel={loading || footer.disabledCancel} cancelPlaceholder={footer.cancelPlaceholder}
                            handleDelete={footer.handleDelete} disabledDelete={loading || footer.disabledDelete} deleteText={footer.deleteText}
                            noDivider
                        />
                    </Box>
                </>}
            </Box>
        </TabContext>
    )
}

export default Page