import { Dialog, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Button, Grid, Typography, Box, FormControl, InputLabel, OutlinedInput, Select, SelectChangeEvent } from "@mui/material"
import React from "react"
import { useNavigate } from "react-router-dom"
import AppContext from "../../AppContext"
import { ListItemInterface, SiteInterface } from "../../useApp"
import DeleteButtonConfirmation from "../DeleteButtonConfirmation"

interface EditSiteProps {
    site: SiteInterface
    open: boolean
    toggleOpen: () => void
}

const EditSite = ({ site, open, toggleOpen }: EditSiteProps) => {
    const navigate = useNavigate()
    const { api, lists } = React.useContext(AppContext)
    const [data, setData] = React.useState<SiteInterface>(site)
    const inputFile = React.createRef<HTMLInputElement>()

    const clickInputFile = () => {
        if (inputFile && inputFile.current) {
            inputFile.current.value = '';
            inputFile.current.click()
        }
    }

    const uploadFile = (event: any) => {
        const file = event.target.files[0]
        if (inputFile && inputFile.current) inputFile.current.value = '';
        api.sites.uploadImage(site.id, file)
    }

    const deleteImage = () => api.sites.deleteImage(site.id)

    const changeCat = (event: SelectChangeEvent<ListItemInterface['id'][]>) => {
        let { target: { value } } = event
        let newValue: string[] | number[] | string = value
        if (typeof newValue === 'string') newValue = newValue.split(',')
        api.sites.setCategories(site.id, newValue.map(v => v) as ListItemInterface['id'][])
    }

    const save = () => {
        const newData: Partial<SiteInterface> = {}
        if (site['name'] !== data['name']) newData['name'] = data['name']
        if (site['url'] !== data['url']) newData['url'] = data['url']
        if (site['urlConnect'] !== data['urlConnect']) newData['urlConnect'] = data['urlConnect']

        api.sites.put(site.id, newData)
        toggleOpen()
    }

    const deleteSite = () => api.sites.delete(site.id).then(success => success && navigate('/sites'))

    return (
        <Dialog open={open} onClose={toggleOpen} fullWidth={true}
            maxWidth={'md'}>
            <DialogTitle>Editer un site</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            autoFocus
                            sx={{ mt: 2 }}
                            value={data.name}
                            label="Nom du site"
                            onChange={(event: any) => setData({ ...data, name: event.target.value })}
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            label="Adresse du site"
                            fullWidth
                            variant="outlined"
                            value={data.url || ''}
                            onChange={(event: any) => setData({ ...data, url: event.target.value })}
                        />
                        <TextField
                            margin="dense"
                            label="Adresse de l'espace client"
                            fullWidth
                            variant="outlined"
                            value={data.urlConnect || ''}
                            onChange={(event: any) => setData({ ...data, urlConnect: event.target.value })}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography>Image du site</Typography>

                        {!site.imageId
                            ? <Typography variant="caption" component="div">Aucune image</Typography>
                            : <Box textAlign="center" sx={{ mt: 2, mb: 2 }}>
                                <img src={`/download/${site.imageId}`} width="100" alt={site.name} />
                            </Box>}
                        <input type="file" hidden accept="image/*" ref={inputFile} onChange={uploadFile} />
                        <Button variant="outlined" color="primary" onClick={clickInputFile}>
                            Choisir une image
                        </Button>
                        <Button variant="outlined" color="error" onClick={deleteImage} disabled={!site.imageId}>
                            Supprimer l'image
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel id="demo-multiple-name-label">Catégories du site</InputLabel>
                            <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple
                                value={site.categories.map(c => c.id)}
                                onChange={changeCat}
                                input={<OutlinedInput label="Catégories du site" />}
                            >
                                {lists.siteType.map(option =>
                                    <MenuItem key={option.id} value={option.id} selected={site.categories.map(c => c.id).includes(option.id)}>
                                        {option.value}
                                    </MenuItem>)}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={save} disabled={!data.name}>Sauvegarder</Button>
                <Button variant="outlined" onClick={toggleOpen}>Annuler</Button>
                <DeleteButtonConfirmation onDelete={deleteSite} />
            </DialogActions>
        </Dialog>
    )
}

export default EditSite