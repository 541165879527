import { IngredientInterface } from "../_ingredients";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()
    private _baseUrl = `/api/cuisines/ingredients`

    getAll = (): Promise<Response> => this._fetch.get(`${this._baseUrl}`)
    post = (newIngredient: Partial<IngredientInterface>): Promise<Response> => this._fetch.post(`${this._baseUrl}`, true, newIngredient)

    get = (id: IngredientInterface['id']): Promise<Response> => this._fetch.get(`${this._baseUrl}/${id}`)
    put = (id: IngredientInterface['id'], data: Partial<IngredientInterface>): Promise<Response> => this._fetch.put(`${this._baseUrl}/${id}`, data)

    uploadImage = (id: IngredientInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`${this._baseUrl}/${id}/image`, form)
    removeImage = (id: IngredientInterface['id']) => this._fetch.delete(`${this._baseUrl}/${id}/image`)


    // delete = (id: HealthDataInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/constantes/${id}`)

}