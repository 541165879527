import { Dialog, DialogTitle, DialogContent, TextField, MenuItem, DialogActions, Button, FormControlLabel, Switch } from "@mui/material"
import React from "react"
import AppContext from "../../AppContext"
import { SiteContentInterface, ListItemInterface, UserDbInterface } from "../../useApp"
import UserSelector from "./UserSelector"

interface EditContentProps {
    content: SiteContentInterface
    open: boolean
    toggleOpen: () => void
}

const EditContent = ({ content, open, toggleOpen }: EditContentProps) => {
    const { api, lists } = React.useContext(AppContext)
    const [data, setData] = React.useState<SiteContentInterface>(content)

    const setType = (id: ListItemInterface['id']) => setData({ ...data, typeId: id, type: lists.mdpType.find(l => l.id === id) as ListItemInterface })
    const setValue = (value: SiteContentInterface['value']) => setData({ ...data, value })
    const setOwnerId = (id: UserDbInterface['id']) => setData({ ...data, ownerId: id })
    const setDisable = (disable: SiteContentInterface['disable']) => setData({ ...data, disable })

    const save = () => {
        api.sites.contents.put(content.id, { typeId: data.typeId, value: data.value, ownerId: data.ownerId, disable: data.disable })
        toggleOpen()
    }

    React.useEffect(() => {
        setData(content)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Dialog open={open} onClose={toggleOpen}>
            <DialogTitle>Editer un item</DialogTitle>
            <DialogContent>
                <UserSelector
                    selectedUser={data.ownerId}
                    setSelectedUser={setOwnerId}
                />
                <TextField
                    autoFocus
                    sx={{ mt: 2 }}
                    select
                    value={data.typeId}
                    label="Type d'item"
                    onChange={(event: any) => setType(event.target.value)}
                    fullWidth
                >
                    {lists.mdpType.map((option) => (
                        <MenuItem key={option.id} value={option.id.toString()}>
                            {option.value}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    margin="dense"
                    id="name"
                    label="Valeur"
                    fullWidth
                    variant="outlined"
                    value={data.value}
                    onChange={(event: any) => setValue(event.target.value)}
                />
                <FormControlLabel
                    control={<Switch color="primary" checked={data.disable} onChange={(event: any) => setDisable(event.target.checked)} />}
                    label="Masquer dans la liste"
                // labelPlacement="start"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={save} disabled={!data.typeId || !data.value}>Sauvegarder</Button>
                <Button onClick={toggleOpen}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditContent