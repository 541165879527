import React from "react";

import { ListsInterface, UiInterface, UserInterface } from "./useApp";
import Api from "./_api/Api";

interface AppContextInterface {
    api: Api
    lists: ListsInterface;
    ui: UiInterface;
    user: undefined | UserInterface | null;
}

const AppContext = React.createContext({} as AppContextInterface);

export default AppContext