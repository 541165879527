import React from "react";

import { useNavigate } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import AppContext from "../../../../AppContext";
import { ConstanteInterface, Constantes$ } from "../../../../_api/_constantes";
import { map } from "rxjs";
import Page from "../../../Page/Page";
import Sorter from "./Sorter";
import DataTable from "./DataTable/DataTable";
import DateHelper from "App/utils/DateHelper";

const SanteConstantesLP = () => {
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [mode, setMode] = React.useState<'week' | 'query'>('week')
    const [data, setData] = React.useState<ConstanteInterface[]>()
    const [year, setYear] = React.useState<number>()
    const [yearQuery, setYearQuery] = React.useState<{ de: number, a: number }>()
    const [week, setWeek] = React.useState<number>()
    const [weekQuery, setWeekQuery] = React.useState<{ de: number, a: number }>()
    const [selected, setSelected] = React.useState<ConstanteInterface['id'][]>([])
    const years = data && data.map(d => new Date(d.mesureAt).getFullYear()).filter((y, i, s) => s.indexOf(y) === i).sort((a, b) => a < b ? -1 : 1)
    const weeks = data && data.filter(d => new Date(d.mesureAt).getFullYear() === year).map(d => new DateHelper(d.mesureAt).info().week).filter((y, i, s) => s.indexOf(y) === i).sort((a, b) => a < b ? -1 : 1)


    const addConstante = () => api.sante.constantes.post().then(id => id !== '' && navigate(`/sante/constantes/${id}`))

    const deleteConstante = async (id: ConstanteInterface['id']) => await api.sante.constantes.delete(id)

    React.useEffect(() => {
        Constantes$.pipe(
            map(hls => hls && hls.sort((a, b) => a.mesureAt > b.mesureAt ? -1 : 1)),
        ).subscribe(d => {
            if (d !== null) {
                setData(d)
                if ((!week || !year) && (d && d[0] && d[0].mesureAt)) {
                    const { year: y, week: w } = new DateHelper(d[0].mesureAt).info()
                    setYear(y)
                    setWeek(w)
                }
            }
        })
        api.sante.constantes.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    React.useEffect(() => {
        if (week) setWeekQuery({ de: week, a: week })
    }, [week])
    React.useEffect(() => {
        if (year) setYearQuery({ de: year, a: year })
    }, [year])

    const dataQuerying: ConstanteInterface[] = data
        ? mode === 'week'
            ? data.filter(d => new Date(d.mesureAt).getFullYear() === year && new DateHelper(d.mesureAt).info().week === week)
            : weekQuery && yearQuery
                ? data
                    .filter(d => new Date(d.mesureAt).getFullYear() < yearQuery.a
                        ? true
                        : new Date(d.mesureAt).getFullYear() === yearQuery.a
                            ? new DateHelper(d.mesureAt).info().week <= weekQuery.a
                                ? true
                                : false
                            : false)
                    .filter(d => new Date(d.mesureAt).getFullYear() > yearQuery.de
                        ? true
                        : new Date(d.mesureAt).getFullYear() === yearQuery.de
                            ? new DateHelper(d.mesureAt).info().week >= weekQuery.de
                                ? true
                                : false
                            : false)
                : []
        : []


    return (
        <Page
            title={{
                title: `Liste des constantes`
            }}
            loading={!data}
            searchable={{
                add: addConstante,
                addPlaceholder: `Ajouter des constantes`,
                customRender: (year && week && (years && years.length > 0) && weeks && <Sorter
                    mode={mode} setMode={setMode}
                    year={year} setYear={setYear} years={years} yearQuery={yearQuery} setYearQuery={setYearQuery}
                    week={week} setWeek={setWeek} weeks={weeks} weekQuery={weekQuery} setWeekQuery={setWeekQuery}
                />) || undefined
            }}
        >
            <Box sx={{ height: { xs: '70vh', md: '100%' } }}>
                {data
                    ? <DataTable
                        year={year!}
                        dataQuerying={dataQuerying}
                        selected={selected} setSelected={setSelected}
                        deleteConstante={deleteConstante}
                    />
                    : <LinearProgress />}
            </Box>
        </Page>
    )
}

export default SanteConstantesLP