import { CarnetInterface, JournalInterface } from '../useApp'
import Http from './http/carnets_journals'
import Idb from "../__api_v2/_Idb"
import { Carnet$ } from './_carnets'
import ApiArticles from './_carnets_journals_articles'



export default class ApiJournals {
    private _http!: Http
    private _idb!: Idb<'carnets'>
    articles!: ApiArticles
    constructor() {
        this._http = new Http()
        this._idb = new Idb('carnets')
        this.articles = new ApiArticles()
    }

    post = async (carnetId: CarnetInterface['id']): Promise<void> => this._http.post(carnetId).then((resp) => {
        if (resp.status === 200) {
            resp.json().then((json: JournalInterface) => {
                const newCarnet: CarnetInterface = {
                    ...(Carnet$.getValue() as CarnetInterface), Journals: [{
                        ...json,
                        Articles: [],
                        eis: []
                    }, ...(Carnet$.getValue() as CarnetInterface).Journals],
                }
                this._idb.post(newCarnet)
                Carnet$.next(newCarnet)
            })
        }
    })

    put = (journalId: JournalInterface['id'], data: Partial<JournalInterface>) => this._http.put(journalId, data)
        .then((resp) => {
            if (resp.status === 200) {
                let newCarnet = Carnet$.getValue()
                if (newCarnet) {
                    newCarnet.Journals = newCarnet.Journals.map(J => J.id === journalId ? ({ ...J, ...data }) : J)
                    this._idb.post(newCarnet)
                    Carnet$.next(newCarnet)
                } else {
                    Carnet$.next({ ...Carnet$.getValue() as CarnetInterface })
                }
            } else {

                Carnet$.next({ ...Carnet$.getValue() as CarnetInterface })
            }
        })

    delete = (journalId: JournalInterface['id']): void => {
        const oldCarnet = { ...Carnet$.getValue() } as CarnetInterface
        const newCarnet = { ...Carnet$.getValue(), Journals: (Carnet$.getValue() as CarnetInterface).Journals.filter(f => f.id !== journalId) } as CarnetInterface
        Carnet$.next(newCarnet)
        this._http.delete(journalId).then(resp => {
            if (resp.status === 204) {
                this._idb.post(newCarnet)
            } else {
                Carnet$.next(oldCarnet)
            }
        })
    }

}