//@ts-ignore
import * as ExcelJS from 'exceljs/dist/exceljs';
import { Alignment, Borders, Font, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { ConstanteInterface } from '../../../../_api/_constantes';
import DateHelper from 'App/utils/DateHelper';

interface DataCollection { [key: number]: WeekCollection }
interface WeekCollection { [key: number]: Line[] }
type Line = [
    '',
    daysCollection,
    string,
    '',
    number | '',
    number | '',
    number | '',
    '',
    number | '',
    number | '',
    number | '',
    '',
    number | '',
    number | '',
    number | '',
    '', '',
    string | ''
]

type daysCollection = 'Lundi' | 'Mardi' | 'Mercredi' | 'Jeudi' | 'Vendredi' | 'Samedi' | 'Dimanche'

class ToExcel {
    private __xls!: Workbook
    private __datas!: DataCollection
    public columns = ['', 'Date', ' ', 'Sem', 'Systolique', 'Diastolique', 'Pouls', '', 'Systolique', 'Diastolique', 'Pouls', '', 'Poids', `Gain\nde poids`, 'Température', `Ecart\nGauche`, `Ecart\nDroite`, 'Commentaire'];
    public days: daysCollection[] = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    public styles: { [key: string]: Partial<Font> } = {
        header: { bold: true, name: "Arial", size: 12 },
        line: { bold: false, name: "Arial", size: 12 }
    }
    public alignment: { [key: string]: Partial<Alignment> } = {
        header: { vertical: 'middle', horizontal: 'center', wrapText: true },
        line: { vertical: 'middle', horizontal: 'center', wrapText: false }
    }
    public borders: { [key: string]: Borders } = {
        header: { top: { style: 'medium' }, left: { style: 'dotted' }, right: { style: 'dotted' }, bottom: { style: 'double' }, diagonal: {} },
        line: { top: {}, left: { style: 'dotted' }, right: { style: 'dotted' }, bottom: { style: 'dotted' }, diagonal: {} },
        foot: { top: { style: 'thin' }, left: { style: 'dotted' }, right: { style: 'dotted' }, bottom: { style: 'medium' }, diagonal: {} }
    }
    public colLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R']
    public year!: number;
    public countFormula = (ws: Worksheet, letter: string) => `COUNT(${letter}${ws.rowCount - 6}:${letter}${ws.rowCount})`
    public averageFormula = (ws: Worksheet, letter: string) => `AVERAGE(${letter}${ws.rowCount - 6}:${letter}${ws.rowCount})`
    public ifFormula = (ws: Worksheet, cond: string, alors: string, autrement: string) => `IF(${cond},${alors},${autrement})`

    constructor(datas: ConstanteInterface[]) {
        // this.year = year
        this.__xls = new ExcelJS.Workbook();
        this.__datas = this.__organiseData(datas)
    }

    private __emptyLine(dayIndex: number, week: number, year: number) {
        const sunday = new Date(year, 0, (1 + (week) * 7));
        while (sunday.getDay() !== 0) {
            sunday.setDate(sunday.getDate() - 1);
        }
        sunday.setDate(sunday.getDate() + 1 + dayIndex);
        return ['', this.days[dayIndex], new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'short' }).format(sunday).replace(' ', '-'), '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''] as Line
    }

    private __organiseData(datas: ConstanteInterface[]) {
        let arr: DataCollection = {}
        datas.forEach(data => {
            let { year, week, date } = new DateHelper(data.mesureAt).info()

            if (!arr[year]) arr[year] = {}
            if (!arr[year][week]) arr[year][week] = [this.__emptyLine(0, week, year), this.__emptyLine(1, week, year), this.__emptyLine(2, week, year), this.__emptyLine(3, week, year), this.__emptyLine(4, week, year), this.__emptyLine(5, week, year), this.__emptyLine(6, week, year)]
            const localDay = date.getDay() - 1 < 0 ? 6 : date.getDay() - 1
            const line: Line = [
                '',
                this.days[localDay],
                new Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'short' }).format(date).replace(' ', '-'),
                '',
                (data.value && data.value.pni && data.value.pni.g && +data.value.pni.g.s) || arr[year][week][localDay][4],
                (data.value && data.value.pni && data.value.pni.g && +data.value.pni.g.d) || arr[year][week][localDay][5],
                (data.value && data.value.pouls && +data.value.pouls.g) || arr[year][week][localDay][6],
                '',
                (data.value && data.value.pni && data.value.pni.d && +data.value.pni.d.s) || arr[year][week][localDay][8],
                (data.value && data.value.pni && data.value.pni.d && +data.value.pni.d.d) || arr[year][week][localDay][9],
                (data.value && data.value.pouls && +data.value.pouls.d) || arr[year][week][localDay][10],
                '',
                (data.value && +data.value.masse) || arr[year][week][localDay][11],
                '',
                (data.value && +data.value.temp) || arr[year][week][localDay][13] || '',
                '', '',
                (data.value && data.value.comment && `${data.value.comment} ; ${arr[year][week][localDay][16]}`) || arr[year][week][localDay][16]
            ]
            arr[year][week][localDay] = line
        })
        console.log(arr)
        return arr
    }


    private _writeHeader(ws: Worksheet, semaine: number | 'Semaine' = 'Semaine') {
        if (semaine === 'Semaine') {
            const firstLine = ws.addRow([])
            const firstCol = ws.getColumn(1)
            firstLine.height = 4
            firstCol.width = 1
            const locHeader = ws.addRow(['', '', '', '', 'Gauche', '', '', '', 'Droite'])
            ws.mergeCells('E2:G2')
            ws.mergeCells('I2:K2')
            locHeader.font = this.styles.header
            locHeader.height = 35
            locHeader.alignment = this.alignment.header
            const h1 = locHeader.getCell(5)
            const h2 = locHeader.getCell(9)
            h1.border = { top: { style: 'medium' }, left: { style: 'medium' }, right: { style: 'medium' }, bottom: { style: 'dotted' } }
            h2.border = { top: { style: 'medium' }, left: { style: 'medium' }, right: { style: 'medium' }, bottom: { style: 'dotted' } }

        }
        let header = ws.addRow(this.columns);
        this.columns.forEach((name, index) => {
            let cell = header.getCell(index + 1)
            if (index >= 1) {
                cell.value = name === 'Sem' ? semaine : name
                cell.border = this.borders.header

                if (index === 1) cell.border = { ...this.borders.header, left: { style: 'medium' } }
                if (index === 15) cell.border = { ...this.borders.header, left: { style: 'medium' } }
                if (index === 16) cell.border = { ...this.borders.header, right: { style: 'medium' } }
                if (index === 17) cell.border = { ...this.borders.header, right: { style: 'medium' }, left: { style: 'medium' } }
                if (semaine === 'Semaine') {
                    if ((index > 3 && index < 7) || (index > 7 && index < 11)) {
                        cell.border = { ...this.borders.header, top: { style: 'dotted' } }
                    }
                }
            }
        })
        header.height = 35
        header.font = this.styles.header
        header.alignment = this.alignment.header
        ws.mergeCells(`B${ws.rowCount}:C${ws.rowCount}`);
    }

    private _writeWeekLine(ws: Worksheet, line: Line) {
        let dayLine = ws.addRow(line)
        const diff = (letterA: string, letterB: string) => this.ifFormula(ws, `${letterA}${ws.rowCount} = ""`, '""', this.ifFormula(ws, `${letterB}${ws.rowCount} = ""`, '""', `${letterA}${ws.rowCount}-${letterB}${ws.rowCount}`))// + `IF(${letterA}${ws.rowCount}="","",IF(${letterB}${ws.rowCount}="","",${letterA}${ws.rowCount}-${letterB}${ws.rowCount}))`
        line.forEach((l, index) => {
            if (index > 0) {
                let cell = dayLine.getCell(index + 1)
                cell.font = this.styles.line
                cell.border = this.borders.line
                if (index === 1 || index === 15) cell.border = { ...this.borders.line, left: { style: 'medium' } }
                if (index === 17) cell.border = { ...this.borders.line, right: { style: 'medium' }, left: { style: 'medium' } }
            }
        })
        const diff1 = dayLine.getCell(16);
        diff1.value = { formula: diff('E', 'F'), result: '', date1904: false }
        diff1.font = this.styles.line
        diff1.border = { ...this.borders.line, left: { style: 'medium' } }
        const diff2 = dayLine.getCell(17);
        diff2.value = { formula: diff('I', 'J'), result: '', date1904: false }
        diff2.font = this.styles.line
        diff2.border = { ...this.borders.line, right: { style: 'medium' } }
        dayLine.alignment = this.alignment.line
        dayLine.outlineLevel = 1;
    }

    private _writeWeekFooter(ws: Worksheet) {
        const footer = ws.addRow([
            '', '', '',
            { formula: this.countFormula(ws, 'E'), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'E')}=0`, `""`, this.averageFormula(ws, 'E')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'F')}=0`, `""`, this.averageFormula(ws, 'F')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'G')}=0`, `""`, this.averageFormula(ws, 'G')), date1904: false },
            '',
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'I')}=0`, `""`, this.averageFormula(ws, 'I')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'J')}=0`, `""`, this.averageFormula(ws, 'J')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'K')}=0`, `""`, this.averageFormula(ws, 'K')), date1904: false },
            '',
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'M')}=0`, `""`, this.averageFormula(ws, 'M')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'N')}=0`, `""`, this.averageFormula(ws, 'N')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'O')}=0`, `""`, this.averageFormula(ws, 'O')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'P')}=0`, `""`, this.averageFormula(ws, 'P')), date1904: false },
            { formula: this.ifFormula(ws, `${this.countFormula(ws, 'Q')}=0`, `""`, this.averageFormula(ws, 'Q')), date1904: false },
        ])
        this.columns.forEach((l, index) => {
            if (index > 0) {

                let cell = footer.getCell(index + 1)
                cell.font = this.styles.foot
                cell.border = this.borders.foot
                cell.numFmt = index >= 12 && index <= 14 ? '0.0' : '0'
                if (index === 1 || index === 15) cell.border = { ...this.borders.foot, left: { style: 'medium' } }
                if (index === 17) cell.border = { ...this.borders.foot, right: { style: 'medium' }, left: { style: 'medium' } }
            }
        })
        footer.font = this.styles.line
        footer.alignment = this.alignment.line

    }



    public async get() {
        for (let year in this.__datas) {
            const ws = this.__xls.addWorksheet(`Annéee ${year.toString()}`, {});
            this._writeHeader(ws)
            ws.views = [{ state: "frozen", ySplit: 3, xSplit: 4 }]

            for (let week in this.__datas[year]) {
                this._writeHeader(ws, +week)
                this.__datas[year][week].forEach(line => this._writeWeekLine(ws, line))
                this._writeWeekFooter(ws)
            }
            this.columns.forEach((c, i) => {
                if (i > 0) {
                    const col = ws.getColumn(i + 1)
                    col.width = c === '' ? 3.2 : c === 'Commentaire' ? 100 : 14
                }
            })
            ws.addRow([])
            const unusedRows = ws.getRows(ws.rowCount, 1048576 - ws.rowCount) as ExcelJS.Row[]
            unusedRows.forEach((row) => row.hidden = true)


        }



        this.__xls.xlsx.writeBuffer().then(buffer => saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `Mesures Tensions.xlsx`
        ))
    }

}

export default ToExcel