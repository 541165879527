import { BehaviorSubject } from "rxjs"
import Idb from "../__api_v2/_Idb"
import Http from './http/ingredients'
import { ListItemInterface } from "../useApp";

export interface IngredientInterface {
    id: string;
    imageId: string | null;
    name: string;
    unitId: number | null;
    unit: ListItemInterface | null;
}

export const Ingredients$ = new BehaviorSubject<IngredientInterface[]>([])
export const Ingredient$ = new BehaviorSubject<IngredientInterface | null>(null)

export default class ApiIngredient {
    private _idb!: Idb<'ingredients'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('ingredients')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Ingredients$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: IngredientInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Ingredients$.next(data)))
                }
            }))
    }

    get = (id: IngredientInterface['id']): void => {
        this._idb.get(id).then(data => data && Ingredient$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: IngredientInterface) => {
                    this._idb.post(data)
                    Ingredient$.next(data)
                })
            }
        })
    }

    post = (newIngredient: Partial<IngredientInterface>): Promise<IngredientInterface | null> => this._http.post(newIngredient).then(resp => {
        if (resp.status === 200) {
            return resp.json().then((ingredient: IngredientInterface) => {
                this._idb.post(ingredient);
                Ingredients$.next([...Ingredients$.getValue(), ingredient])
                Ingredient$.next(ingredient)
                return ingredient
            })
        }
        return null
    })

    put = (id: IngredientInterface['id'], ingredient: Partial<IngredientInterface>): Promise<number> => this._http.put(id, ingredient).then(resp => {
        if (resp.status === 200) {
            const newIngredient = { ...(Ingredient$.getValue() as IngredientInterface), ...ingredient }
            this._idb.post(newIngredient)
            Ingredient$.next(newIngredient)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: IngredientInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newIngredient = { ...(Ingredient$.getValue() as IngredientInterface), imageId: id }
                    this._idb.post(newIngredient)
                    Ingredient$.next(newIngredient)
                })
            }
        })
    }

    deleteImage = async (id: IngredientInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newIngredient = { ...(Ingredient$.getValue() as IngredientInterface), imageId: null }
            this._idb.post(newIngredient)
            Ingredient$.next(newIngredient)
        }
    })
}


namespace ApiIngredient { /* empty */ }