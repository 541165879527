import { SiteContentInterface, SiteInterface } from "../useApp"
import Idb from "../__api_v2/_Idb"
import Http from './http/sitesContents'
import { Site$ } from "./_sites"

export default class ApiSiteContents {
    private _idb!: Idb<'sites'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('sites')
        this._http = new Http()
    }

    post = async (siteId: SiteInterface['id'], data: Partial<SiteContentInterface>): Promise<SiteContentInterface['id']> => this._http.post(siteId, data).then((resp) => {
        if (resp.status === 200) {
            return resp.json().then((json: SiteContentInterface) => {
                const newSite = Site$.getValue() as SiteInterface;
                newSite.contents = [json, ...newSite.contents]
                this._idb.post(newSite)
                Site$.next(newSite)
                return json.id
            })
        } else {
            return -1
        }
    })
    put = async (contentId: SiteContentInterface['id'], data: Partial<SiteContentInterface>) => this._http.put(contentId, data).then((resp: any) => {
        if (resp.status === 200) {
            resp.json().then((json: SiteContentInterface) => {
                const newValue = { ...Site$.getValue() } as SiteInterface;
                newValue.contents = newValue.contents.map(s => s.id === contentId ? json : s)
                Site$.next(newValue)
            })
        }
    })

    delete = async (id: SiteContentInterface['id']): Promise<void> => {
        const oldValue = Site$.getValue() as SiteInterface;
        if (oldValue) {
            const newValue = { ...oldValue }
            newValue.contents = newValue.contents.filter(f => f.id !== id)
            Site$.next(newValue)
            this._http.delete(id).then(resp => {
                if (resp.status === 204) {
                    this._idb.post(newValue)
                } else {
                    Site$.next(oldValue)
                }
            })
        }
    }
}

namespace ApiSiteContents { /* empty */ }