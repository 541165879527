import ApiCarnets from "./_carnets";
import ApiChimique from "./_chimiques";
import ApiConstantes from "./_constantes";
import ApiEngrais from "./_engrais";
import ApiFleurs from "./_fleurs";
import ApiLabos from "./_labos";
import ApiLegumes from "./_legumes";
import ApiLists from "./_lists";
import ApiPurins from "./_purins";
import ApiSites from "./_sites";
import ApiUsers from "./_users";
import ApiCuisine from "./_cuisines";
import ApiFiles from "./_files";

export default class Api {

    cuisine = new ApiCuisine()
    jardin = {
        legumes: new ApiLegumes(),
        fleurs: new ApiFleurs(),
        purins: new ApiPurins(),
        engrais: new ApiEngrais(),
        chimiques: new ApiChimique(),
    }
    lists = new ApiLists()
    sante = {
        constantes: new ApiConstantes(),
        carnets: new ApiCarnets(),
        labos: new ApiLabos()
    }
    sites = new ApiSites()
    users = new ApiUsers()
    files = new ApiFiles()

    private _clear() {
        for (let key in this) {
            if ('_idb' in this[key]) {
                //@ts-ignore
                if ('clear' in this[key]['_idb']) {
                    //@ts-ignore
                    this[key]['_idb'].clear()
                }
            }
        }
    }
    clear = this._clear.bind(this)
}

namespace Api { /* empty */ }