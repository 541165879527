import { Box, ListSubheader, Menu, MenuItem } from "@mui/material"
import { Examin, GroupExamins, labos } from "../Exams"

type onSelect = (id: Examin['id']) => void
interface FilterTable {
    anchor: null | HTMLElement
    handleClose: () => void
    onSelect: onSelect
    selected: Examin['id'][]
}
const FilterTable = ({ anchor, handleClose, onSelect, selected }: FilterTable) => {
    return (
        <Menu
            open={Boolean(anchor)}
            anchorEl={anchor}

            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Box sx={{ display: 'flex' }}>
                {labos.map(L => <Group key={L.id} labo={L} onSelect={onSelect} selected={selected} />)}
            </Box>
        </Menu>
    )
}
interface GroupProps {
    labo: GroupExamins
    onSelect: onSelect
    selected: Examin['id'][]

}
const Group = ({ labo, onSelect, selected }: GroupProps) => {
    return (
        <Box>
            <ListSubheader>{labo.name}</ListSubheader>
            {labo.examins.map(i => <Item key={i.id} item={i} onSelect={onSelect} selected={selected} />)}
        </Box>
    )
}
interface ItemProps {
    item: Examin
    onSelect: onSelect
    selected: Examin['id'][]
}
const Item = ({ item, onSelect, selected }: ItemProps) => {
    const handleClick = (event: any) => {
        event.preventDefault();
        onSelect(item.id)
    }
    return (
        <MenuItem
            selected={!selected.includes(item.id)}
            onClick={handleClick}
        >
            {item.name}
        </MenuItem>
    )
}

export default FilterTable