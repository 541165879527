import { Box } from "@mui/material";
import { TabPanel } from '@mui/lab'
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import AppContext from "../../../AppContext";
import { Legume$, LegumeInterface } from "../../../_api/_legumes";
import TextHelper from "./TextHelper";
import VueAmiEnnemis from "./VueAmiEnnemis";
import VuePlantation from "./VuePlantation";
import VueRecolte from "./VueRecolte";
import VueSemis from "./VueSemis";
import Page from "../../Page/Page";

export type LegumeTabsValuesUsed = 'Semis' | 'Plantation' | 'Récolte' | 'Amis / Ennemis'

const VueLegume = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<LegumeInterface>()
    const [visibles, setVisibles] = React.useState<LegumeTabsValuesUsed[]>()

    const handleEdit = () => navigate(`/jardin/legumes/${data && data.id}/edit`)

    React.useEffect(() => {
        Legume$.subscribe(l => {
            if (l) {
                if (!data || data.id !== l.id) {
                    setVisibles([
                        (l.condCulture || l.semisDate || (l.semisSaisons && l.semisSaisons.length > 0) || (l.semisTypes && l.semisTypes.length > 0) || l.semisProf || l.semisTemp) ? 'Semis' as LegumeTabsValuesUsed : null,
                        (l.plantation || l.plantationDate || (l.plantationSaisons && l.plantationSaisons.length > 0) || (l.plantationTasks && l.plantationTasks.length > 0)) ? 'Plantation' : null,
                        l.recolte ? 'Récolte' : null,
                        (l.protection || (l.legumesAmis && l.legumesAmis.length > 0) || (l.legumesEnnemis && l.legumesEnnemis.length > 0) || (l.fleursAmies && l.fleursAmies.length > 0) ? 'Amis / Ennemis' : null)
                    ].filter(v => v !== null) as LegumeTabsValuesUsed[])
                }
                setData(l)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.legumes.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.variety ? `${data.name} – ${data.variety}` : data.name : "Légume",
                subtitle: data ? data.description || data.latin || undefined : "Jardin",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
                toggleEdit: handleEdit
            }}
            tabs={visibles && visibles.length > 0 ? visibles : undefined}
            loading={!data}
        >
            {data && visibles && <Box>
                {data.description && <TextHelper value={data.description} divider />}

                <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                    {visibles.includes('Semis') ? <TabPanel value="Semis"><VueSemis legume={data} /></TabPanel> : ''}
                    {visibles.includes('Plantation') ? <TabPanel value="Plantation" ><VuePlantation legume={data} /></TabPanel> : ''}
                    {visibles.includes('Récolte') ? <TabPanel value="Récolte" ><VueRecolte legume={data} /></TabPanel> : ''}
                    {visibles.includes('Amis / Ennemis') ? <TabPanel value="Amis / Ennemis" ><VueAmiEnnemis legume={data} /></TabPanel> : ''}
                </Box>
            </Box>}

        </Page>
    )
}

export default VueLegume