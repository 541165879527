import { Check, AccessTime, Fireplace, Star } from "@mui/icons-material"
import { MenuItem, ListItemIcon, ListItemText, Divider, Container, Card, CardActionArea, CardContent, Typography, Box, Rating } from "@mui/material"
import React from "react"
import { useNavigate, Link } from "react-router-dom"
import AppContext from "../../AppContext"
import { CuisineInterface, Cuisines$ } from "../../_api/_cuisines"
import Page from "../Page/Page"
import SearchableHeader from "../Page/SearchableHeader"


const CuisineImage = require('../../assets/cuisine.jpeg')

const ListCuisine = () => {
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<CuisineInterface[]>()
    const [search, setSearch] = React.useState<string>('')
    const [sort, setSort] = React.useState<'asc' | 'desc'>('asc')
    const [filters, toggleFilters] = React.useReducer((s: string[], v: string) => s.includes(v) ? s.filter(ss => ss !== v) : [...s, v], [])
    const filterItems = (data && data.map(d => d.type).filter((t, i, s) => t !== null && s.indexOf(t) === i).sort((a, b) => (a as string) < (b as string) ? -1 : 1) as Exclude<CuisineInterface['type'], null>[]) || []

    const addRecette = () => api.cuisine.post().then(id => id && navigate(`/cuisine/recettes/${id}/edit`))

    const sortNumber = sort === 'asc' ? -1 : 1

    React.useEffect(() => {
        Cuisines$.subscribe(c => c && setData(c))
        api.cuisine.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Page
            title={{
                title: "Recettes de cuisine",
                imageUrl: CuisineImage,
            }}
            loading={!data}
        >
            <SearchableHeader
                sortOrder={sort} changeSortOrder={setSort}
                search={search} setSearch={setSearch}
                filter={(filterItems.length > 1 && filterItems.map(f => <MenuItem key={f} selected={filters.includes(f)} onClick={() => f !== null && toggleFilters(f)}>
                    {filters.includes(f) && <ListItemIcon>
                        <Check />
                    </ListItemIcon>}
                    <ListItemText inset={!filters.includes(f)}>{f}</ListItemText>
                </MenuItem>)) || undefined}
                filterActive={filters.length > 0}
                add={addRecette} addPlaceholder="Créer une nouvelle recette"
            />
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Container>
                {data && data
                    .sort((a, b) => a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase() ? sortNumber : -sortNumber)
                    .filter(c => filters.length === 0 ? true : filters.includes(c.type || ''))
                    .filter(c => !search
                        ? true
                        : c.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    ).map(cuisine =>
                        <CuisineCard key={cuisine.id} cuisine={cuisine} />)}
            </Container>
        </Page>
    )
}

interface CuisineCardProps {
    cuisine: CuisineInterface
}
const CuisineCard = ({ cuisine }: CuisineCardProps) => {

    const cardSx = {
        p: 4, pl: { xs: 2, md: 16 }, pr: { xs: 2, md: 16 },
        backgroundImage: cuisine.imageId ? `url('/download/${cuisine.imageId}')` : undefined,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        textShadow: cuisine.imageId ? '1px 1px 2px black' : undefined,
        color: cuisine.imageId ? 'white' : undefined
    }
    return (
        <Card sx={{ mb: 2, mt: 2, height: 'unset' }}>
            <CardActionArea component={Link} to={`/cuisine/recettes/${cuisine.id}`}>
                <CardContent sx={cardSx}>
                    <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>{cuisine.name}</Typography>
                    <Typography variant="body1" sx={{ textAlign: 'center' }}>{cuisine.description}</Typography>
                    {cuisine.facilite && <Box textAlign={'center'}>
                        <Rating value={+cuisine.facilite} readOnly max={+cuisine.facilite} icon={<Star sx={{ color: undefined, stroke: cuisine.imageId ? 'black' : undefined, }} />} />
                    </Box>}
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 4 }}>
                        {cuisine.preparationTime &&
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <AccessTime fontSize="large" />
                                <Typography variant="h5">
                                    {cuisine.preparationTime} minutes
                                </Typography>
                            </Box>}
                        {cuisine.cookingTime &&
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Fireplace fontSize="large" />
                                <Typography variant="h5">
                                    {cuisine.cookingTime} minutes
                                </Typography>
                            </Box>}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card >
    )
}

export default ListCuisine