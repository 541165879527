import { Box, Grid, TextField, InputAdornment } from "@mui/material"
import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { PurinInterface, Purin$ } from "../../../../_api/_purins"
import ImageUploader from "../../../ImageUploader"
import Page from "../../../Page/Page"


const EditPurin = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<PurinInterface>()

    const handleChangeName = (event: any) => setData({ ...(data as PurinInterface), name: event.target.value })
    const handleChangeMasse = (event: any) => setData({ ...(data as PurinInterface), masse: event.target.value })
    const handleChangeTemp = (event: any) => setData({ ...(data as PurinInterface), temp: event.target.value })
    const handleChangeDilPulv = (event: any) => setData({ ...(data as PurinInterface), dilutionPulv: event.target.value })
    const handleChangeDilArr = (event: any) => setData({ ...(data as PurinInterface), dilutionArros: event.target.value })

    const hasChange = () => {
        if (!data) return false
        let oldPurin = Purin$.getValue() as PurinInterface;
        if (oldPurin.name !== data.name) return true
        if (oldPurin.masse !== data.masse) return true
        if (oldPurin.temp !== data.temp) return true
        if (oldPurin.dilutionPulv !== data.dilutionPulv) return true
        if (oldPurin.dilutionArros !== data.dilutionArros) return true

        return false;
    }

    const handleSave = () => {
        if (!data) return false
        let newPurin: Partial<PurinInterface> = {}
        let oldPurin = Purin$.getValue() as PurinInterface;
        if (oldPurin.name !== data.name) newPurin.name = data.name
        if (oldPurin.masse !== data.masse) newPurin.masse = data.masse
        if (oldPurin.temp !== data.temp) newPurin.temp = data.temp
        if (oldPurin.dilutionPulv !== data.dilutionPulv) newPurin.dilutionPulv = data.dilutionPulv
        if (oldPurin.dilutionArros !== data.dilutionArros) newPurin.dilutionArros = data.dilutionArros

        api.jardin.purins.put(data.id, newPurin)
    }

    const handleDelete = () => api.jardin.purins.delete(params.id as PurinInterface['id']).then(success => success && navigate(`/jardin/purins`))

    React.useEffect(() => {
        Purin$.subscribe(l => {
            if (l) {
                setData(l)
            }
        })
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.purins.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.name : "Purins",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            footer={{
                handleSave: handleSave,
                disabledSave: hasChange(),
                handleDelete: handleDelete,
                handleCancel: () => hasChange() ? setData(Purin$.getValue() as PurinInterface) : navigate(`/jardin/purins/${params.id}`),
                cancelPlaceholder: hasChange() ? 'Annuler' : 'Retour'
            }}
            loading={!data}
        >

            {data &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Nom du purin"
                                fullWidth
                                value={data.name}
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageUploader data={data} callApi={api.jardin.purins} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Masse pour 10L d'eau"
                                fullWidth
                                type="number"
                                value={data.masse || ''}
                                onChange={handleChangeMasse}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Temps de macération"
                                fullWidth
                                type="number"
                                value={data.temp || ''}
                                onChange={handleChangeTemp}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">j</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Dilution pour pulvérisation"
                                fullWidth
                                type="number"
                                value={data.dilutionPulv || ''}
                                onChange={handleChangeDilPulv}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Dilution pour arrosage"
                                fullWidth
                                type="number"
                                value={data.dilutionArros || ''}
                                onChange={handleChangeDilArr}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>


                    </Grid>
                </Box>}
        </Page>
    )
}

export default EditPurin