import React from "react";

import { Checkbox, Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import { Close } from '@mui/icons-material'

import AppContext from "../../../AppContext";
import { JournalInterface, ListItemInterface } from "../../../useApp";

interface EISelectorProps {
    journal: JournalInterface
    open: boolean
    onClose: () => void
}
const EISelector = ({ journal, open, onClose }: EISelectorProps) => {
    const { lists, api } = React.useContext(AppContext)
    const [eis, setEis] = React.useState<ListItemInterface[]>(journal.eis)

    // const toggleEi = (journalId: JournalInterface['id'], listItem: ListItemInterface) => api.sante.carnets.toggleEi(journalId, listItem)
    const toggleEi = (listItem: ListItemInterface) => {
        const newEis = eis.find(ei => ei.id === listItem.id) ? eis.filter(ei => ei.id !== listItem.id) : [...eis, listItem]
        setEis(newEis)
        api.sante.carnets.journals.put(journal.id, { eis: newEis })
    }

    React.useEffect(() => {
        setEis(journal.eis)
    }, [journal])


    return (
        <Dialog onClose={onClose} open={open} scroll="paper">
            <DialogTitle>Effets indesirables</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}>
                <Close />
            </IconButton>
            <DialogContent dividers>
                <List sx={{ pt: 0 }}>
                    {lists.ei.map((ei) => (
                        <ListItem button onClick={() => toggleEi(ei)} key={ei.id}>
                            <ListItemAvatar>
                                <Checkbox
                                    edge="start"
                                    checked={eis && eis.find(e => e.id === ei.id) !== undefined}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': ei.value }}
                                    onChange={() => toggleEi(ei)}
                                />
                            </ListItemAvatar>
                            <ListItemText primary={ei.value} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    )
}

export default EISelector