import { ConstanteInterface } from "../_constantes";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    post = (): Promise<Response> => this._fetch.post(`/api/sante/constantes`)
    getAll = (): Promise<Response> => this._fetch.get(`/api/sante/constantes`)

    get = (id: ConstanteInterface['id']): Promise<Response> => this._fetch.get(`/api/sante/constantes/${id}`)
    put = (data: ConstanteInterface): Promise<Response> => this._fetch.put(`/api/sante/constantes/${data.id}`, data)
    delete = (id: ConstanteInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/constantes/${id}`)

}