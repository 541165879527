import { Grid, TextField } from "@mui/material";
import React from "react";
import AppContext from "../../AppContext";
import ImageUploader from "../ImageUploader";
import Page from "../Page/Page";


const Profil = () => {
    const { user: authUser, api } = React.useContext(AppContext)
    const [user, setUser] = React.useState(authUser!)
    const [loading, setLoading] = React.useState<boolean>(false)

    const handleChangeFisrt = ({ target: { value: firstName } }: any) => setUser({ ...user, firstName })
    const handleChangeLast = ({ target: { value: lastName } }: any) => setUser({ ...user, lastName })

    const hasChange = () => JSON.stringify(user) !== JSON.stringify(authUser)
    const handleSave = () => {
        setLoading(true)
        api.users.putMe(user).then(() => {
            setLoading(false)
        })
    }

    const handleUploadImage = (file: File) => {
        api.users.uploadMeImage(file)
    }
    const handleDeleteImage = () => {
        api.users.deleteMeImage()
    }
    React.useEffect(() => {
        if (user.iat !== authUser!.iat && user.exp !== authUser!.exp) {
            setUser(authUser!)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser])
    return (
        <Page
            title={{
                title: 'Mon profil',
                subtitle: user?.email || undefined,
                imageUrl: (user?.imageId && `/download/${user.imageId}`) || undefined
            }}
            footer={{
                handleSave,
                disabledSave: hasChange() && !loading,
                handleCancel: () => setUser(authUser!),
                disabledCancel: !hasChange() || loading,
            }}
            loading={!user}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Prénom"
                        value={user.firstName}
                        onChange={handleChangeFisrt}
                        fullWidth
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Nom de famille"
                        value={user.lastName}
                        onChange={handleChangeLast}
                        fullWidth
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Adresse de courriel"
                        value={user.email}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <ImageUploader data={{ name: `${user.firstName} ${user.lastName}`, imageId: user.imageId }} callApi={api.users} customMethods={{ set: handleUploadImage, delete: handleDeleteImage }} />
                </Grid>
            </Grid>
        </Page>
    )
}

export default Profil