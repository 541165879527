import { ContentCut, Edit, Visibility } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";
import AppContext from "../../AppContext";
import { SiteContentInterface } from "../../useApp";
import DeleteButtonConfirmation from "../DeleteButtonConfirmation";
import EditContent from "./EditContent";

interface VueItemInterface {
    item: SiteContentInterface
}
const VueItem = ({ item }: VueItemInterface) => {
    const { api } = React.useContext(AppContext)
    const [block, unblock] = React.useReducer((block) => !block, item.type.value.includes("Mot de passe"))
    const [edit, toggleEdit] = React.useReducer((e) => !e, false)

    const copy = () => navigator.clipboard.writeText(item.value)

    const deleteItem = () => api.sites.contents.delete(item.id)

    return (
        <Grid item>
            <TextField
                className="hoverContainer"
                label={item.type.value}
                value={item.value}
                type={block ? "password" : undefined}
                fullWidth
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end" className="hoverTarget">
                            {block
                                ? <IconButton
                                    onClick={unblock}
                                    edge="end"
                                >
                                    <Visibility />
                                </IconButton>
                                : <>
                                    <IconButton
                                        onClick={copy}
                                        edge="end"
                                    >
                                        <ContentCut />
                                    </IconButton>
                                    <IconButton
                                        onClick={toggleEdit}
                                        edge="end"
                                    >
                                        <Edit />
                                    </IconButton>
                                    <DeleteButtonConfirmation rounded onDelete={deleteItem} />
                                </>
                            }
                        </InputAdornment>
                }}
            />
            <Typography variant="caption">{new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(item.updatedAt))}</Typography>
            <EditContent content={item} open={edit} toggleOpen={toggleEdit} />
        </Grid>
    )
}

export default VueItem