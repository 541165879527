import React from "react";
import { Box, Collapse, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon, Tooltip } from "@mui/material";
import { Agriculture, Blender, ChevronLeft, ChevronRight, Cookie, EnergySavingsLeaf, ExpandLess, ExpandMore, Fireplace, Healing, HealthAndSafety, Home, ImportContacts, LocalFlorist, LockPerson, Logout, Science } from '@mui/icons-material'

import { DrawerHeader } from "./Ui";
import AppContext from "../AppContext";
import { Link } from "react-router-dom";
import { logout } from "../_rxjs/auth";
import UserSpace from "./UserSpace";
import ThemeSwitcher from "./ThemeSwitcher";
import ThemeContext from "../themes/ThemeContext";

const LeftMenu = () => {
    const { ui } = React.useContext(AppContext)
    const { theme, name: nameTheme, toggle: toggleTheme } = React.useContext(ThemeContext)

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
            <DrawerHeader>
                <IconButton onClick={() => ui.drawer.toggle()}>
                    {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <UserSpace />
            <List sx={{ flexGrow: 1 }}>
                <NavButton
                    to="/"
                    icon={<Home />}
                    primary="Accueil"
                />
                <Divider />
                <NavList
                    keyCode="cuisine"
                    primary="Cuisine"
                    icon={<Cookie />}
                    list={[

                        { icon: <Cookie />, primary: 'Recettes', to: '/cuisine/recettes' },
                        { icon: <Blender />, primary: 'Ingredients', to: '/cuisine/ingredients' },
                    ]}
                />
                <Divider />
                <NavList
                    keyCode="jardin"
                    primary="Jardin potager"
                    icon={<Agriculture />}
                    list={[
                        {
                            icon: <SvgIcon >
                                <path fill="currentColor" d="M18.92 14C18.64 15.97 17.53 17.68 15.96 18.76C15.76 17.93 14.53 17.25 12.92 17.06C13.09 15.87 13.44 14.76 13.68 14.12C14.06 14.36 14.5 14.5 15 14.5C18.55 14.5 18 8.5 18 8.5S17.5 9.5 16 9.5H15C13.62 9.5 12.5 10.62 12.5 12C12.5 12.5 12.64 12.93 12.87 13.31C13.53 12.62 14.24 12 15 11.5C13.68 12.83 12.67 14.5 12 16.1C11.37 14.87 10.58 13.93 10 13.5C10.47 13.7 10.91 14 11.33 14.3C11.44 14.06 11.5 13.79 11.5 13.5C11.45 10.77 7 11 7 11C7.31 11.42 7.5 11.95 7.5 12.5V13.5C7.5 14.6 8.4 15.5 9.5 15.5C10 15.5 10.45 15.32 10.8 15C11.03 15.63 11.2 16.35 11.29 17.04C9.57 17.19 8.25 17.89 8.04 18.77C6.21 17.5 5 15.39 5 13C5 9.14 8.14 6 12 6V9L17 5L12 1V4C7.03 4 3 8.03 3 13S7.03 22 12 22C16.63 22 20.44 18.5 20.94 14H18.92Z" />
                            </SvgIcon>, primary: 'Engrais', to: '/jardin/engrais'
                        },
                        { icon: <LocalFlorist />, primary: 'Fleurs', to: '/jardin/fleurs' },
                        {
                            icon: <SvgIcon><path fill="currentColor" d="M16,10L15.8,11H13.5A0.5,0.5 0 0,0 13,11.5A0.5,0.5 0 0,0 13.5,12H15.6L14.6,17H12.5A0.5,0.5 0 0,0 12,17.5A0.5,0.5 0 0,0 12.5,18H14.4L14,20A2,2 0 0,1 12,22A2,2 0 0,1 10,20L9,15H10.5A0.5,0.5 0 0,0 11,14.5A0.5,0.5 0 0,0 10.5,14H8.8L8,10C8,8.8 8.93,7.77 10.29,7.29L8.9,5.28C8.59,4.82 8.7,4.2 9.16,3.89C9.61,3.57 10.23,3.69 10.55,4.14L11,4.8V3A1,1 0 0,1 12,2A1,1 0 0,1 13,3V5.28L14.5,3.54C14.83,3.12 15.47,3.07 15.89,3.43C16.31,3.78 16.36,4.41 16,4.84L13.87,7.35C15.14,7.85 16,8.85 16,10Z" /></SvgIcon>, primary: 'Legumes', to: '/jardin/legumes'
                        },
                        { icon: <Fireplace />, primary: 'Produits chimiques', to: '/jardin/produits-chimiques' },
                        { icon: <EnergySavingsLeaf />, primary: 'Purins', to: '/jardin/purins' },
                    ]}
                />
                <Divider />
                <NavButton
                    to="/sites"
                    icon={<LockPerson />}
                    primary="Mots de passe"
                />
                <Divider />
                <Box sx={{ flexGrow: 1 }} />
                <NavList
                    keyCode="healthcare"
                    primary="Santé"
                    icon={<Healing />}
                    list={[
                        { icon: <Science />, primary: 'Biologie', to: '/sante/biologie' },
                        { icon: <HealthAndSafety />, primary: 'Constantes', to: '/sante/constantes' },
                        { icon: <ImportContacts />, primary: 'Carnets de bord', to: '/sante/carnets-de-bord' },
                        // { icon: <Vaccines />, primary: 'Labos', to: '/sante/labos' }
                    ]}
                />
            </List>
            <Divider />
            <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: "space-around" }}>
                <Box>
                    <ThemeSwitcher checked={nameTheme === 'dark'} onChange={toggleTheme} />
                </Box>
                <Box>
                    <Tooltip title="Se déconnecter">
                        <IconButton onClick={() => { ui.drawer.toggle(); logout() }}>
                            <Logout />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>

        </Box>
    )
}

interface NavButtonProps {
    icon: JSX.Element;
    primary: string;
    to: string;
}

const NavButton = ({ icon, primary, to }: NavButtonProps) => {
    const { ui } = React.useContext(AppContext)
    return (
        <ListItem disablePadding>
            <ListItemButton component={Link} to={to} onClick={() => ui.drawer.toggle()}>
                <ListItemIcon>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={primary} />
            </ListItemButton>
        </ListItem>
    )
}
interface NavListProps {
    keyCode: string;
    primary: string;
    icon: JSX.Element
    list: NavButtonProps[]
}

const NavList = ({ keyCode, primary, icon, list }: NavListProps) => {
    const { ui } = React.useContext(AppContext)
    return (
        <React.Fragment>
            <ListItem disablePadding>
                <ListItemButton onClick={() => ui.drawer.setExpanded(keyCode)}>
                    <ListItemIcon>
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={primary} />
                    {ui.drawer.expanded.includes(keyCode) ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
            </ListItem>
            <Collapse in={ui.drawer.expanded.includes(keyCode)} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {list.map(item =>
                        <ListItemButton key={item.to} component={Link} to={item.to} onClick={() => ui.drawer.toggle()} sx={{ pl: 4 }}>
                            <ListItemIcon>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.primary} />
                        </ListItemButton>)}
                </List>
            </Collapse>
        </React.Fragment>
    )
}

export default LeftMenu