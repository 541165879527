import { TabPanel } from "@mui/lab"
import { Grid, TextField } from "@mui/material"
import React from "react"
import { FleurInterface } from "../../../../_api/_fleurs"
import { LegumeInterface } from "../../../../_api/_legumes"
import { ListAmisEnnemisEdit } from "../../ListAmisEnnemis"

interface TabAmisEnnemisProps {
    legume: LegumeInterface
    setLegume: (l: LegumeInterface) => void
}
const TabAmisEnnemis = ({ legume, setLegume }: TabAmisEnnemisProps) => {

    const handleChangeProtection = (event: any) => setLegume({ ...legume, protection: event.target.value })
    const handleChangeLegAmis = (items: LegumeInterface[]) => setLegume({ ...legume, legumesAmis: items })
    const handleChangeLegEnnemis = (items: LegumeInterface[]) => setLegume({ ...legume, legumesEnnemis: items })
    const handleChangeFleursAmis = (items: FleurInterface[]) => setLegume({ ...legume, fleursAmies: items })



    return (
        <TabPanel value="Amis/Ennemis">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Informations libres"
                        multiline
                        fullWidth
                        defaultValue={legume.protection}
                        onChange={handleChangeProtection}
                    />
                </Grid>
                <Grid item xs={4}>
                    <ListAmisEnnemisEdit title="Légumes amis" items={legume.legumesAmis || []} excludeItems={legume.legumesEnnemis || []} type="légume" setItems={(items: LegumeInterface[] | FleurInterface[]) => handleChangeLegAmis(items as LegumeInterface[])} />
                </Grid>
                <Grid item xs={4}>
                    <ListAmisEnnemisEdit title="Légumes ennemis" items={legume.legumesEnnemis || []} excludeItems={legume.legumesAmis || []} type="légume" setItems={(items: LegumeInterface[] | FleurInterface[]) => handleChangeLegEnnemis(items as LegumeInterface[])} />
                </Grid>
                <Grid item xs={4}>
                    <ListAmisEnnemisEdit title="Fleurs amies" items={legume.fleursAmies || []} type="fleur" setItems={(items: LegumeInterface[] | FleurInterface[]) => handleChangeFleursAmis(items as FleurInterface[])} />
                </Grid>

            </Grid>
        </TabPanel>
    )
}

export default TabAmisEnnemis
