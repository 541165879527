import { Box, Grid, List, ListSubheader, Typography } from "@mui/material"
import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { Fleur$, FleurInterface } from "../../../../_api/_fleurs"
import Page from "../../../Page/Page"
import ListAmisEnnemis from "../../ListAmisEnnemis"

export type FleursTabsValuesUsed = 'semis' | 'plantation' | 'recolte' | 'ami-ennemis'

const VueFleur = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<FleurInterface>()

    const handleEdit = () => navigate(`/jardin/fleurs/${data && data.id}/edit`)

    React.useEffect(() => {
        Fleur$.subscribe(l => l && setData(l))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.fleurs.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.variety ? `${data.name} – ${data.variety}` : data.name : "Fleur",
                subtitle: data ? data.latin || undefined : "Jardin",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
                toggleEdit: handleEdit,
            }}
            loading={!data}
        >
            {data &&
                <Box>
                    <Grid container spacing={2}>
                        {data.type &&
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Type de fleur</Typography>
                                <Typography variant="body2">{data.type}</Typography>
                            </Grid>}
                        {data.ravageurs && data.ravageurs.length > 0 &&
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h6">Ravageurs visés</Typography>
                                <Typography variant="body2">{data.ravageurs.map((ravageur, index) =>
                                    <Typography variant="body2" component="span">{ravageur.value}{index + 1 < data.ravageurs.length ? ', ' : ''}</Typography>)}</Typography>
                            </Grid>}
                        {data.legumesAmis && data.legumesAmis.length > 0 &&
                            <Grid item xs={12} sm={6}>
                                <List
                                    subheader={<ListSubheader>Légumes amis</ListSubheader>}
                                >
                                    {data.legumesAmis.map(legume => <ListAmisEnnemis legume={legume} />)}
                                </List>
                            </Grid>}
                    </Grid>
                </Box>}

        </Page>
    )
}

export default VueFleur