import { FleurInterface } from "../_fleurs";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()
    private _baseUrl = `/api/sante/labos`

    getAll = (): Promise<Response> => this._fetch.get(`${this._baseUrl}`)
    get = (id: FleurInterface['id']): Promise<Response> => this._fetch.get(`${this._baseUrl}/${id}`)
    post = (): Promise<Response> => this._fetch.post(`${this._baseUrl}`)
    put = (id: FleurInterface['id'], data: Partial<FleurInterface>): Promise<Response> => this._fetch.put(`${this._baseUrl}/${id}`, data)

    uploadImage = (id: FleurInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`${this._baseUrl}/${id}/image`, form)
    removeImage = (id: FleurInterface['id']) => this._fetch.delete(`${this._baseUrl}/${id}/image`)

    delete = (id: FleurInterface['id']): Promise<Response> => this._fetch.delete(`${this._baseUrl}/${id}`)

}