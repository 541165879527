import { Remove, Person, Add } from "@mui/icons-material";
import { TextField, InputAdornment, Button } from "@mui/material";
import React from "react";

interface NbPersonProps {
    nbPers: number
    handleChange: (nb: number) => void
    min?: number
}
const NbPerson = ({ nbPers, handleChange, min = 1 }: NbPersonProps) => {
    const add = () => handleChange(nbPers + 1)
    const remove = () => handleChange((nbPers > min && nbPers - 1) || nbPers)
    return <TextField
        label="Ingrédients pour"
        value={nbPers}
        onChange={(event: any) => Number(event.target.value) && handleChange(Number(event.target.value))}
        inputProps={{ style: { maxWidth: '3em', textAlign: 'center', fontSize: 'large' }, min: 1, disable: "true" }}
        InputProps={{
            startAdornment: <><InputAdornment position="start"><Button onClick={remove} disabled={nbPers === 1}><Remove /></Button></InputAdornment><Person /></>,
            endAdornment: <InputAdornment position="end"><Button onClick={add}><Add /></Button></InputAdornment>
        }}
    />
}

export default NbPerson