import React from 'react'
import { BehaviorSubject, map } from 'rxjs'
import { tokensInterface, UserInterface } from '../useApp'

const LS_NAME = 'userTokens'

let localStorageStringNullTokens = localStorage.getItem(LS_NAME)
let initialTokens: tokensInterface | null = null;
if (localStorageStringNullTokens) {
    initialTokens = JSON.parse(localStorageStringNullTokens) as tokensInterface
} else {
    initialTokens = null;
}

export const authTokens$ = new BehaviorSubject<tokensInterface | null>(initialTokens)

export const setTokens = (tokens: tokensInterface | null) => {
    authTokens$.next(tokens)
    if (tokens) {
        localStorage.setItem(LS_NAME, JSON.stringify(tokens))
    } else {
        localStorage.removeItem(LS_NAME)
    }
}

export const logout = () => {
    setTokens(null)
    clearDb()
    // location.reload()
}

export const login = async (email: string, password: string): Promise<void> => {
    clearDb()
    return fetch('/api/auth/login', {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, password })
    })
        .then((resp) => resp.status === 200 ? resp.json().then((tokens: tokensInterface) => setTokens(tokens)) : setTokens(null))
}

export const refreshTokens = async (): Promise<boolean> => (authTokens$.getValue() !== null && fetch('/api/auth/refresh', {
    method: 'POST',
    headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: authTokens$.getValue()!.refreshToken })
}).then(resp => resp.status === 200
    ? resp.json().then((newToken: tokensInterface | null) => { setTokens(newToken) }).then(() => true)
    : false
)) || false

// export const refresh = async (callback: any): Promise<tokensInterface | null> => {
//     if (initialTokens) {
//         return fetch('/api/auth/refresh', {
//             method: 'POST',
//             headers: {
//                 'Accept': 'application/json, text/plain, */*',
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ token: initialTokens.refreshToken })
//         })
//             .then(resp => resp.status === 200
//                 ? resp.json().then((newToken: tokensInterface | null) => {
//                     setTokens(newToken)
//                     return newToken
//                 })
//                 : null
//             )
//     } else {
//         setTokens(null)
//         return null
//     }
// }
const clearDb = async () => {
    console.log('clearDb')
    indexedDB.databases().then(dbs => dbs.forEach(db => {
        if (db.name && db.name.startsWith('lucas')) {
            const table = indexedDB.open(db.name)
            table.onsuccess = function (event) {
                const t = table.result
                const storeName = t.objectStoreNames[0]
                const transaction = t.transaction([storeName], "readwrite")
                const objectStore = transaction.objectStore(storeName)
                objectStore.clear()
            }
        }
    }))
}

export const useAuth = (): [tokensInterface | null, UserInterface | null] => {

    const [tokens, setTokens] = React.useState<tokensInterface | null>(null)

    const [user, setUser] = React.useState<UserInterface | null>(null)

    React.useEffect(() => {
        authTokens$.subscribe(t => setTokens(t))
        authTokens$.pipe(
            map(tokens => {
                if (tokens) {
                    var base64Url = tokens.accessToken.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    try {
                        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
                            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                        }).join(''));
                        return { ...JSON.parse(jsonPayload) };
                    } catch (e) {
                        localStorage.clear()
                        return null
                    }
                }
                return null;
            })
        ).subscribe(u => setUser(u))
    }, [])
    return [tokens, user];
}