
import { useSensors, useSensor, PointerSensor, DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities'
import { Add, DragHandle, } from '@mui/icons-material';
import { TabPanel } from '@mui/lab';
import { Box, Grid, Typography, TextField, Button, Divider } from '@mui/material';
import React from 'react';
import { CuisineInterface, CuisinePreparationInterface } from '../../../_api/_cuisines';
import DeleteButtonConfirmation from '../../DeleteButtonConfirmation';

interface TabPreparationProps {
    data: CuisineInterface;
    setData: (cuisine: CuisineInterface) => void
}
const TabPreparation = ({ data, setData }: TabPreparationProps) => {
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: { distance: 5 },
        })
    );

    const addEtape = () => setData({ ...data, preparations: [...data.preparations, { id: new Date().getTime(), value: '' }] })

    const changeValue = (id: CuisinePreparationInterface['id'], value: CuisinePreparationInterface['value']) => setData({
        ...data,
        preparations: data.preparations.map(preparation => preparation.id === id ? ({ ...preparation, value }) : preparation)
    })

    const deletePreparation = (id: CuisinePreparationInterface['id']) => setData({
        ...data,
        preparations: data.preparations.filter(preparation => preparation.id !== id)
    })

    const handleDragEnd = (event: any) => {
        console.log(event)
        const { active: { data: { current: { sortable: { index: oldIndex } } } }, over: { data: { current: { sortable: { index: newIndex } } } } } = event;
        if (oldIndex !== newIndex) setData({ ...data, preparations: arrayMove(data.preparations, oldIndex, newIndex) })
    }

    return (
        <TabPanel value="Préparation" sx={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box>
                <Button variant="outlined" color='primary' onClick={addEtape}>
                    <Add />  Ajouter une étape
                </Button>
            </Box>
            <Divider sx={{ mb: 4, mt: 2 }} />
            {data &&
                <Box sx={{ flexGrow: 1, overflowY: 'auto', pt: 1 }}>
                    <DndContext
                        sensors={sensors}
                        collisionDetection={closestCenter}
                        onDragEnd={handleDragEnd}
                    >
                        <SortableContext
                            items={data.preparations}
                            strategy={verticalListSortingStrategy}
                        >
                            <Grid container spacing={2} direction="column">
                                {data.preparations.map((preparation, index) =>
                                    <SortableLine key={preparation.id} preparation={preparation} index={index} changeValue={changeValue} deletePreparation={deletePreparation} />)}
                                {data.preparations.length === 0 && <Grid item><Typography>Aucune étape de préparation…</Typography></Grid>}
                            </Grid>
                        </SortableContext>
                    </DndContext>
                </Box>}
        </TabPanel>
    )
}
interface SortableLineProps {
    preparation: CuisinePreparationInterface;
    index: number;
    changeValue: (id: CuisinePreparationInterface['id'], value: CuisinePreparationInterface['value']) => void
    deletePreparation: (id: CuisinePreparationInterface['id']) => void
}
const SortableLine = ({ preparation, index, changeValue, deletePreparation }: SortableLineProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: preparation.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    const handleChangeValue = (event: any) => changeValue(preparation.id, event.target.value)

    const handleDelete = () => deletePreparation(preparation.id)
    return (
        <Grid item key={preparation.id} ref={setNodeRef} style={style} {...attributes}>
            <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
                <Box>
                    <DragHandle {...listeners} />
                </Box>
                <Box sx={{ flexGrow: 1, ml: 2 }}>
                    <TextField
                        value={preparation.value || ''}
                        onChange={handleChangeValue}
                        fullWidth
                        label={`Etape ${index + 1}`}
                        multiline
                    />
                </Box>
                <Box>
                    <DeleteButtonConfirmation rounded onDelete={handleDelete} />
                </Box>
            </Box>
        </Grid>
    )
}

export default TabPreparation