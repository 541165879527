import { Edit } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"


interface EditButtonProps {
    to: string,
    title?: string
}
const EditButton = ({ to, title = 'Editer' }: EditButtonProps) => {
    return (
        <Tooltip title={title}>
            <IconButton component={Link} to={to}>
                <Edit />
            </IconButton>
        </Tooltip>
    )
}

export default EditButton