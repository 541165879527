import { Card, CardActionArea, CardContent, Grid, LinearProgress, Typography } from "@mui/material";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import AppContext from "../../../AppContext";
import { CarnetInterface } from "../../../useApp";
import { Carnets$ } from "../../../_api/_carnets";
import Page from "../../Page/Page";

const JdBLanding = () => {
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<CarnetInterface[]>()

    const handleAdd = () => api.sante.carnets.post().then(id => id && navigate(`/sante/carnets-de-bord/${id}`))

    React.useEffect(() => {
        Carnets$.subscribe(data => setData(data))
        api.sante.carnets.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Page
            loading={!data}
            title={{ title: 'Carnets de bord' }}
            searchable={{
                add: handleAdd,
                addPlaceholder: 'Créer un nouveau carnet de bord'
            }}
        >
            <Grid container spacing={2}>
                {!data
                    ? <Grid item xs={12}><LinearProgress /></Grid>
                    : data.map(carnet =>
                        <Grid item xs={12} md={4} key={carnet.id}>
                            <Carnet carnet={carnet} />
                        </Grid>)}
            </Grid>
        </Page>
    )
}

interface CarnetProps {
    carnet: CarnetInterface;
}

const Carnet = ({ carnet }: CarnetProps) => {
    return (
        <Card>
            <CardActionArea component={Link} to={`/sante/carnets-de-bord/${carnet.id}`}>
                <CardContent>
                    <Typography variant="h6">
                        {carnet.title}
                    </Typography>
                    <Typography variant="subtitle2">
                        du {new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' })
                            .format(new Date(carnet.createdAt))} au {new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' })
                                .format(new Date(carnet.updatedAt))}</Typography>
                    <Typography variant="caption">{carnet.Journals.length || 'Aucun'} journa{carnet.Journals.length > 1 ? 'ux' : 'l'}</Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default JdBLanding