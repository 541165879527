import { ThemeOptions } from "@mui/material"
import React from "react"
import darkTheme from "./dark"
import lightTheme from "./light"


export type useThemesInterface = () => {
    theme: ThemeOptions,
    name: 'light' | 'dark',
    toggle: () => void
}

const useThemes: useThemesInterface = () => {
    const [theme, setTheme] = React.useState<ThemeOptions>(window.matchMedia('(prefers-color-scheme: dark)').matches ? darkTheme : lightTheme)
    const toggle = () => setTheme(theme.palette!.mode === 'light' ? darkTheme : lightTheme)
    return ({
        theme,
        name: theme.palette!.mode as 'light' | 'dark',
        toggle
    })
}
export default useThemes