import { BehaviorSubject } from "rxjs"
import Http from './http/fleurs'
import Idb from "../__api_v2/_Idb"

export interface LaboInterface {
    id: string;
    date: string;
    LaboValues: LaboValues[]
}

export interface LaboValues {
    id: string;
    type: valueType;
    value: number
}

export type valueType = 'hematie' | 'hb' | 'hc' | 'vgm' | 'ccmh' | 'tcmh' | 'leuco' | 'neutro' | 'eosino' | 'baso' | 'lympho' | 'mono' | 'thrombo' | 'asat' | 'alat' | 'ggt' | 'pal' | 'ldh' | 'psa_t' | 'psa_l' | 'psa_t_l' | 'protides' | 'albumine' | 'vit_d2_d3' | 'vit_b12' | 'vit_b9' | 'ca_19.9' | 'ace' | 'tp' | 'inr' | 'tck_t' | 'tck_p' | 'tck_r' | 'uree' | 'creat' | 'mdrd' | 'dfg' | 'pcr' | 'ac_urique' | 'glucose' | 'serum_aspect' | 'na' | 'k' | 'ca' | 'cl' | 'ra' | 'bili_t' | 'bili_c' | 'bili_l' | 'trigly' | 'chol_t' | 'chol_lie_hdl' | 'chol_t/hdl' | 'ldl' | 'tsh'



export const Labos$ = new BehaviorSubject<LaboInterface[] | null>(null)
export const Labo$ = new BehaviorSubject<LaboInterface | null>(null)

export default class ApiLabos {
    private _idb!: Idb<'labos'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('labos')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Labos$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: LaboInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Labos$.next(data)))
                }
            }))
    }

    get = (id: LaboInterface['id']): void => {
        this._idb.get(id).then(data => data && Labo$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: LaboInterface) => {
                    this._idb.post(data)
                    Labo$.next(data)
                })
            }
        })
    }

    post = (): Promise<LaboInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((el: LaboInterface) => {
                this._idb.post(el);
                Labo$.next(el)
                return el.id
            })
        } else {
            return null;
        }
    }).catch(() => null)

    put = (id: LaboInterface['id'], fleur: Partial<LaboInterface>): Promise<number> => this._http.put(id, fleur).then(resp => {
        if (resp.status === 200) {
            const newFleur = { ...(Labo$.getValue() as LaboInterface), ...fleur }
            this._idb.post(newFleur)
            Labo$.next(newFleur)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: LaboInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newFleur = { ...(Labo$.getValue() as LaboInterface), imageId: id }
                    this._idb.post(newFleur)
                    Labo$.next(newFleur)
                })
            }
        })
    }

    deleteImage = async (id: LaboInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newFleur = { ...(Labo$.getValue() as LaboInterface), imageId: null }
            this._idb.post(newFleur)
            Labo$.next(newFleur)
        }
    })

    delete = async (id: LaboInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Labo$.next(null)
            return true
        }
        return false
    })
}