import { TabPanel } from '@mui/lab'
import { Grid, TextField, Box, InputAdornment } from '@mui/material'
import React from 'react'
import { ListItemInterface } from '../../../../useApp'
import { LegumeInterface } from '../../../../_api/_legumes'
import { SelectCustomMultiple } from '../../../SelectCustom'
import SortableList from '../../../SortableList'

interface TabPlantationProps {
    legume: LegumeInterface
    setLegume: (l: LegumeInterface) => void
}
const TabPlantation = ({ legume, setLegume }: TabPlantationProps) => {
    const handleSaisonChange = (options: ListItemInterface[]) => setLegume({ ...legume, plantationSaisons: options })
    const handleChangeArosFreq = (event: any) => setLegume({ ...legume, arrosageFreq: +event.target.value })
    const handleChangeArosVol = (event: any) => setLegume({ ...legume, arrosageVol: +event.target.value })
    const handlePlantatinTaskChange = (options: ListItemInterface[]) => setLegume({ ...legume, plantationTasks: options })
    const handleChangePlantation = (event: any) => setLegume({ ...legume, plantation: event.target.value })

    return (
        <TabPanel value="Plantation">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Informations libres"
                        multiline
                        fullWidth
                        value={legume.plantation || ''}
                        onChange={handleChangePlantation}
                    />
                </Grid>
                <Grid item xs={6}>
                    <SelectCustomMultiple label="Saison de plantation" value={legume.plantationSaisons.map(s => s.id)} type="jarSaison" onChange={handleSaisonChange} />
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex' }}>
                        <TextField
                            label="Fréquence d'arrosage"
                            fullWidth
                            defaultValue={legume.arrosageFreq || ''}
                            onChange={handleChangeArosFreq}
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 1,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>,
                            }}
                        />
                        <TextField
                            label="Volume d'arrosage"
                            fullWidth
                            defaultValue={legume.arrosageVol || ''}
                            onChange={handleChangeArosVol}
                            type="number"
                            inputProps={{
                                min: 0,
                                step: 1,
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">L</InputAdornment>,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <SortableList
                        title="Tâches avant plantation"
                        activeItems={legume.plantationTasks}
                        setItems={handlePlantatinTaskChange} />
                </Grid>

            </Grid>

        </TabPanel>
    )
}

export default TabPlantation
