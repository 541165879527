import React from "react";

import { Box, Chip, Collapse, Divider, Fab, Stack, Typography } from "@mui/material";
import { Edit } from '@mui/icons-material'

import { JournalInterface } from "../../../useApp";
import VueJournalEdit from "./VueJournalEdit";

interface JournalProps {
    journal: JournalInterface
    isLast: boolean,
}

const VueJournal = ({ journal, isLast }: JournalProps) => {
    const [isEdit, toggleEdit] = React.useReducer(a => !a, journal.title === 'Nouveau journal')

    return (
        <Box sx={{ mt: 2, mb: 2 }} className="hoverContainer">
            <Collapse in={!isEdit}>
                <Box display="flex" justifyContent="space-between">
                    <Typography variant="h5" gutterBottom>{journal.title}</Typography>
                    <Fab color="primary" aria-label="edit" className="hoverTarget" size="small" onClick={toggleEdit}>
                        <Edit />
                    </Fab>
                </Box>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Stack sx={{ flexGrow: 1 }}>
                        {journal.Articles.map(article => article.value.split("\n").map((a, i) => <Typography key={article.id + i} variant="body1" gutterBottom>{a}</Typography>)
                        )}
                    </Stack>
                    {journal.eis.length > 0 &&
                        <Stack sx={{ gap: 1 }}>
                            {journal.eis.sort((a, b) => a.value < b.value ? -1 : 1).map(ei => <Chip key={ei.id} label={ei.value} size="small" />)}
                        </Stack>}
                </Box>
            </Collapse>
            <Collapse in={isEdit} mountOnEnter unmountOnExit>
                <VueJournalEdit journal={journal} toggleEdit={toggleEdit} />
            </Collapse>
            {!isLast && <Divider sx={{ mt: 2 }} />}
        </Box>
    )
}

export default VueJournal