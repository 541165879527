import { Add, Delete, NorthEast } from '@mui/icons-material'
import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, Box, List, ListSubheader, Menu, MenuItem, Tooltip } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { FleurInterface, Fleurs$ } from '../../_api/_fleurs'
import { LegumeInterface, Legumes$ } from '../../_api/_legumes'

interface ElAmisEnnemisProps {
    legume: LegumeInterface | FleurInterface
    fleur?: boolean
}
export const ListAmisEnnemis = ({ legume: l, fleur = false }: ElAmisEnnemisProps) =>
    <ListItem key={l.id} button component={Link} to={`/jardin/${fleur === true ? 'fleurs' : 'legumes'}/${l.id}`}>
        <ListItemAvatar>
            <Avatar src={l.imageId ? `/download/${l.imageId}` : undefined}>
                {l.name[0]}{(l.variety && l.variety[0]) || ''}
            </Avatar>
        </ListItemAvatar>
        <ListItemText primary={l.name} secondary={l.variety} />
        <ListItemSecondaryAction sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton component={Link} to={`/jardin/legumes/${l.id}`}>
                <NorthEast />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>

interface AMListProps {
    title: string,
    items: LegumeInterface[] | FleurInterface[]
    excludeItems?: LegumeInterface[]
    type: 'légume' | 'fleur',
    setItems: (items: LegumeInterface[] | FleurInterface[]) => void
}
export const ListAmisEnnemisEdit = ({ title, items, type, setItems, excludeItems = [] }: AMListProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const options = type === "légume"
        ? (Legumes$.getValue() as LegumeInterface[])
            .filter(l => !(excludeItems as LegumeInterface[]).map(i => i.id).includes(l.id))
            .filter(l => !(items as LegumeInterface[]).map(i => i.id).includes(l.id))
            .sort((a, b) => (`${a.name} ${a.variety || ''}`).toLowerCase() < (`${b.name} ${b.variety || ''}`).toLowerCase() ? -1 : 1)
        : (Fleurs$.getValue() as FleurInterface[])
            .filter(l => !(items as FleurInterface[]).map(i => i.id).includes(l.id))
            .sort((a, b) => (`${a.name} ${a.variety || ''}`).toLowerCase() < (`${b.name} ${b.variety || ''}`).toLowerCase() ? -1 : 1)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const addItem = (item: LegumeInterface | FleurInterface) => setItems(type === 'légume'
        ? [...items, item] as LegumeInterface[] : [...items, item] as FleurInterface[])
    const deleteItem = (item: LegumeInterface | FleurInterface) => setItems(type === 'légume'
        ? (items as LegumeInterface[]).filter((f) => f.id !== item.id) : (items as FleurInterface[]).filter((f) => f.id !== item.id))
    return (
        <List
            subheader={<ListSubheader>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    {title}
                    {options.length > 0 && <Box>
                        <Tooltip title={`Ajouter un${type === 'légume' ? '' : 'e'} ${type}…`}>
                            <IconButton onClick={handleClick}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Box>}
                    <AMListMenu items={options} anchorEl={anchorEl} handleClose={() => setAnchorEl(null)} addItem={addItem} />
                </Box>

            </ListSubheader>}
        >
            {items.map(item => <AMListItem key={item.id} item={item} deleteItem={deleteItem} />)}
        </List>
    )
}
interface AMListItemProps {
    item: LegumeInterface | FleurInterface
    deleteItem: (item: LegumeInterface | FleurInterface) => void
}
const AMListItem = ({ item, deleteItem }: AMListItemProps) => {
    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar src={undefined}>
                    {item.name[0]}{item.variety ? item.variety[0] : null}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.name} secondary={item.variety || undefined} />
            <ListItemSecondaryAction className="hoverContainer">
                <IconButton color="error" onClick={() => deleteItem(item)}>
                    <Delete />
                </IconButton>
            </ListItemSecondaryAction>

        </ListItem>
    )
}

interface AMListMenuProps {
    items: LegumeInterface[] | FleurInterface[]
    anchorEl: null | HTMLElement
    handleClose: () => void
    addItem: (item: LegumeInterface | FleurInterface) => void
}
const AMListMenu = ({ items, anchorEl, handleClose, addItem }: AMListMenuProps) => {
    const open = Boolean(anchorEl)
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
                style: {
                    maxHeight: 48 * 10,
                },
                elevation: 0,
                sx: {
                    // overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    mb: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {items.map((a) => <MenuItem key={a.id} onClick={() => addItem(a)}>
                <ListItemAvatar>
                    <Avatar src={a.imageId ? `/download/${a.imageId}` : undefined}>
                        {a.name[0]}{a.variety ? a.variety[0] : null}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={a.name} secondary={a.variety} />
            </MenuItem>)}
        </Menu>
    )
}

export default ListAmisEnnemis