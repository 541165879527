import React from "react"
import { TabPanel } from "@mui/lab"
import { Grid, TextField } from "@mui/material"
import { LegumeInterface } from "../../../../_api/_legumes"

interface TabRecolteProps {
    legume: LegumeInterface
    setLegume: (l: LegumeInterface) => void
}
const TabRecolte = ({ legume, setLegume }: TabRecolteProps) => {
    const handleChangeRecolte = (event: any) => setLegume({ ...legume, recolte: event.target.value })

    return (
        <TabPanel value="Recolte">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        label="Informations libres"
                        multiline
                        fullWidth
                        value={legume.recolte || ''}
                        onChange={handleChangeRecolte}
                    />
                </Grid>
            </Grid>
        </TabPanel>
    )
}

export default TabRecolte