import { PurinInterface } from "../_purins";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()
    private _baseUrl = `/api/jardin/purins`

    getAll = (): Promise<Response> => this._fetch.get(`${this._baseUrl}`)
    get = (id: PurinInterface['id']): Promise<Response> => this._fetch.get(`${this._baseUrl}/${id}`)
    post = (): Promise<Response> => this._fetch.post(`${this._baseUrl}`)
    put = (id: PurinInterface['id'], data: Partial<PurinInterface>): Promise<Response> => this._fetch.put(`${this._baseUrl}/${id}`, data)

    uploadImage = (id: PurinInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`${this._baseUrl}/${id}/image`, form)
    removeImage = (id: PurinInterface['id']) => this._fetch.delete(`${this._baseUrl}/${id}/image`)


    delete = (id: PurinInterface['id']): Promise<Response> => this._fetch.delete(`${this._baseUrl}/${id}`)

}