import { Box, Collapse, ToggleButtonGroup, ToggleButton } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { SelectCustomEnum } from "../../../SelectCustom";

type SorterModeType = 'week' | 'query'
type SorterQuery = { de: number, a: number } | undefined

interface SorterProps {
    mode: SorterModeType, setMode: Dispatch<SetStateAction<SorterModeType>>,
    year: number, setYear: Dispatch<SetStateAction<number | undefined>>,
    years: number[],
    yearQuery: SorterQuery, setYearQuery: Dispatch<SetStateAction<SorterQuery>>
    week: number, setWeek: Dispatch<SetStateAction<number | undefined>>,
    weeks: number[],
    weekQuery: SorterQuery, setWeekQuery: Dispatch<SetStateAction<SorterQuery>>
    isVisible?: boolean
}

const Sorter = ({ mode, setMode, year, years, setYear, yearQuery, setYearQuery, week, setWeek, weeks, weekQuery, setWeekQuery, isVisible = true }: SorterProps) => {
    return (
        <React.Fragment>
            <Box sx={{ display: { xs: 'none', md: isVisible ? 'flex' : 'none' }, flexGrow: 1, gap: 2, alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Collapse in={mode === 'week'}>
                        <SelectCustomEnum
                            label={"Année"}
                            value={year.toString() || null}
                            options={years?.map(y => y.toString()) || []}
                            onChange={(y: string) => setYear(+y)}
                            maxOptions={10}
                        />
                    </Collapse>
                    <Collapse in={mode === 'query'}>
                        {yearQuery && <Box sx={{ display: 'flex', gap: 1 }}>

                            <SelectCustomEnum
                                label={"De l'année"}
                                value={yearQuery.de.toString()}
                                options={years?.filter(w => !yearQuery ? true : w <= yearQuery.a).map(y => y.toString()) || []}
                                onChange={(w: string) => setYearQuery({ ...yearQuery, de: +w })}
                                maxOptions={10}
                            />
                            <SelectCustomEnum
                                label={"A l'année"}
                                value={yearQuery.a.toString()}
                                options={years?.filter(w => !yearQuery ? true : w >= yearQuery.de).map(y => y.toString()) || []}
                                onChange={(w: string) => setYearQuery({ ...yearQuery, a: +w })}
                                maxOptions={10}
                            />
                        </Box>}
                    </Collapse>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                    <Collapse in={mode === 'week'}>
                        <SelectCustomEnum
                            label={"Semaine"}
                            value={week.toString() || null}
                            options={weeks?.map(y => y.toString()) || []}
                            onChange={(w: string) => setWeek(+w)}
                            maxOptions={10}
                        />
                    </Collapse>
                    <Collapse in={mode === 'query'}>
                        {weekQuery && <Box sx={{ display: 'flex', gap: 1 }}>

                            <SelectCustomEnum
                                label={"De la semaine"}
                                value={weekQuery.de.toString()}
                                options={weeks?.filter(w => !weekQuery ? true : w <= weekQuery.a).map(y => y.toString()) || []}
                                onChange={(w: string) => setWeekQuery({ ...weekQuery, de: +w })}
                                maxOptions={10}
                            />
                            <SelectCustomEnum
                                label={"A la semaine"}
                                value={weekQuery.a.toString()}
                                options={weeks?.filter(w => !weekQuery ? true : w >= weekQuery.de).map(y => y.toString()) || []}
                                onChange={(w: string) => setWeekQuery({ ...weekQuery, a: +w })}
                                maxOptions={10}
                            />
                        </Box>}
                    </Collapse>
                </Box>

                {(years.length > 1 || weeks.length > 1) &&
                    <Box>
                        <ToggleButtonGroup
                            value={mode}
                            onChange={(event: any, value: SorterModeType) => value && setMode(value)}
                            exclusive
                        >
                            <ToggleButton value="week">Par semaine</ToggleButton>
                            <ToggleButton value="query">Interval</ToggleButton>
                        </ToggleButtonGroup>
                    </Box>}
            </Box>


        </React.Fragment>
    )
}

export default Sorter