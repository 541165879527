export type ExamIds = 'hematie' | 'hb' | 'hc' | 'vgm' | 'ccmh' | 'tcmh' | 'leuco' | 'neutro' | 'eosino' | 'baso' | 'lympho' | 'mono' | 'thrombo' | 'asat' | 'alat' | 'ggt' | 'pal' | 'ldh' | 'psa_t' | 'psa_l' | 'psa_t_l' | 'protides' | 'albumine' | 'vit_d2_d3' | 'vit_b12' | 'vit_b9' | 'ca_19.9' | 'ace' | 'tp' | 'inr' | 'tck_t' | 'tck_p' | 'tck_r' | 'uree' | 'creat' | 'mdrd' | 'dfg' | 'pcr' | 'ac_urique' | 'glucose' | 'serum_aspect' | 'na' | 'k' | 'ca' | 'cl' | 'ra' | 'bili_t' | 'bili_c' | 'bili_l' | 'trigly' | 'chol_t' | 'chol_lie_hdl' | 'chol_t/hdl' | 'ldl' | 'tsh'

export type Examin = {
    id: ExamIds,
    name: string,
    inGroupName?: string,
    groupName?: string,
    min: number | null,
    max: number | null,
}

export type GroupExamins = {
    id: string,
    name: string,
    examins: Examin[]
}


export const labos: GroupExamins[] = [
    {
        "id": "ng",
        "name": "Numération Globulaire",
        "examins": [
            {
                "id": "hematie",
                "name": "Hémathies",
                "min": 4600000,
                "max": 6400000
            },
            {
                "id": "hb",
                "name": "Hémoglobine",
                "min": 13,
                "max": 17
            },
            {
                "id": "hc",
                "name": "Hématocrite",
                "min": 37,
                "max": 50
            },
            {
                "id": "vgm",
                "name": "VGM",
                "min": 80,
                "max": 100
            },
            {
                "id": "ccmh",
                "name": "CCMH",
                "min": 29.5,
                "max": 35.5
            },
            {
                "id": "tcmh",
                "name": "TCMH",
                "min": 26,
                "max": 33
            },
            {
                "id": "leuco",
                "name": "Leucocytes",
                "min": 4000,
                "max": 10000
            },
            {
                "id": "neutro",
                "name": "Polynucléaires Neutrophiles",
                "inGroupName": "Neutrophiles",
                "groupName": "Polynucléaires",
                "min": 1800,
                "max": 7500
            },
            {
                "id": "eosino",
                "name": "Polynucléaires Eosinophiles",
                "inGroupName": "Eosinophiles",
                "groupName": "Polynucléaires",
                "min": 0,
                "max": 800
            },
            {
                "id": "baso",
                "name": "Polynucléaires Basophiles",
                "inGroupName": "Basophiles",
                "groupName": "Polynucléaires",
                "min": 0,
                "max": 200
            },
            {
                "id": "lympho",
                "name": "Lymphocytes",
                "min": 1000,
                "max": 4000
            },
            {
                "id": "mono",
                "name": "Monocytes",
                "min": 200,
                "max": 1000
            },
            {
                "id": "thrombo",
                "name": "Numération des plaquettes",
                "min": 150000,
                "max": 500000
            }
        ]
    },
    {
        "id": "es",
        "name": "Enzymologie sanguine",
        "examins": [
            {
                "id": "asat",
                "name": "SGOT ASAT",
                "groupName": "Transaminases",
                "min": 5,
                "max": 34
            },
            {
                "id": "alat",
                "name": "SGPT ALAT",
                "groupName": "Transaminases",
                "min": 5,
                "max": 55
            },
            {
                "id": "ggt",
                "name": "Gama glutamyl",
                "min": 12,
                "max": 64
            },
            {
                "id": "pal",
                "name": "Phosphatases alcalines",
                "min": 40,
                "max": 150
            },
            {
                "id": "ldh",
                "name": "Lacto-Déshydrogenase",
                "min": 125,
                "max": 220
            }
        ]
    },
    {
        "id": "pm",
        "name": "Protéines Marqueurs",
        "examins": [
            {
                "id": "psa_t",
                "groupName": "PSA",
                "name": "PSA totales",
                "inGroupName": "Totales",
                "min": 0,
                "max": 4
            },
            {
                "id": "psa_l",
                "groupName": "PSA",
                "name": "PSA Libres",
                "inGroupName": "Libres",
                "min": null,
                "max": null
            },
            {
                "id": "psa_t_l",
                "groupName": "PSA",
                "name": "PSA libres/totales",
                "inGroupName": "Rapport",
                "min": null,
                "max": null
            },
            {
                "id": "protides",
                "name": "Protides totaux",
                "min": 64,
                "max": 83
            },
            {
                "id": "albumine",
                "name": "Albumine sérique",
                "min": 34,
                "max": 48
            },
            {
                "id": "vit_d2_d3",
                "name": "Vitamine D2-D3 (25OH)",
                "min": 20,
                "max": null
            },
            {
                "id": "vit_b12",
                "name": "Vitamines B12",
                "min": 187,
                "max": 883
            },
            {
                "id": "vit_b9",
                "name": "Folates sériques Vitamines B9",
                "min": 3.1,
                "max": 20.5
            },
            {
                "id": "ca_19.9",
                "name": "Antigène CA 19-9",
                "min": null,
                "max": 37
            },
            {
                "id": "ace",
                "name": "ACE",
                "min": null,
                "max": 5
            }
        ]
    },
    {
        "id": "hs",
        "name": "Hémostase sanguine",
        "examins": [
            {
                "id": "tp",
                "name": "Taux de Prothrombine",
                "min": 70,
                "max": 130
            },
            {
                "id": "inr",
                "name": "INR",
                "min": null,
                "max": 4
            },
            {
                "id": "tck_t",
                "groupName": "Temps de Cephaline Kaolin (TCK)",
                "name": "Temps de cephaline kaolin — témoin ",
                "inGroupName": "Témoin",
                "min": null,
                "max": null
            },
            {
                "id": "tck_p",
                "groupName": "Temps de Cephaline Kaolin (TCK)",
                "name": "Temps de cephaline kaolin — patient",
                "inGroupName": "Patient",
                "min": null,
                "max": 38
            },
            {
                "id": "tck_r",
                "groupName": "Temps de Cephaline Kaolin (TCK)",
                "name": "Temps de cephaline kaolin — rapport",
                "inGroupName": "Rapport",
                "min": 0.8,
                "max": 1.2
            }
        ]
    },
    {
        "id": "bs",
        "name": "Biochimie sanguine",
        "examins": [
            {
                "id": "uree",
                "name": "Urée",
                "min": 0.18,
                "max": 0.55
            },
            {
                "id": "creat",
                "name": "Créatinine",
                "min": 7.3,
                "max": 11.8
            },
            {
                "id": "mdrd",
                "name": "Clairance estimée (MDRD)",
                "min": null,
                "max": null
            },
            {
                "id": "dfg",
                "name": "Débit Filtration Glomérulaire",
                "min": 90,
                "max": null
            },
            {
                "id": "pcr",
                "name": "Protéine C Réactive",
                "min": null,
                "max": 5
            },
            {
                "id": "ac_urique",
                "name": "Acide urique",
                "min": 35,
                "max": 72
            },
            {
                "id": "glucose",
                "name": "Glycémie",
                "min": 0.8,
                "max": 1.15
            },
            {
                "id": "serum_aspect",
                "name": "Aspect du sérum",
                "min": null,
                "max": null
            },
            {
                "id": "na",
                "name": "Sodium",
                "min": 136,
                "max": 145
            },
            {
                "id": "k",
                "name": "Potassium",
                "min": 3.5,
                "max": 5.1
            },
            {
                "id": "ca",
                "name": "Calcium",
                "min": 88,
                "max": 100
            },
            {
                "id": "cl",
                "name": "Chlore",
                "min": 98,
                "max": 107
            },
            {
                "id": "ra",
                "name": "Réserve alcaline",
                "min": 23,
                "max": 31
            },
            {
                "id": "bili_t",
                "groupName": "Bilirubine",
                "name": "Bilirubine totale",
                "inGroupName": "Totale",
                "min": 2,
                "max": 12
            },
            {
                "id": "bili_c",
                "groupName": "Bilirubine",
                "name": "Bilirubine conjuguée",
                "inGroupName": "Conjuguée",
                "min": null,
                "max": 5
            },
            {
                "id": "bili_l",
                "groupName": "Bilirubine",
                "name": "Bilirubine libre",
                "inGroupName": "Libre",
                "min": null,
                "max": 5
            },
            {
                "id": "trigly",
                "name": "Triglycérides",
                "min": null,
                "max": 1.5
            },
            {
                "id": "chol_t",
                "groupName": "Cholestérol",
                "name": "Cholestérol total",
                "inGroupName": "Total",
                "min": null,
                "max": 2
            },
            {
                "id": "chol_lie_hdl",
                "groupName": "Cholestérol",
                "name": "Cholestérol lié",
                "inGroupName": "Lié",
                "min": 0.4,
                "max": null
            },
            {
                "id": "chol_t/hdl",
                "groupName": "Cholestérol",
                "name": "Cholestérol rapport",
                "inGroupName": "Rapport",
                "min": null,
                "max": 5
            },
            {
                "id": "ldl",
                "groupName": "Cholestérol",
                "name": "LDL",
                "inGroupName": "LDL",
                "min": null,
                "max": 1.59
            }
        ]
    },
    {
        "id": "hormonos",
        "name": "Hormonologie Sanguine",
        "examins": [
            {
                "id": "tsh",
                "name": "T.S.H. Ultra sensible",
                "min": 0.35,
                "max": 4.94
            }
        ]
    }
]



