import { ArticleInterface, CarnetInterface, JournalInterface } from '../useApp'
import Http from './http/carnets_journals_articles'
import { Carnet$ } from './_carnets'
import Idb from "../__api_v2/_Idb"

export default class ApiArticles {
    private _http!: Http
    private _idb!: Idb<'carnets'>
    constructor() {
        this._http = new Http()
        this._idb = new Idb('carnets')
    }

    post = async (journalId: JournalInterface['id']): Promise<void> => this._http.post(journalId).then((resp) => {
        if (resp.status === 200) {
            resp.json().then((json: ArticleInterface) => {
                const c = { ...(Carnet$.getValue() as CarnetInterface) }
                c.Journals = c.Journals.map(j => j.id === journalId ? ({ ...j, Articles: [...j.Articles, json] }) : j)
                this._idb.post(c)
                Carnet$.next(c)
            })
        }
    })

    put = async (article: ArticleInterface): Promise<Response> => this._http.put(article.id, { value: article.value })

    delete = (articleId: ArticleInterface['id']): void => {
        const newCarnet = { ...(Carnet$.getValue() as CarnetInterface) }
        newCarnet.Journals = newCarnet.Journals.map(j => ({ ...j, Articles: j.Articles.filter(a => a.id !== articleId) }))
        Carnet$.next(newCarnet)
        this._idb.post(newCarnet)
        this._http.delete(articleId)
    }

}