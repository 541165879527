import { CloudDownload } from "@mui/icons-material"
import { Box, FormControl, IconButton, Stack, Typography } from "@mui/material"
import React from "react"
import AppContext from "../../AppContext"
import { FileInterface } from "../../useApp"
import DeleteButtonConfirmation from "../DeleteButtonConfirmation"

const pdfFile = require('../../assets/file_pdf.png')

interface VueFileProps {
    file: FileInterface
}

const VueFile = ({ file }: VueFileProps) => {
    const { api } = React.useContext(AppContext)
    const imagePath = file.mimeType.includes('image/') ? `/download/${file.id}` : pdfFile

    const downloadFile = () => {
        const el = document.createElement("a");
        el.href = `/download/${file.id}`;
        el.download = file.name;
        el.click();
    }
    const deleteFile = () => api.sites.deleteFile(file.id)
    return (
        <FormControl fullWidth sx={{ mb: 1, mt: 1 }} className="hoverContainer">
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src={imagePath} width="50" alt={file.name} />
                <Stack sx={{ flexGrow: 1, ml: 1, mr: 1 }}>
                    <Typography>{file.name}</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <Typography variant="subtitle2" sx={{ mr: 1 }}>Type :</Typography>
                            <Typography variant="caption">{file.mimeType}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography variant="subtitle2" sx={{ mr: 1 }}>Taille :</Typography>
                            <Typography variant="caption">{file.size}</Typography>
                        </Box>
                    </Box>
                </Stack>
                <Box>
                    <IconButton color="primary" className="hoverTarget" onClick={downloadFile}>
                        <CloudDownload />
                    </IconButton>
                    <DeleteButtonConfirmation onDelete={deleteFile} rounded />
                </Box>
            </Box>
        </FormControl>
    )
}

export default VueFile