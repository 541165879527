import { createTheme, ThemeOptions } from "@mui/material";

const definition: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#136598',
        },
        secondary: {
            main: '#4bf7e2',
        },
        info: {
            main: '#a12ffd',
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: '#136598',
                    color: '#fff',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    boxShadow: 'rgb(0 0 0 / 12%) 1px 0 10px 0'

                }
            }
        },
        MuiCard: {
            defaultProps: {
                sx: {
                    height: '100%'
                }
            }
        },
    },
};

const lightTheme = createTheme(definition)


export default lightTheme