import { EngraisInterface } from "../_engrais";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()
    private _baseUrl = `/api/jardin/engrais`

    getAll = (): Promise<Response> => this._fetch.get(`${this._baseUrl}`)
    get = (id: EngraisInterface['id']): Promise<Response> => this._fetch.get(`${this._baseUrl}/${id}`)
    put = (id: EngraisInterface['id'], data: Partial<EngraisInterface>): Promise<Response> => this._fetch.put(`${this._baseUrl}/${id}`, data)
    post = (): Promise<Response> => this._fetch.post(`${this._baseUrl}`)

    uploadImage = (id: EngraisInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`${this._baseUrl}/${id}/image`, form)
    removeImage = (id: EngraisInterface['id']) => this._fetch.delete(`${this._baseUrl}/${id}/image`)


    delete = (id: EngraisInterface['id']): Promise<Response> => this._fetch.delete(`${this._baseUrl}/${id}`)
}