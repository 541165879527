import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material"
import React from "react"
import AppContext from "../../AppContext"
import { ListItemInterface, SiteInterface, UserDbInterface } from "../../useApp"
import UserSelector from "./UserSelector"

interface AddContentProps {
    siteId: SiteInterface['id']
    open: boolean,
    toggleOpen: () => void
    selectedUser: UserDbInterface['id']
}

const AddContent = ({ siteId, open, toggleOpen, selectedUser }: AddContentProps) => {
    const { api, lists } = React.useContext(AppContext)

    const [typeId, setType] = React.useState<ListItemInterface['id']>()
    const [value, setValue] = React.useState<ListItemInterface['value']>('')
    const [ownerId, setOwnerId] = React.useState<UserDbInterface['id']>(selectedUser)

    const handleSelectedUserChange = (id: UserDbInterface['id']) => setOwnerId(id)

    const create = () => {
        api.sites.contents.post(siteId, { typeId, value, SiteId: siteId, ownerId }).then(() => toggleOpen())

    }

    React.useEffect(() => {
        setType(undefined)
        setValue("")
        setOwnerId(selectedUser)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <Dialog open={open} onClose={toggleOpen} keepMounted={false}>
            <DialogTitle>Ajouter un item</DialogTitle>
            <DialogContent>
                <UserSelector
                    selectedUser={ownerId}
                    setSelectedUser={handleSelectedUserChange}
                />
                <TextField
                    sx={{ mt: 2 }}
                    select
                    defaultValue=""
                    label="Type d'item"
                    onChange={(event: any) => setType(event.target.value)}
                    fullWidth
                >
                    {lists.mdpType.map((option) => (
                        <MenuItem key={option.id} value={option.id.toString()}>
                            {option.value}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Valeur"
                    fullWidth
                    variant="outlined"
                    value={value}
                    onChange={(event: any) => setValue(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={create} disabled={!typeId || !value}>Créer</Button>
                <Button onClick={toggleOpen}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddContent