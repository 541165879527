import { Grid, List, ListSubheader } from "@mui/material"
import React from "react"
import { LegumeInterface } from "../../../_api/_legumes"
import ListAmisEnnemis from "../ListAmisEnnemis"
import TextHelper from "./TextHelper"

interface VueAmiEnnemisProps {
    legume: LegumeInterface
}
const VueAmiEnnemis = ({ legume }: VueAmiEnnemisProps) => {
    return (
        <>
            {legume.protection && <TextHelper value={legume.protection} divider />}
            <Grid container spacing={2}>
                {legume.legumesAmis && legume.legumesAmis.length > 0
                    ? <Grid item xs={12} sm={6} md={4}>
                        <List
                            subheader={<ListSubheader>Légumes amis</ListSubheader>}
                        >
                            {legume.legumesAmis.sort((a, b) => a.name < b.name ? -1 : 1).map(l =>
                                <ListAmisEnnemis legume={l} key={l.id} />)}
                        </List>

                    </Grid>
                    : ''}
                {legume.legumesEnnemis && legume.legumesEnnemis.length > 0
                    ? <Grid item xs={12} sm={6} md={4}>
                        <List
                            subheader={<ListSubheader>Légumes ennemis</ListSubheader>}
                        >
                            {legume.legumesEnnemis.sort((a, b) => a.name < b.name ? -1 : 1).map(l =>
                                <ListAmisEnnemis legume={l} key={l.id} />)}
                        </List>

                    </Grid>
                    : ''}
                {legume.fleursAmies && legume.fleursAmies.length > 0
                    ? <Grid item xs={12} sm={6} md={4}>
                        <List
                            subheader={<ListSubheader>Fleurs amies</ListSubheader>}
                        >
                            {legume.fleursAmies.sort((a, b) => a.name < b.name ? -1 : 1).map(l =>
                                <ListAmisEnnemis legume={l} key={l.id} fleur />)}
                        </List>

                    </Grid>
                    : ''}
            </Grid>

        </>
    )
}


export default VueAmiEnnemis