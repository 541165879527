import { tokensInterface } from "../../useApp";
import { authTokens$, refreshTokens } from "../../_rxjs/auth";

// eslint-disable-next-line 
let localToken: tokensInterface | null = null
authTokens$.subscribe(token => localToken = token)

class fetchUtil {
    private _accessToken!: string;
    private _refreshToken!: string;

    constructor() {
        authTokens$.subscribe(tokens => {
            if (tokens) {
                this._accessToken = tokens.accessToken
                this._refreshToken = tokens.refreshToken
            } else {
                this._accessToken = ''
                this._refreshToken = ''
            }
        })
    }

    get = async (url: string, refresh: boolean = true): Promise<any> => fetch(url, {
        method: 'GET',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this._accessToken} `
        }
    }).then((resp: any) => resp.status === 401 && refresh
        ? refreshTokens().then(success => success ? this.get(url, false) : null)
        : resp
    )

    post = async (url: string, refresh: boolean = true, data: any = null): Promise<any> => fetch(url, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this._accessToken}`
        },
        body: data ? JSON.stringify(data) : undefined
    })
        .then(resp => resp.status === 401 && refresh
            ? refreshTokens().then(success => success ? this.post(url, false, data) : null)
            : resp
        )

    put = async (url: string, data: {}, refresh: boolean = true): Promise<any> => fetch(url, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this._accessToken}`
        },
        body: JSON.stringify(data)
    }).then(resp => resp.status === 401 && refresh
        ? refreshTokens().then(success => success ? this.put(url, data, false) : null)
        : resp)

    patch = async (url: string, data: {}, refresh: boolean = true): Promise<any> => fetch(url, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this._accessToken}`
        },
        body: JSON.stringify(data)
    }).then(resp => resp.status === 401 && refresh
        ? refreshTokens().then(success => success ? this.patch(url, data, false) : null)
        : resp)

    delete = async (url: string, refresh: boolean = true): Promise<any> => fetch(url, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'authorization': `Bearer ${this._accessToken}`
        },
    })
        .then(resp => resp.status === 401 && refresh
            ? refreshTokens().then(success => success ? this.delete(url, false) : null)
            : resp)

    upload = async (url: string, formData: FormData, refresh: boolean = true): Promise<any> => fetch(url, {
        method: 'POST',
        headers: {
            'authorization': `Bearer ${this._accessToken}`
        },
        body: formData
    })
        .then(resp => resp.status === 401 && refresh
            ? refreshTokens().then(success => success ? this.upload(url, formData, false) : null)
            : resp)

}

export default fetchUtil