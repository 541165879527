import { Card, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import ExcelIcon from "App/assets/ExcelIcon";
import { ConstanteInterface } from "App/_api/_constantes";
import ToExcel from "../ToExcel";
import GroupRow from "./GroupRow";

export const PAm = (systole: number, diastole: number) => Number(systole) && Number(diastole) ? Math.round((Number(systole) + 2 * Number(diastole)) / 3) : null
export const avr = (values: number[]) => {
    let ret: number[] = [];
    values.forEach(v => {
        if (v) {
            ret.push(v);
        }
    })
    let sum = () => {
        let s = 0;
        ret.forEach(r => s += Number(r))
        return s
    }
    if (ret.length === 0) return null
    return Math.round(sum() / (ret.length))
}

export type Row = {
    date: string;
    constantes: ConstanteInterface[]
}

interface DataTableProps {
    year: number,
    dataQuerying: ConstanteInterface[],
    selected: ConstanteInterface['id'][], setSelected: Dispatch<SetStateAction<ConstanteInterface['id'][]>>,
    deleteConstante: (id: ConstanteInterface['id']) => Promise<void>
}
const DataTable = ({
    dataQuerying,
    year,
    selected, setSelected,
    deleteConstante
}: DataTableProps) => {

    const data = () => {
        let ret: Row[] = []

        dataQuerying.sort((a, b) => a.mesureAt < b.mesureAt ? -1 : 1).forEach((d) => {
            let localDate = new Date(new Date(d.mesureAt).getFullYear(), new Date(d.mesureAt).getMonth(), new Date(d.mesureAt).getDate()).toString()
            if (!ret.find(r => r.date === localDate)) ret.push({ date: localDate, constantes: [] })
            ret.find(r => r.date === localDate)?.constantes.push(d)
        })


        return ret
    }

    return (
        <Card sx={{ width: '100%', mb: 2, height: '100%' }}>
            <TableContainer sx={{ height: '100%' }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" rowSpan={2}>
                                <Checkbox
                                    checked={selected.length === dataQuerying.length}
                                    indeterminate={selected.length > 0 && selected.length < dataQuerying.length}
                                    onChange={() => setSelected(selected.length === 0 ? dataQuerying.map(c => c.id) : [])}
                                />
                            </TableCell>
                            <TableCell rowSpan={2} size="small" />
                            <TableCell rowSpan={2} align="center">
                                Date
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell colSpan={3} align="center">
                                Gauche
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell colSpan={3} align="center">
                                Droite
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell rowSpan={2} align="center">
                                Masse
                            </TableCell>
                            <TableCell rowSpan={2} align="center">
                                Temp
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell colSpan={2} align="center">
                                Ecarts
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell colSpan={2} align="center">
                                Moyenne
                            </TableCell>
                            <TableCell rowSpan={2} />
                            <TableCell rowSpan={2} sx={{ width: 0 }}>
                                <IconButton
                                    color={"success"}
                                    onClick={() => year && dataQuerying && (new ToExcel(dataQuerying.filter(d => selected.includes(d.id)))).get()}
                                    disabled={selected.length === 0}
                                >
                                    <ExcelIcon disabled={selected.length === 0} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                        <TableRow >
                            <TableCell sx={{ top: 57 }} align="center">
                                Systole
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Diastole
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Pouls
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Systole
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Diastole
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Pouls
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Gauche
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Droite
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Gauche
                            </TableCell>
                            <TableCell sx={{ top: 57 }} align="center">
                                Droite
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data().map(row =>
                            <GroupRow
                                key={row.date}
                                row={row}
                                selected={selected} setSelected={setSelected}
                                deleteConstante={deleteConstante}
                                defaultOpen={true}
                            />)}
                    </TableBody>
                </Table>

            </TableContainer>
        </Card>
    )
}

export default DataTable