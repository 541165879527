import { Grid, Card, CardMedia, CardActionArea, Box, CardContent, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ChimiqueInterface } from "../../_api/_chimiques";
import { EngraisInterface } from "../../_api/_engrais";
import { FleurInterface } from "../../_api/_fleurs";
import { LegumeInterface } from "../../_api/_legumes";
import { PurinInterface } from "../../_api/_purins";

export interface ListItemCardProps {
    produit: LegumeInterface | FleurInterface | EngraisInterface | PurinInterface | ChimiqueInterface
    type: 'legumes' | 'fleurs' | 'engrais' | 'purins' | 'produits-chimiques'
}
const ListItemCard = ({ produit, type }: ListItemCardProps) => {
    return (
        <Grid item xs={12} md={6} lg={4} xl={3}>
            <Card sx={{ display: { xs: 'flex', md: 'inline-box' }, height: 100 }}>
                {produit.imageId && <CardMedia
                    component="img"
                    sx={{ width: 100, height: 100 }}
                    image={`/download/${produit.imageId}`}
                />}
                <CardActionArea sx={{ display: 'flex', flexGrow: 1 }} component={Link} to={`/jardin/${type}/${produit.id}`}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                        <CardContent sx={{ flex: '1 0 auto' }}>
                            <Typography variant="h5">{produit.name}</Typography>
                            {(type === 'legumes' || type === 'fleurs') && (produit as LegumeInterface).variety && <Typography variant="h6" color="text.secondary">{(produit as LegumeInterface).variety}</Typography>}
                        </CardContent>
                    </Box>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default ListItemCard