import React from "react";
import { useAuth } from "./_rxjs/auth";

import Api from './_api/Api'
import { useLists } from "./_api/_lists";
import { Breakpoint, useMediaQuery } from "@mui/material";
import { useWidth } from "./_hooks/useWidth";

type useAppType = [
    API: Api,
    lists: ListsInterface,
    ui: UiInterface,
    user: undefined | UserInterface | null,
]

export interface ListsInterface {
    ei: ListItemInterface[],
    mdpType: ListItemInterface[],
    siteType: ListItemInterface[],
    jardin: {
        legumeType: ListItemInterface[],
        exigences: ListItemInterface[],
    };
    full: ListItemInterface[];
}

export interface ListItemInterface {
    id: string,
    type: ListItemType
    value: string
    secondary: string
}

export type ListItemType = 'ei' | 'mdpType' | 'siteType' | 'jarExi' | 'jarLegType' | 'jarSemType' | 'jarTask' | 'jarSaison' | 'jarFleurType' | 'jarParasite' | 'jarEngType' | 'jarEngUsage' | 'jarEngMoment' | 'jarEngSol' | 'cuisineUnit'


export interface CarnetInterface {
    id: string;
    title: string;
    createdAt: string;
    updatedAt: string;
    Journals: JournalInterface[]
}

export interface JournalInterface {
    id: string,
    title: string
    createdAt: string
    updatedAt: string
    Articles: ArticleInterface[]
    eis: ListItemInterface[]
}

export interface ArticleInterface {
    id: string,
    value: string
    createdAt: string
    updatedAt: string
}

export interface UiInterface {
    size: Breakpoint;
    drawer: {
        width: number | '100%',
        open: boolean,
        toggle: () => void
        expanded: string[]
        setExpanded: (value: string) => void
    },
}

export interface UserDbInterface {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
    imageId: string;
}

export interface UserInterface {
    accessToken: string;
    refreshToken: string;
    firstName: string;
    lastName: string;
    email: string;
    imageId: string;
    iat: number;
    exp: number;
}

export interface tokensInterface {
    accessToken: string;
    refreshToken: string
}

export interface SiteInterface {
    id: string;
    name: string;
    url: string;
    urlConnect: string;
    contents: SiteContentInterface[];
    categories: ListItemInterface[];
    imageId: string;
    files: FileInterface[]
}
export interface SiteContentInterface {
    id: number;
    value: string;
    createdAt: string;
    updatedAt: string;
    SiteId: SiteInterface['id'];
    typeId: ListItemInterface['id'];
    type: ListItemInterface;
    ownerId: string;
    owner: UserDbInterface;
    disable: boolean;
}

export interface FileInterface {
    id: string;
    mimeType: string;
    name: string;
    size: number;
}

const useApp = (): useAppType => {
    const querySm = '(max-width:600px)'
    // const size = useMediaQuery(querySm) ? 'sm' : useMediaQuery('(max-width:900px)') ? 'md' : useMediaQuery('(max-width:1200px)') ? 'lg' : 'xl'
    // const size = () => {
    //     const w = window.innerWidth;
    //     if (w < 600) return 'sm'
    //     if (w < 900) return 'md'
    //     if (w < 120000) return 'lg'
    //     return 'xl'

    // }
    const size = useWidth()
    const [tokens, user] = useAuth()
    const [UiDrawerOpen, setUiDrawerOpen] = React.useState<boolean>(false)
    const [UiDrawerExpanded, setUiDrawerExpanded] = React.useState<string[]>([])
    const lists = useLists()
    const API = new Api()
    const ui: UiInterface = {
        size: size,
        drawer: {
            width: useMediaQuery(querySm) ? '100%' : 250,
            open: UiDrawerOpen,
            toggle: () => setUiDrawerOpen(!UiDrawerOpen),
            expanded: UiDrawerExpanded,
            setExpanded: (value) => setUiDrawerExpanded(UiDrawerExpanded.includes(value) ? UiDrawerExpanded.filter(str => str !== value) : [...UiDrawerExpanded, value])
        },
    }

    React.useEffect(() => {
        if (tokens) {
            API.lists.getAll()
            API.users.getAll()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokens])

    return [
        API,
        lists,
        ui,
        user
    ]
}

export default useApp;