import React from "react"
import { AccessTime, Fireplace, Star } from "@mui/icons-material"
import { Box, Container, Grid, Rating, Typography } from "@mui/material"
import { CuisineInterface } from "../../../_api/_cuisines"
import { DynamicBreadCrumbs } from "../../Page/PageTitle"

const CuisineImage = require('../../../assets/cuisine.avif')

interface VoirHeaderProps {
    data: CuisineInterface
}
const VoirHeader = ({ data }: VoirHeaderProps) => {
    const textShadow = '1px 1px 2px black'
    return (
        <Box sx={{
            backgroundImage: `url('${data.imageId ? `/download/${data.imageId}` : CuisineImage}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
            backgroundColor: 'black',
            mt: -16,
            ml: -4,
            mr: -4,
            pt: 20, pl: 4, pr: 8,
            color: 'white',
            textShadow,
            boxShadow: `0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
            zIndex: 10
        }}>
            <Container maxWidth="md">
                <Box sx={{ pl: 2, pr: 2, pt: 8, pb: 8 }} textAlign={'center'}>
                    <Typography variant="h2" sx={{ textShadow, mb: 4 }}>{data.name}</Typography>
                    <Typography variant="h5" sx={{ textShadow }}>{data.description}</Typography>
                </Box>
                {data.facilite && <Box textAlign={'center'}>
                    <Rating value={+data.facilite} readOnly max={+data.facilite} icon={<Star sx={{ color: undefined, stroke: data.imageId ? 'black' : undefined, }} />} />
                </Box>}
                <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'space-around' }}>
                    {data.preparationTime &&
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                            <AccessTime fontSize="large" />
                            <Typography variant="h5">
                                {data.preparationTime} minutes
                            </Typography>
                        </Grid>}
                    {data.cookingTime &&
                        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
                            <Fireplace fontSize="large" />
                            <Typography variant="h5">
                                {data.cookingTime} minutes
                            </Typography>
                        </Grid>}
                </Grid>

                <Box sx={{ mt: 3, pb: 2, textShadow: 'none' }}>
                    <DynamicBreadCrumbs idReplacer={data.name} />
                </Box>
            </Container>
        </Box>
    )
}

export default VoirHeader