import React from "react"
import LandingPage from "../LandingPage"


const recetteImage = require('../../assets/cuisine.avif')
const ingredientImage = require('../../assets/ingredients.jpg')

const LandingCuisine = () =>
    <LandingPage
        title="Cuisine"
        tuiles={[
            {
                url: '/cuisine/ingredients',
                img: ingredientImage,
                primary: 'Ingrédients'
            },
            {
                url: '/cuisine/recettes',
                img: recetteImage,
                primary: 'Recettes'
            }
        ]}
    />

export default LandingCuisine