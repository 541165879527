import React from "react";
import AppContext from "../../../AppContext";
import { FleurInterface, Fleurs$ } from "../../../_api/_fleurs";
import CommonList from "../CommonList";

const Image = require('../../../assets/fleurs.jpg')

const ListFleurs = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<FleurInterface[]>();
    React.useEffect(() => {
        Fleurs$.subscribe(l => setData(l))
        api.jardin.fleurs.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CommonList
            data={data}
            title={"Fleurs"}
            image={Image}
            apiCall={api.jardin.fleurs}
            type={'fleurs'}
        />
    )
}

export default ListFleurs