import React from "react"
import LandingPage from "./LandingPage"

const HealthImage = require('../assets/health.jpeg')
const JardinImage = require('../assets/jardin.jpeg')
const PasswordImage = require('../assets/password.jpeg')
const CuisineImage = require('../assets/cuisine.jpeg')

const MainLandingPage = () => <LandingPage
    title={"Famille Lucas"}
    tuiles={[
        {
            url: '/jardin',
            img: JardinImage,
            primary: 'Jardin',
            buttons: [
                { primary: 'Engrais', url: '/jardin/engrais' },
                { primary: 'Fleurs', url: '/jardin/fleurs' },
                { primary: 'Legumes', url: '/jardin/legumes' },
                { primary: 'Produits chimiques', url: '/jardin/produits-chimiques' },
                { primary: 'Purins', url: '/jardin/purins' },
            ]
        },
        {
            url: '/cuisine',
            img: CuisineImage,
            primary: 'Recettes de cuisine',
            buttons: [
                { primary: 'Ingrédients', url: '/cuisine/ingredients' },
                { primary: 'Recettes', url: '/cuisine/recettes' }
            ]
        },
        {
            url: '/sante',
            img: HealthImage,
            primary: 'Santé',
            secondary: "Carnet de santé",
            buttons: [
                { primary: 'Constantes', url: '/sante/constantes' },
                { primary: 'Carnets de bord', url: '/sante/carnets-de-bord' },
                // { primary: 'Labos', url: '/sante/labos' }
            ]
        },
        {
            url: '/sites',
            img: PasswordImage,
            primary: 'Mots de passe',
            buttons: [

            ]
        }
    ]}
/>

export default MainLandingPage