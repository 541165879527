import React from "react";
import AppContext from "../../../AppContext";
import { PurinInterface, Purins$ } from "../../../_api/_purins";
import CommonList from "../CommonList";

const Image = require('../../../assets/purins.jpg')

const ListPurins = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<PurinInterface[]>();

    React.useEffect(() => {
        Purins$.subscribe(l => setData(l))
        api.jardin.purins.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CommonList
            data={data}
            title={"Purins"}
            image={Image}
            apiCall={api.jardin.purins}
            type={'purins'}
        />
    )
}

export default ListPurins