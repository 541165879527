import { Grid, LinearProgress, Box, Card, CardActionArea, CardContent, Typography, CardMedia, Avatar, Chip } from "@mui/material"
import React from "react"
import { useNavigate, Link } from "react-router-dom"
import { map } from "rxjs"
import AppContext from "../../AppContext"
import ThemeContext from "../../themes/ThemeContext"
import { SiteInterface, UserDbInterface } from "../../useApp"
import { Sites$ } from "../../_api/_sites"
import { Users$ } from "../../_api/_users"
import Page from "../Page/Page"


const SitesLanding = () => {
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<SiteInterface[]>()
    const [search, setSearch] = React.useState<string>('')
    const [sortOrder, setSortOrder] = React.useState<'asc' | 'desc'>('asc')
    const [users, setUsers] = React.useState<UserDbInterface[]>()

    const sortNumber = sortOrder === 'asc' ? -1 : 1

    const handleAdd = () => api.sites.post().then((id: string) => id && navigate(`/sites/${id}`))

    React.useEffect(() => {
        Sites$
            .pipe(map((d: SiteInterface[]) => d.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)))
            .subscribe(data => data && setData(data))
        Users$.subscribe(u => u && setUsers(u))
        api.sites.getAll()
        api.users.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page
            title={{
                title: "Mots de passe",
            }}
            searchable={{
                sortOrder: sortOrder,
                changeSortOrder: setSortOrder,
                search: search,
                setSearch: setSearch,
                searchPlaceholder: "Rechercher un site",
                add: handleAdd,

            }}
            loading={!data}
        >

            {!data
                ? <LinearProgress />
                : <Box>
                    <Grid container spacing={2} alignItems={"stretch"}>
                        {data
                            .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? sortNumber : -sortNumber)
                            .filter(f => search === ''
                                ? true
                                : f.name.toLowerCase().includes(search.toLowerCase()) ||
                                f.categories.filter(f => f.value.toLowerCase().includes(search.toLowerCase())).length > 0
                            ).map((site) => <Site key={site.id} site={site} users={users} />)}
                    </Grid>
                </Box>}


        </Page >
    )
}

interface SiteProps {
    site: SiteInterface
    users?: UserDbInterface[]
}

const Site = ({ site, users }: SiteProps) => {
    const { name: themeName } = React.useContext(ThemeContext)
    const localUsers: UserDbInterface[] = site.contents.map(c => c.owner)
        .filter((u, i, s) => s.map(ss => ss.id).indexOf(u.id) === i)
        .sort((a, b) => `${a.lastname} ${a.firstname}`.toLocaleLowerCase() < `${b.lastname} ${b.firstname}`.toLocaleLowerCase() ? -1 : 1)
    return (
        <Grid item xs={12} md={6} lg={4} xl={3} >
            <Card sx={{ height: '100%', backgroundColor: themeName === 'dark' ? 'rgba(18,18,18, .5)' : undefined }}>
                <CardActionArea sx={{ height: '100%' }} component={Link} to={`/sites/${site.id}`}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            {site.name}
                        </Typography>
                    </CardContent>
                    <CardMedia
                        component="img"
                        image={`/download/${site.imageId}`}
                        sx={{ height: 150, objectFit: 'contain', visibility: site.imageId ? 'visible' : 'hidden' }}
                    />
                    <CardContent>
                        <Box sx={{ display: 'flex', gap: '.25em' }}>
                            {localUsers.map(u => <Avatar
                                key={u.id}
                                alt={`${u.firstname} ${u.lastname}`}
                                title={`${u.firstname} ${u.lastname}`}
                                src={u.imageId ? `/download/${u.imageId}` : undefined}
                            >
                                {u.firstname.split('')[0]}
                                {u.lastname.split('')[0]}
                            </Avatar>)}
                        </Box>

                        <Box sx={{ display: 'flex', mt: 2, gap: 1 }}>
                            {site.categories.map((cat, index) => <Chip key={cat.id} label={`${cat.value}`} size="small" />)}
                        </Box>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Grid>
    )
}

export default SitesLanding