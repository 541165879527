import { ChimiqueInterface } from "../_chimiques";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()
    private _baseUrl = `/api/jardin/chimiques`

    getAll = (): Promise<Response> => this._fetch.get(`${this._baseUrl}`)
    get = (id: ChimiqueInterface['id']): Promise<Response> => this._fetch.get(`${this._baseUrl}/${id}`)
    put = (id: ChimiqueInterface['id'], data: Partial<ChimiqueInterface>): Promise<Response> => this._fetch.put(`${this._baseUrl}/${id}`, data)
    post = (): Promise<Response> => this._fetch.post(`${this._baseUrl}`)

    uploadImage = (id: ChimiqueInterface['id'], form: FormData): Promise<Response> => this._fetch.upload(`${this._baseUrl}/${id}/image`, form)
    removeImage = (id: ChimiqueInterface['id']) => this._fetch.delete(`${this._baseUrl}/${id}/image`)


    delete = (id: ChimiqueInterface['id']): Promise<Response> => this._fetch.delete(`${this._baseUrl}/${id}`)

}