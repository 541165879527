import React from "react";
import { Avatar, Box, Breadcrumbs, Chip, Divider, emphasize, IconButton, LinearProgress, ListItemIcon, ListItemText, Menu, MenuItem, styled, SvgIcon, Typography } from "@mui/material";
import { Agriculture, Blender, Cookie, Edit, EnergySavingsLeaf, ExpandMore, Fireplace, Healing, HealthAndSafety, Home, ImportContacts, LocalFlorist, LockPerson, Science } from '@mui/icons-material'
import { Link, useLocation, useParams } from 'react-router-dom'

export interface PageTitleProps {
    title: string;
    subtitle?: string
    toggleEdit?: () => void
    imageUrl?: string
    loading?: boolean
    noDivider?: boolean
}

const PageTitle = ({ title, subtitle, toggleEdit, imageUrl, loading, noDivider }: PageTitleProps) => {
    const avatarSize = subtitle ? 100 : 80
    return (
        <Box sx={{ pb: 0 }} className="hoverContainer">
            <Box sx={{ display: 'flex' }}>
                {imageUrl && <Avatar
                    src={imageUrl}
                    sx={{ width: avatarSize, height: avatarSize, mr: 3 }}
                />}
                <Box sx={{ flexGrow: 1 }}>

                    <Typography variant="h4" gutterBottom={!subtitle}>
                        {title}
                        {toggleEdit && <IconButton className="hoverTarget" sx={{ ml: 2 }} onClick={toggleEdit}>
                            <Edit />
                        </IconButton>}
                    </Typography>
                    {subtitle
                        ? subtitle.includes("\n")
                            ? subtitle.split("\n").map((sub, index) => <Typography key={index} variant="subtitle1" gutterBottom>
                                {sub}
                            </Typography>)
                            : <Typography variant="subtitle1" gutterBottom>
                                {subtitle}
                            </Typography>
                        : <></>}
                    <DynamicBreadCrumbs idReplacer={title} />
                </Box>
            </Box>
            {loading
                ? <LinearProgress />
                : (!noDivider && <Divider />) || ''}
        </Box>
    )
}

export default PageTitle

interface DynamicBreadCrumbsProps {
    idReplacer?: string
}
type Route = { title: string, icon: JSX.Element, url: string, routes?: Route[] }
export const DynamicBreadCrumbs = ({ idReplacer }: DynamicBreadCrumbsProps) => {
    const location = useLocation()
    const params = useParams();

    const path = ['', ...location.pathname.split('/').filter(p => p !== '')]

    const Routes: Route[] = [
        {
            title: 'Accueil', icon: <Home fontSize="small" />, url: '',
            routes: [
                {
                    title: 'Cuisine', icon: <Cookie fontSize="small" />, url: 'cuisine', routes: [
                        {
                            title: 'Recettes', icon: <Cookie fontSize="small" />, url: 'recettes', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                },
                            ]
                        },
                        {
                            title: 'Ingrédients', icon: <Blender />, url: 'ingredients', routes: [
                                { title: idReplacer || 'Edition', icon: <Edit fontSize="small" />, url: ':id' },
                            ]
                        }
                    ]
                },
                {
                    title: 'Jardin potager', icon: <Agriculture fontSize="small" />, url: 'jardin', routes: [
                        {
                            title: 'Engrais', icon: <SvgIcon fontSize="small">
                                <path fill="currentColor" d="M18.92 14C18.64 15.97 17.53 17.68 15.96 18.76C15.76 17.93 14.53 17.25 12.92 17.06C13.09 15.87 13.44 14.76 13.68 14.12C14.06 14.36 14.5 14.5 15 14.5C18.55 14.5 18 8.5 18 8.5S17.5 9.5 16 9.5H15C13.62 9.5 12.5 10.62 12.5 12C12.5 12.5 12.64 12.93 12.87 13.31C13.53 12.62 14.24 12 15 11.5C13.68 12.83 12.67 14.5 12 16.1C11.37 14.87 10.58 13.93 10 13.5C10.47 13.7 10.91 14 11.33 14.3C11.44 14.06 11.5 13.79 11.5 13.5C11.45 10.77 7 11 7 11C7.31 11.42 7.5 11.95 7.5 12.5V13.5C7.5 14.6 8.4 15.5 9.5 15.5C10 15.5 10.45 15.32 10.8 15C11.03 15.63 11.2 16.35 11.29 17.04C9.57 17.19 8.25 17.89 8.04 18.77C6.21 17.5 5 15.39 5 13C5 9.14 8.14 6 12 6V9L17 5L12 1V4C7.03 4 3 8.03 3 13S7.03 22 12 22C16.63 22 20.44 18.5 20.94 14H18.92Z" />
                            </SvgIcon>, url: 'engrais', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                }
                            ]
                        },
                        {
                            title: 'Fleurs', icon: <LocalFlorist fontSize="small" />, url: 'fleurs', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                }
                            ]
                        },
                        {
                            title: 'Legumes', icon: <SvgIcon fontSize="small"><path fill="currentColor" d="M16,10L15.8,11H13.5A0.5,0.5 0 0,0 13,11.5A0.5,0.5 0 0,0 13.5,12H15.6L14.6,17H12.5A0.5,0.5 0 0,0 12,17.5A0.5,0.5 0 0,0 12.5,18H14.4L14,20A2,2 0 0,1 12,22A2,2 0 0,1 10,20L9,15H10.5A0.5,0.5 0 0,0 11,14.5A0.5,0.5 0 0,0 10.5,14H8.8L8,10C8,8.8 8.93,7.77 10.29,7.29L8.9,5.28C8.59,4.82 8.7,4.2 9.16,3.89C9.61,3.57 10.23,3.69 10.55,4.14L11,4.8V3A1,1 0 0,1 12,2A1,1 0 0,1 13,3V5.28L14.5,3.54C14.83,3.12 15.47,3.07 15.89,3.43C16.31,3.78 16.36,4.41 16,4.84L13.87,7.35C15.14,7.85 16,8.85 16,10Z" /></SvgIcon>, url: 'legumes', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                }
                            ]
                        },
                        {
                            title: 'Produits chimiques', icon: <Fireplace fontSize="small" />, url: 'produits-chimiques', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                }
                            ]
                        },
                        {
                            title: 'Purins', icon: <EnergySavingsLeaf fontSize="small" />, url: 'purins', routes: [
                                {
                                    title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id', routes: [
                                        { title: 'Edition', icon: <Edit fontSize="small" />, url: 'edit' },
                                    ]
                                }
                            ]
                        },

                    ]
                },
                {
                    title: 'Mots de passe', icon: <LockPerson fontSize="small" />, url: 'sites', routes: [
                        { title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id' }
                    ]
                },
                {
                    title: 'Santé', icon: <Healing fontSize="small" />, url: 'sante', routes: [
                        {
                            title: 'Constantes', icon: <HealthAndSafety fontSize="small" />, url: 'constantes', routes: [
                                { title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id' }
                            ]
                        },
                        {
                            title: 'Carnets de bord', icon: <ImportContacts fontSize="small" />, url: 'carnets-de-bord', routes: [
                                { title: idReplacer || 'Elément', icon: <></>, url: params.id || ':id' }
                            ]
                        },
                        {
                            title: 'Biologie', icon: <Science fontSize="small" />, url: 'biologie'
                        }
                    ]
                },
            ],
        }
    ]

    const builBreadCrumbs = (path: string[], url: string, routes: Route[]): JSX.Element[] => {
        let returnArr: JSX.Element[] = []
        const localPath = [...path]
        const actualPath = localPath.splice(0, 1)[0]
        const actualRoute = routes.find(r => r.url === actualPath)

        if (actualRoute) {

            url = url + actualRoute.url
            const el = <BreadcrumbsItem
                key={url}
                url={`${url || '/'}`}
                route={actualRoute}
                routes={routes.length > 1 ? routes.filter(r => r.url !== actualRoute.url) : undefined}
            />

            returnArr.push(el)
            if (actualRoute.routes && actualRoute.routes.length > 0) {
                returnArr = [...returnArr, ...builBreadCrumbs(localPath, url + '/', actualRoute.routes)]
            }

        }

        return returnArr
    }

    const arrBr = builBreadCrumbs(path, '', Routes)




    return (
        <Breadcrumbs separator="›" sx={{ mb: 2 }}>
            {arrBr.map(e => e)}
        </Breadcrumbs>
    )

}

interface BreadcrumbsItemProps {
    url: string
    route: Route
    routes?: Route[]
}
const BreadcrumbsItem = ({ url, route, routes }: BreadcrumbsItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null)

    const handleClick = (event: any) => {
        event.preventDefault()
        event.stopPropagation()
        setAnchorEl(event.target)
    }

    const handleClose = () => setAnchorEl(null);

    return (<React.Fragment>
        <StyledBreadcrumb
            component={Link}
            to={url || '/'}
            label={route.title}
            icon={route.icon}
            deleteIcon={routes && routes.length > 0 ? <ExpandMore /> : undefined}
            onDelete={routes && routes.length > 0 ? handleClick : undefined}
        />
        {routes && <MenuBr routes={routes} url={url.replace(route.url, '')} handleClose={handleClose} anchorEl={anchorEl} />}
    </React.Fragment>)
}
interface MenuBrProps {
    anchorEl: HTMLInputElement | null;
    handleClose: () => void;
    routes: Route[];
    url: string;
}
const MenuBr = ({ routes, url, anchorEl, handleClose }: MenuBrProps) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            {routes.map(route =>
                <MenuItem key={route.url} component={Link} to={`${url}${route.url}`} dense>
                    <ListItemIcon>
                        {route.icon}
                    </ListItemIcon>
                    <ListItemText>{route.title}</ListItemText>
                </MenuItem>)}

        </Menu>
    )
}

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
}) as typeof Chip;