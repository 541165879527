import { Typography, Box, Grid, FormControlLabel, Checkbox, ToggleButtonGroup, ToggleButton, CardMedia, CardContent, Card, Avatar } from "@mui/material"
import { GridView, List } from "@mui/icons-material"
import React from "react"
import { CuisineIngredientInterface, CuisineInterface } from "../../../_api/_cuisines"
import NbPerson from "../NbPerson"

const ingredientDefault = require('../../../assets/ingredientDefault.webp')

interface VoirIngredientsProps {
    ingredients: CuisineIngredientInterface[]
    personne: CuisineInterface['personne']
}
const VoirIngredients = ({ ingredients, personne }: VoirIngredientsProps) => {
    const [nbPers, setNbPers] = React.useState<number>(personne)
    const [display, setDisplay] = React.useState<'grid' | 'list'>('grid')

    const handleChangeDisplay = (event: any, value: 'grid' | 'list') => value && setDisplay(value)
    return (
        <React.Fragment>
            <Typography variant="h4" gutterBottom>Ingrédients</Typography>
            <Grid container spacing={2} justifyContent="space-between" sx={{ mb: 2 }}>
                <Grid item>
                    <NbPerson nbPers={nbPers} handleChange={setNbPers} />
                </Grid>
                <Grid item>
                    <ToggleButtonGroup
                        value={display}
                        onChange={handleChangeDisplay}
                        exclusive>
                        <ToggleButton value="grid" size="large">
                            <GridView />
                        </ToggleButton>
                        <ToggleButton value="list" size="large">
                            <List />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Box sx={{ pb: 4, mb: 8 }}>
                <Grid container spacing={2}>
                    {ingredients.map(ingredient => <IngredientItem key={ingredient.id} ingredient={ingredient} personne={personne} nbPers={nbPers} display={display} />)}
                </Grid>
            </Box>
        </React.Fragment >
    )
}

interface IngredientItemProps {
    ingredient: CuisineIngredientInterface,
    personne: number,
    nbPers: number;
    display: 'grid' | 'list'
}
const IngredientItem = ({ ingredient, personne, nbPers, display }: IngredientItemProps) => {
    const round = (value: number, step: number) => {
        step || (step = 1.0);
        var inv = 1.0 / step;
        return Math.round(value * inv) / inv;
    }
    const name = ingredient.ingredient.name || ''
    const quantite = ingredient.value && ingredient.value > 0
        ? round(ingredient.value * (nbPers / personne), .25)
        : 0
    const unit = ingredient.value === -1 || (ingredient.unit && ingredient.unit.id === '06852faf-a884-42b0-8049-56675addc647') || (ingredient.unit && ingredient.unit.id === 'a550dfd5-fc4f-4659-a9ba-782e7b56e839')
        ? ''
        : ingredient.unit
            ? quantite > 1
                ? ingredient.unit.secondary
                    ? ingredient.unit.secondary
                    : ingredient.unit.value
                : ingredient.unit.value
            : ''

    return (
        display === 'grid'
            ? <Grid item key={ingredient.id} xs={6} md={3} lg={2}>
                <Card sx={{ height: '100%', pb: 0 }}>
                    <CardMedia component={'img'} image={ingredient.ingredient.imageId ? `/download/${ingredient.ingredient.imageId}` : ingredientDefault} height={160} sx={{ objectFit: 'contain' }} />
                    <CardContent sx={{ textAlign: 'center', mb: 0, pb: 0 }}>
                        <Typography variant="body1">
                            {`${name}${quantite !== 0 ? ' : ' : ''}${(quantite !== 0 && quantite) || ''} ${unit}`}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid >
            : <Grid item key={ingredient.id} xs={12} md={6} lg={4}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <FormControlLabel control={<Checkbox />} label="" sx={{ mr: 'unset' }} />
                    <Avatar src={ingredient.ingredient.imageId ? `/download/${ingredient.ingredient.imageId}` : undefined} sx={{ visibility: ingredient.ingredient.imageId ? 'visible' : 'hidden' }} />

                    <Typography variant="body1">
                        {`${name}${quantite !== 0 ? ' : ' : ''}${(quantite !== 0 && quantite) || ''} ${unit}`}
                        < Typography color="text.secondary" component="span">{ingredient.facultatif && ' (facultatif)'}</Typography>
                    </Typography>
                </Box>
            </Grid >
    )
}
export default VoirIngredients