import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { LegumeInterface } from "../../../_api/_legumes"
import TextHelper from "./TextHelper"

interface VueSemisProps {
    legume: LegumeInterface
}
const VueSemis = ({ legume }: VueSemisProps) => {
    return (
        <Box>
            {legume.condCulture && <TextHelper value={legume.condCulture} divider />}
            <Grid container spacing={2}>
                {legume.semisDate && ((legume.semisDate.du && legume.semisDate.du.m) || (legume.semisDate.au && legume.semisDate.au.m))
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Date</Typography>
                        {(legume.semisDate.du && legume.semisDate.du.m)
                            ? <Typography variant="body2" component="span">du {
                                new Intl.DateTimeFormat('fr-FR', { month: 'long', day: legume.semisDate.du.j ? 'numeric' : undefined }).format(new Date(
                                    new Date().getFullYear(),
                                    legume.semisDate.du.m,
                                    legume.semisDate.du.j || 1
                                ))
                            } </Typography>
                            : ''}
                        {(legume.semisDate.au && legume.semisDate.au.m)
                            ? <Typography variant="body2" component="span">au {
                                new Intl.DateTimeFormat('fr-FR', { month: 'long', day: legume.semisDate.au.j ? 'numeric' : undefined }).format(new Date(
                                    new Date().getFullYear(),
                                    legume.semisDate.au.m,
                                    legume.semisDate.au.j || 1
                                ))
                            }</Typography>
                            : ''}
                    </Grid>
                    : ''}
                {legume.semisSaisons && legume.semisSaisons.length > 0
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Saison</Typography>
                        <Typography variant="body2">{legume.semisSaisons.map((item, index) => item.value + (index + 1 < legume.semisSaisons.length ? ', ' : ''))}</Typography>
                    </Grid>
                    : ''}
                {legume.semisTypes && legume.semisTypes.length > 0
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Type de semis</Typography>
                        <Typography variant="body2">{legume.semisTypes.map((item, index) => item.value + (index + 1 < legume.semisTypes.length ? ', ' : ''))}</Typography>
                    </Grid>
                    : ''}
                {legume.semisTemp
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Température de germination</Typography>
                        <Typography variant="body2">{`${legume.semisTemp}°C`}</Typography>
                    </Grid>
                    : ''}
                {legume.semisProf
                    ? <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Profondeur de semis</Typography>
                        <Typography variant="body2">{`${legume.semisProf}mm`}</Typography>
                    </Grid>
                    : ''}
            </Grid>
        </Box>
    )
}

export default VueSemis