import React from "react";

import { Box, Button, Grid, IconButton, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { Add, Check, Close, ReportProblem } from '@mui/icons-material'

import { ArticleInterface, JournalInterface } from "../../../useApp";
import EiSelector from "./EiSelector";
import AppContext from "../../../AppContext";
import DeleteButtonConfirmation from "../../DeleteButtonConfirmation";

interface VueJournalEditProps {
    journal: JournalInterface
    toggleEdit: () => void
}

const VueJournalEdit = ({ journal, toggleEdit }: VueJournalEditProps) => {
    const { api } = React.useContext(AppContext)
    const [localJournal, setJournal] = React.useState<JournalInterface>(journal)
    const [isEiOpen, toggleEiOpen] = React.useReducer(a => !a, false)

    const addArticle = () => api.sante.carnets.journals.articles.post(journal.id)

    const changeTitle = (event: any) => setJournal({ ...localJournal, title: event.target.value })

    const updateArticle = (articleId: ArticleInterface['id'], value: ArticleInterface['value']) => setJournal({ ...localJournal, Articles: localJournal.Articles.map(a => a.id === articleId ? ({ ...a, value }) : a) })

    const deleteJournal = () => api.sante.carnets.journals.delete(journal.id)

    const validateEdit = () => {
        Promise.all([
            localJournal.Articles.map(article => api.sante.carnets.journals.articles.put(article))
        ]).then(() => api.sante.carnets.journals.put(journal.id, localJournal))

        toggleEdit()
    }

    React.useEffect(() => {
        let lJ = { ...localJournal }
        journal.Articles.map((article, index) => {
            if (!lJ.Articles.map(a => a.id).includes(article.id)) {
                lJ.Articles.splice(index, 0, article)
            }
            return article
        })
        lJ.Articles = lJ.Articles.filter(article => journal.Articles.map(a => a.id).includes(article.id))
        setJournal(lJ)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [journal, isEiOpen])

    return (
        <React.Fragment>
            <Box display="flex" alignItems="top" justifyContent="space-between">
                <Stack sx={{ flexGrow: 1, mr: 2, mb: 4 }}>
                    <TextField
                        fullWidth
                        label="Titre du journal"
                        value={localJournal.title}
                        onChange={changeTitle}
                    />
                    {!localJournal.title && <Typography color="error" variant="caption"><ReportProblem fontSize="small" />  Titre du journal ne peut être vide...</Typography>}
                </Stack>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <Box>
                        <IconButton onClick={validateEdit} disabled={!localJournal.title}>
                            <Check />
                        </IconButton>
                    </Box>
                    <Box>
                        <IconButton onClick={toggleEdit}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box>
                        <DeleteButtonConfirmation rounded onDelete={deleteJournal} />
                    </Box>
                </Box>
            </Box>
            <Grid container spacing={2} direction="column">
                {!journal.Articles
                    ? <LinearProgress />
                    : journal.Articles.map(article => <VueArticle key={article.id} article={article} updateArticle={updateArticle} />
                    )}
                <Grid item textAlign="center">
                    <Button variant="outlined" color="primary" onClick={addArticle}>
                        <Add />  Ajouter un article
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={toggleEiOpen}>
                        <Add />  Ajouter un effet indésirable
                    </Button>
                </Grid>
            </Grid>
            <EiSelector journal={journal} open={isEiOpen} onClose={toggleEiOpen} />
        </React.Fragment>
    )
}

interface VueArticleProp {
    article: ArticleInterface
    updateArticle: (id: ArticleInterface['id'], value: ArticleInterface['value']) => void
}

const VueArticle = ({ article, updateArticle }: VueArticleProp) => {
    const { api } = React.useContext(AppContext)

    const editArticle = (event: any) => updateArticle(article.id, event.target.value)
    const deleteArticle = () => api.sante.carnets.journals.articles.delete(article.id)

    return (
        <Grid item key={article.id} className="hoverContainer">
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <TextField
                    fullWidth
                    multiline
                    defaultValue={article.value}
                    onChange={editArticle}
                    sx={{ mr: 2 }}
                />
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <DeleteButtonConfirmation rounded onDelete={deleteArticle} />
                </Box>
            </Box>
        </Grid>
    )
}
export default VueJournalEdit