import React from "react";
import AppContext from "../../../AppContext";
import { EngraisInterface, Engraiss$ } from "../../../_api/_engrais";
import CommonList from "../CommonList";

const Image = require('../../../assets/engrais.jpg')

const ListEngrais = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<EngraisInterface[]>();

    React.useEffect(() => {
        Engraiss$.subscribe(l => setData(l))
        api.jardin.engrais.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CommonList
            data={data}
            title={"Engrais"}
            image={Image}
            apiCall={api.jardin.engrais}
            type={'engrais'}
        />
    )
}

export default ListEngrais