import { Box, Grid, Typography } from "@mui/material"
import React from "react"
import { useParams, useNavigate } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { Engrais$, EngraisInterface } from "../../../../_api/_engrais"
import Page from "../../../Page/Page"

const VueEngrais = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<EngraisInterface>()

    const handleEdit = () => navigate(`/jardin/engrais/${data && data.id}/edit`)

    React.useEffect(() => {
        Engrais$.subscribe(l => l && setData(l))
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.engrais.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.name : "Engrais",
                toggleEdit: handleEdit,
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            loading={!data}
        >
            {data &&
                <Box>
                    <Grid container spacing={2}>
                        {data.type
                            ? <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6">Type d'engrais</Typography>
                                <Typography variant="body2">{data.type}</Typography>
                            </Grid>
                            : ''}
                        {data.composition.K || data.composition.N || data.composition.P
                            ? <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6">Composition</Typography>
                                <Grid container spacing={2}>
                                    {data.composition.K &&
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Potasse</Typography>
                                            <Typography variant="body2">{data.composition.K}%</Typography>
                                        </Grid>}
                                    {data.composition.N &&
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Nitrate</Typography>
                                            <Typography variant="body2">{data.composition.N}%</Typography>
                                        </Grid>}
                                    {data.composition.P &&
                                        <Grid item xs={3}>
                                            <Typography variant="subtitle1">Phosphate</Typography>
                                            <Typography variant="body2">{data.composition.P}%</Typography>
                                        </Grid>}
                                </Grid>
                            </Grid>
                            : ''}
                        {data.modesUsage && data.modesUsage.length > 0
                            ? <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="h6">Modes d'usage</Typography>
                                <Typography variant="body2">{data.modesUsage.map((item, index) => item.value + (index + 1 < data.modesUsage.length ? ', ' : ''))}</Typography>
                            </Grid>
                            : ''}
                        {(data.momentsUsage && data.momentsUsage.length > 0) || (data.ensoleil)
                            ? <Grid item xs={12} sm={6} md={4} container spacing={2}>
                                {data.momentsUsage && data.momentsUsage.length > 0
                                    ? <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">Moments d'usage</Typography>
                                        <Typography variant="body2">{data.momentsUsage.map((item, index) => item.value + (index + 1 < data.momentsUsage.length ? ', ' : ''))}</Typography>
                                    </Grid>
                                    : ''}
                                {data.ensoleil
                                    ? <Grid item xs={12} sm={6}>
                                        <Typography variant="h6">Ensoleillement</Typography>
                                        <Typography variant="body2">{data.ensoleil}</Typography>
                                    </Grid>
                                    : ''}
                            </Grid>
                            : ''}
                    </Grid>
                </Box>}

        </Page>
    )
}

export default VueEngrais