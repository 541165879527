import { Box, TableCell } from "@mui/material"
import React from "react"
import { Examin, GroupExamins, labos } from "../Exams"

interface HeadRow1Props {
    labo: GroupExamins
    selected: Examin['id'][]
    sep?: boolean
}
export const HeadRow1 = ({ labo, selected, sep = true }: HeadRow1Props) => {
    const colSpan = labo.examins.filter(i => !selected.includes(i.id)).length
    if (colSpan === 0) return (<></>)
    return (
        <React.Fragment>
            <TableCell align="center" colSpan={colSpan}>
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    {labo.name}
                </Box>
            </TableCell>
            {sep && colSpan && <TableCell align="center" rowSpan={4} sx={{ width: 2 }} />}
        </React.Fragment>
    )
}

interface HeadRow2Props {
    size: number
    examin: Examin
    selected: Examin['id'][]
}
export const HeadRow2 = ({ size, examin, selected }: HeadRow2Props) => {
    if (examin.groupName) {
        const exams = labos.map(G => G.examins).flat().filter(E => !selected.includes(E.id)).filter(E => E.groupName === examin.groupName)
        if (exams.map(E => E.id).indexOf(examin.id) > 0) return (<></>)
        return (
            <TableCell align="center" sx={{ top: size }} colSpan={exams.length}>
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    {examin.groupName}
                </Box>
            </TableCell>
        )
    } else {
        return (
            <TableCell align="center" rowSpan={2} sx={{ top: size }}>
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    {examin.name}
                </Box>
            </TableCell>
        )
    }
}

export const HeadRow3 = ({ size, examin, selected }: HeadRow2Props) => {

    return (
        <TableCell align="center" sx={{ top: size * 2 }}>
            <Box sx={{ whiteSpace: 'nowrap' }}>
                {examin.inGroupName ?? examin.name}
            </Box>
        </TableCell>
    )
}