import { BehaviorSubject } from "rxjs"
import Http from './http/fleurs'
import { ListItemInterface } from "../useApp";
import { LegumeInterface } from "./_legumes";
import Idb from "../__api_v2/_Idb"

export interface FleurInterface {
    id: string;
    name: string;
    latin: string;
    variety: string;
    type: string;
    imageId: string | null;
    ravageurs: ListItemInterface[];
    legumesAmis: LegumeInterface[]
}


export const Fleurs$ = new BehaviorSubject<FleurInterface[]>([])
export const Fleur$ = new BehaviorSubject<FleurInterface | null>(null)

export default class ApiFleurs {
    private _idb!: Idb<'fleurs'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('fleurs')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Fleurs$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: FleurInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Fleurs$.next(data)))
                }
            }))
    }

    get = (id: FleurInterface['id']): void => {
        this._idb.get(id).then(data => data && Fleur$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: FleurInterface) => {
                    this._idb.post(data)
                    Fleur$.next(data)
                })
            }
        })
    }

    post = (): Promise<FleurInterface['id'] | null> => this._http.post().then(resp => {
        if (resp.status === 200) {
            return resp.json().then((el: FleurInterface) => {
                this._idb.post(el);
                Fleur$.next(el)
                return el.id
            })
        } else {
            return null;
        }
    }).catch(() => null)

    put = (id: FleurInterface['id'], fleur: Partial<FleurInterface>): Promise<number> => this._http.put(id, fleur).then(resp => {
        if (resp.status === 200) {
            const newFleur = { ...(Fleur$.getValue() as FleurInterface), ...fleur }
            this._idb.post(newFleur)
            Fleur$.next(newFleur)
            return 200
        }
        return 400
    }).catch(() => 500)

    uploadImage = async (id: FleurInterface['id'], file: File): Promise<void> => {
        var form = new FormData()
        form.append('file', file)
        return this._http.uploadImage(id, form).then(resp => {
            if (resp.status === 200) {
                return resp.json().then((id: string) => {
                    const newFleur = { ...(Fleur$.getValue() as FleurInterface), imageId: id }
                    this._idb.post(newFleur)
                    Fleur$.next(newFleur)
                })
            }
        })
    }

    deleteImage = async (id: FleurInterface['id']): Promise<void> => this._http.removeImage(id).then(resp => {
        if (resp.status === 204) {
            const newFleur = { ...(Fleur$.getValue() as FleurInterface), imageId: null }
            this._idb.post(newFleur)
            Fleur$.next(newFleur)
        }
    })

    delete = async (id: FleurInterface['id']): Promise<boolean> => this._http.delete(id).then(resp => {
        if (resp.status === 204) {
            this._idb.delete(id)
            Fleur$.next(null)
            return true
        }
        return false
    })
}