import { TableRow, TableCell } from "@mui/material"
import React from "react"
import { Examin, labos } from "../Exams"
import { HeadRow1, HeadRow2, HeadRow3 } from "./HeadRow"
import NormeCell from "./NormeCell"

export const colHeight = {
    small: 37.08,
    medium: 57.08
}

interface HeadProps {
    size: 'small' | 'medium'
    selected: Examin['id'][]
}
const Head = ({ size = 'medium', selected }: HeadProps) => {
    const examins = labos.map(L => L.examins).flat().filter(E => !selected.includes(E.id))
    return (
        <React.Fragment>
            <TableRow>
                <TableCell align="center" rowSpan={4} sx={{ position: 'sticky', left: 0, zIndex: 10 }}>Date</TableCell>
                {labos.map((L, index) => <HeadRow1 key={L.id} labo={L} selected={selected} sep={index + 1 < labos.length} />)}
                <TableCell align="center" rowSpan={4} />
            </TableRow>
            <TableRow>
                {examins.map(E =>
                    <HeadRow2 key={E.id} selected={selected} size={colHeight[size]} examin={E} />)}
            </TableRow>
            <TableRow>
                {examins.filter(E => E.groupName).map(E =>
                    <HeadRow3 key={E.id} selected={selected} size={colHeight[size]} examin={E} />)}
            </TableRow>
            <TableRow>
                {examins.map(examin =>
                    <NormeCell key={examin.id} size={colHeight[size]} selected={selected} examin={examin} />)}
            </TableRow>
        </React.Fragment>
    )
}

export default Head