import { Cookie, Edit, Print, Settings } from "@mui/icons-material"
import { Avatar, Box, Container, Paper, SpeedDial, SpeedDialAction, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../../AppContext"
import { CuisineInterface, Cuisine$ } from "../../../_api/_cuisines"
import Page from "../../Page/Page"
import VoirHeader from "./VoirHeader"
import VoirIngredients from "./VoirIngredients"

const VoirCuisine = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<CuisineInterface>()

    const actions = [
        { icon: <Print />, name: 'Imprimer', action: () => window.print() },
        { icon: <Edit />, name: 'Editer', action: () => navigate(`/cuisine/recettes/${params.id}/edit`) },
        { icon: <Cookie />, name: 'Retour à la liste des recettes', action: () => navigate(`/cuisine/recettes`) },
    ];

    React.useEffect(() => {
        Cuisine$.subscribe(c => c && setData(c))
    }, [])

    React.useEffect(() => {
        api.cuisine.get(params.id as string)
        document.body.scrollTo({ top: 0 })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])


    return (
        <Page loading={!data} noMargin>
            {data && <VoirHeader data={data} />}

            {data &&
                <Container component={Paper} sx={{ pt: 1, mb: -3, borderTopLeftRadius: 0, borderTopRightRadius: 0, zIndex: 0 }}>
                    <Box>
                        <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{ position: 'relative', top: 0, right: 0 }}
                            icon={<Settings />}
                            direction="left"
                        >
                            {actions.map((action) => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={action.action}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                    {data.ingredients.length > 0 && <VoirIngredients ingredients={data.ingredients} personne={data.personne} />}
                    {data.preparations.length > 0 &&
                        <React.Fragment>
                            <Typography variant="h4" gutterBottom>Préparation</Typography>
                            <Box sx={{ pb: 4 }}>
                                {data.preparations.map((preparation, index) =>
                                    <Box key={preparation.id} sx={{ display: 'flex', gap: 2, flexWrap: 'nowrap', mt: 2, mb: 4, alignItems: 'center' }}>
                                        <Avatar sizes="large">{index + 1}</Avatar>
                                        <Stack>
                                            {preparation.value.split("\n").map((prep, i) => <Typography key={`${preparation.id} ${i}`} variant="body1" gutterBottom>{prep}</Typography>)}
                                        </Stack>
                                    </Box>)}
                            </Box>
                        </React.Fragment>}
                </Container>}
        </Page>
    )
}

export default VoirCuisine