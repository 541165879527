import { TableCell, Typography } from "@mui/material"
import { Examin } from "../Exams"

interface NormeCellProps {
    examin: Examin
    selected: Examin['id'][]
    size: number
}
const NormeCell = ({ examin, size, selected }: NormeCellProps) => {
    const { min, max } = examin ?? { min: null, max: null }
    if (selected.includes(examin.id)) return (<></>)
    return (
        <TableCell sx={{ top: size * 3 }} align="center">
            {min && max ? <Typography variant="caption" sx={{ display: 'inline-flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>{min} — {max}</Typography> : ''}
            {!min && max ? <Typography variant="caption" sx={{ display: 'inline-flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>{'<'} {max}</Typography> : ''}
            {min && !max ? <Typography variant="caption" sx={{ display: 'inline-flex', flexWrap: 'nowrap', whiteSpace: 'nowrap' }}>{'>'} {min}</Typography> : ''}
        </TableCell>
    )
}

export default NormeCell