import { Dialog, DialogTitle, DialogContent, TextField, Box, List, ListItem, ListItemAvatar, Avatar, ListItemText, Typography, DialogActions, Button } from "@mui/material";
import React from "react";
import AppContext from "../../../AppContext";
import { CuisineIngredientInterface, CuisineInterface } from "../../../_api/_cuisines";
import { IngredientInterface, Ingredients$ } from "../../../_api/_ingredients";

interface IngredientDialogProps {
    data: CuisineInterface;
    setData: (data: CuisineInterface) => void;
    open: boolean,
    handleClose: () => void
}
type IngredientConstructor = { name: IngredientInterface['id'], unitId: IngredientInterface['unitId'], unit: IngredientInterface['unit'] }
const IngredientDialog = ({ data, setData, open, handleClose }: IngredientDialogProps) => {
    const { api } = React.useContext(AppContext)
    const [ingredients, setIngredients] = React.useState<IngredientInterface[]>()
    const [search, setSearch] = React.useState<string>('')
    const [ingredient, setIngredient] = React.useState<IngredientConstructor>({ name: '', unitId: null, unit: null })
    const [isSaving, toggleSaving] = React.useReducer(s => !s, false)
    const actualsIngredientsIds = data.ingredients.map(i => i.ingredientId)
    const ingredientsFiltered = (ingredients && ingredients.filter(f => !actualsIngredientsIds.includes(f.id)).filter(a => a.name.toLowerCase().includes(search.toLowerCase()))) || []

    const handleSelectIngredient = (ingredient: IngredientInterface) => {
        const newCuisineIngredient: CuisineIngredientInterface = { id: new Date().getTime(), value: ingredient.unitId === 4630 ? -1 : null, ingredientId: ingredient.id, ingredient: ingredient, facultatif: false, unitId: null, unit: null }
        setData({ ...data, ingredients: [...data.ingredients, newCuisineIngredient] })
        handleClose()
    }

    const close = () => !isSaving && handleClose()

    const save = () => {
        toggleSaving()
        api.cuisine.ingredients.post(ingredient).then(i => {
            if (i) {
                handleSelectIngredient(i)
                toggleSaving()
            } else {
                toggleSaving()
            }
        })
    }

    const capitalizeFirstLetter = (string: string): string => string ? string.charAt(0).toUpperCase() + string.slice(1) : string


    React.useEffect(() => {
        Ingredients$.subscribe(i => i && setIngredients(i))
        api.cuisine.ingredients.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        setSearch('')
        setIngredient({ name: '', unitId: null, unit: null })
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={close}
            fullWidth={true}
            maxWidth={`md`}>
            <DialogTitle>Ajouter un ingrédient</DialogTitle>
            <DialogContent>
                <TextField
                    value={search}
                    onChange={(event: any) => setSearch(event.target.value)}
                    autoFocus
                    margin="dense"
                    label="Rechercher un ingrédient"
                    fullWidth
                    variant="outlined"
                />
                {ingredientsFiltered && search.length > 1 &&
                    <Box>
                        <List>
                            {ingredientsFiltered.map(ingredient =>
                                <ListItem key={ingredient.id} button onClick={() => handleSelectIngredient(ingredient)}>
                                    <ListItemAvatar>
                                        <Avatar src={ingredient.imageId ? `/download/${ingredient.imageId}` : undefined}>
                                            {ingredient.name[0]}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={ingredient.name} />
                                </ListItem>)}

                            {ingredientsFiltered.length === 0 &&
                                <Box sx={{ mt: 3 }}>
                                    <Typography variant="subtitle1" color="error" gutterBottom>Ingrédient non trouvé</Typography>
                                    <Box sx={{ display: 'flex', flexWrap: 'nowrap', mt: 2 }}>

                                        <TextField
                                            label="Nom du nouvel ingrédient"
                                            value={ingredient.name}
                                            onChange={(event: any) => setIngredient({ ...ingredient, name: capitalizeFirstLetter(event.target.value) })}
                                            sx={{ flexGrow: 1, mr: 2 }}
                                            fullWidth />
                                        {/* <SelectCustom
                                            label="Unité de mesure"
                                            value={ingredient.unitId}
                                            type="cuisineUnit"
                                            onChange={(option: any) => setIngredient({ ...ingredient, unitId: option.id, unit: option })}
                                        /> */}
                                        {/* <TextField
                                            label="Unité de mesure"
                                            select
                                            value={ingredient.unit}
                                            onChange={(event: any) => setIngredient({ ...ingredient, unit: event.target.value })}
                                            sx={{ minWidth: '13em' }}
                                        >
                                            {units.map(unit => <ListItem key={unit} value={unit}>{unit}</ListItem>)}
                                        </TextField> */}
                                    </Box>

                                </Box>}
                        </List>
                    </Box>}
            </DialogContent>
            <DialogActions>
                {ingredient.name /*&& ingredient.unitId*/ && <Button onClick={save} disabled={isSaving}>Sélectionner l'ingrédient</Button>}
                <Button onClick={handleClose} disabled={isSaving}>Annuler</Button>
            </DialogActions>
        </Dialog>
    )
}
export default IngredientDialog