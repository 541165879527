import React from "react";
import AppContext from "../../../AppContext";
import { ChimiqueInterface, Chimiques$ } from "../../../_api/_chimiques";
import CommonList from "../CommonList";

const Image = require('../../../assets/chimique.jpg')

const ListChimiques = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<ChimiqueInterface[]>();


    React.useEffect(() => {
        Chimiques$.subscribe(l => setData(l))
        api.jardin.chimiques.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <CommonList
            data={data}
            title={"Produits chimiques"}
            image={Image}
            apiCall={api.jardin.chimiques}
            type={'produits-chimiques'}
        />
    )
}

export default ListChimiques