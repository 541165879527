import React from "react";
import { SelectChangeEvent, FormControl, InputLabel, Select, OutlinedInput, MenuItem, ListItemAvatar, Avatar, ListItemText, ListItem } from "@mui/material";
import { UserDbInterface } from "../../useApp";
import { Users$ } from "../../_api/_users";
import { map } from "rxjs";

interface UserSelectorProps {
    selectedUser: UserDbInterface['id']
    setSelectedUser: (id: UserDbInterface['id']) => void
    validUsers?: UserDbInterface['id'][]
}
const UserSelector = ({ validUsers, selectedUser, setSelectedUser }: UserSelectorProps) => {
    const [users, setUsers] = React.useState<UserDbInterface[]>(Users$.getValue())
    const actualUser = users && (users.find(u => u.id === selectedUser) as UserDbInterface)
    const handleChange = (event: SelectChangeEvent<UserDbInterface['id']>) => {
        const { target: { value } } = event
        setSelectedUser(value)
    }

    React.useEffect(() => {
        Users$
            .pipe(
                map(u => u.sort((a, b) => `${a.lastname} ${a.firstname}` < `${b.lastname} ${b.firstname}` ? -1 : 1))
            )
            .subscribe(u => u && u.length > 0 && setUsers(u))
    }, [])

    return (
        <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Utilisateur sélectionné</InputLabel>
            {users && actualUser &&
                <Select
                    fullWidth
                    value={selectedUser}
                    onChange={handleChange}
                    input={<OutlinedInput label={`Utilisateur sélectionné`} />}
                    renderValue={(option) => <ActualUser actualUser={actualUser} />}
                >
                    {users && users.filter(f => (validUsers || users.map(u => u.id)).includes(f.id)).map(user =>
                        <MenuItem key={user.id} value={user.id}>
                            <ListItemAvatar>
                                <Avatar alt={`${user.firstname} ${user.lastname}`} title={`${user.firstname} ${user.lastname}`} src={user.imageId ? `/download/${user.imageId}` : undefined} >
                                    {`${user.firstname.split('')[0]}${user.lastname.split('')[0]}`}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={`${user.firstname} ${user.lastname}`} />
                        </MenuItem>)}
                </Select>}

        </FormControl>
    )
}


interface ActualUserProps {
    actualUser: UserDbInterface
}
const ActualUser = ({ actualUser }: ActualUserProps) => {
    return (
        <ListItem value={actualUser.email}>
            <ListItemAvatar>
                <Avatar alt={`${actualUser.firstname} ${actualUser.lastname}`} title={`${actualUser.firstname} ${actualUser.lastname}`} src={actualUser.imageId ? `/download/${actualUser.imageId}` : undefined} >
                    {`${actualUser.firstname.split('')[0]}${actualUser.lastname.split('')[0]}`}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={`${actualUser.firstname} ${actualUser.lastname}`} />
        </ListItem>
    )
}

export default UserSelector