import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    getAll = (): Promise<Response> => this._fetch.get(`/api/list`)

    // post = (): Promise<Response> => this._fetch.post(`/api/sante/constantes`)
    // get = (id: HealthDataInterface['id']): Promise<Response> => this._fetch.get(`/api/sante/constantes/${id}`)
    // put = (data: HealthDataInterface): Promise<Response> => this._fetch.put(`/api/sante/constantes/${data.id}`, data)
    // delete = (id: HealthDataInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/constantes/${id}`)

}