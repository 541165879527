import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Page from "./Page/Page";

interface LandingPageProps {
    title: string;
    tuiles: TuileProps[]
}
const LandingPage = ({ title, tuiles }: LandingPageProps) => {
    return (
        <Page loading={false} title={{ title: title }} overflow={'auto'}>
            <Grid container spacing={3} justifyContent="center" alignContent="center">
                {tuiles.map((tuile) =>
                    <Tuile
                        key={tuile.url}
                        url={tuile.url}
                        img={tuile.img}
                        primary={tuile.primary}
                        buttons={tuile.buttons}
                    />)}
            </Grid>
        </Page>
    )
}

export interface TuileProps {
    url?: string;
    img?: string;
    primary: string;
    secondary?: string;
    buttons?: { primary: string, url: string }[]
}
const Tuile = ({ url, img, primary, secondary, buttons, ...rest }: TuileProps) => {
    return (
        <Grid item xs={12} md={7} lg={4} {...rest}>
            <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }} >
                <CardActionArea disabled={!url} component={Link} to={url ? url : '/'} sx={{ flexGrow: 1 }}>
                    {img && <CardMedia
                        component="img"
                        height="140"
                        image={img}
                    />}
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">{primary}</Typography>
                        {secondary && <Typography variant="body2" color="text.secondary">{secondary}</Typography>}
                    </CardContent>
                </CardActionArea>
                {buttons && buttons.length > 0 && <CardActions sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {buttons.map(button => <Button key={button.url} size="small" component={Link} to={button.url} sx={{ whiteSpace: 'nowrap', ml: '0px !important' }}>{button.primary}</Button>)}
                </CardActions>}
            </Card>
        </Grid>
    )
}

export default LandingPage