import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import AppContext from "../AppContext";



interface UserSpaceProps {
    pxSize?: number
}

const UserSpace = ({ pxSize = 100 }: UserSpaceProps) => {
    const { user } = React.useContext(AppContext)

    if (!user) return <></>
    return (<>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', alignItems: 'center', p: 2 }}>
            <Avatar sx={{ m: 2, width: pxSize, height: pxSize }} alt={`${user.firstName} ${user.lastName}`} src={user.imageId ? `/download/${user.imageId}` : undefined}>
                {user.firstName[0]}{user.lastName[0]}
            </Avatar>
            <Box textAlign={'center'}>
                <Typography variant="subtitle1">{user.firstName} {user.lastName}</Typography>
                <Typography variant="caption" color="GrayText">{user.email}</Typography>
            </Box>
            <Button component={Link} to="/me">
                Mon profil
            </Button>
        </Box>
        <Divider />
    </>
    )
}

export default UserSpace