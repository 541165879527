import { Edit } from "@mui/icons-material"
import { TableRow, TableCell, Checkbox, Box, IconButton } from "@mui/material"
import DeleteButtonConfirmation from "App/Components/DeleteButtonConfirmation"
import { ConstanteInterface } from "App/_api/_constantes"
import { Dispatch, SetStateAction } from "react"
import { Link } from "react-router-dom"
import Cell from "./Cell"
import { PAm } from "./DataTable"

interface ConstanteRowProps {
    constante: ConstanteInterface
    selected: ConstanteInterface['id'][]
    setSelected: Dispatch<SetStateAction<ConstanteInterface['id'][]>>
    deleteConstante: (id: ConstanteInterface['id']) => void
}
const ConstanteRow = ({ constante, selected, setSelected, deleteConstante }: ConstanteRowProps) => {

    return (
        <TableRow>
            <TableCell />
            <TableCell />
            <TableCell padding="checkbox">
                <Checkbox
                    checked={selected.includes(constante.id)}
                    onChange={() => setSelected(selected.includes(constante.id) ? selected.filter(f => f !== constante.id) : [...selected, constante.id])}
                />
            </TableCell>
            <Cell value={Intl.DateTimeFormat('fr-FR', { timeStyle: 'short' }).format(new Date(constante.mesureAt))} />
            <Cell value={constante.value.pni?.g?.s} min={100} max={140} />
            <Cell value={constante.value.pni?.g?.d} min={80} max={90} />
            <Cell value={constante.value.pouls?.g} min={50} max={80} />
            <TableCell />
            <Cell value={constante.value.pni?.d?.s} min={100} max={140} />
            <Cell value={constante.value.pni?.d?.d} min={80} max={90} />
            <Cell value={constante.value.pouls?.d} min={50} max={80} />
            <TableCell />
            <Cell value={constante.value.masse ?? ''} />
            <Cell value={constante.value.temp} min={36} max={37.5} />
            <TableCell />
            <Cell value={constante.value.pni?.g?.s && constante.value.pni?.g?.d ? constante.value.pni?.g?.s - constante.value.pni?.g?.d : ''} />
            <Cell value={constante.value.pni?.d?.s && constante.value.pni?.d?.d ? constante.value.pni?.d?.s - constante.value.pni?.d?.d : ''} />
            <TableCell />

            <Cell value={PAm(constante.value.pni?.g?.s, constante.value.pni?.g?.d) ?? null} min={60} max={100} />
            <Cell value={PAm(constante.value.pni?.d?.s, constante.value.pni?.d?.d) ?? null} min={60} max={100} />

            <TableCell />
            <TableCell className="hoverTarget">
                <Box sx={{ display: 'inline-flex' }}>
                    <IconButton component={Link} to={`/sante/constantes/${constante.id}`}>
                        <Edit />
                    </IconButton>
                    <DeleteButtonConfirmation
                        rounded
                        onDelete={() => deleteConstante(constante.id)} />
                </Box>
            </TableCell>
        </TableRow >
    )
}

export default ConstanteRow