import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Tooltip } from "@mui/material";
import React from "react";


interface DeleteButtonProps {
    deleteButon?: (toggleOpen: () => void) => JSX.Element;
    onDelete: () => void;
    rounded?: boolean;
    toolTip?: string;
    title?: string;
    text?: string[]
    disabled?: boolean
}
const DeleteButtonConfirmation = ({
    deleteButon, onDelete, rounded = false,
    title = 'Confirmation requise', text, toolTip = 'Supprimer', disabled
}: DeleteButtonProps) => {
    const [open, toggleOpen] = React.useReducer(o => !o, false)

    const handleDelete = () => {
        toggleOpen()
        onDelete()
    }

    return (
        <React.Fragment>
            {toolTip
                ? <Tooltip title={toolTip}>
                    <div>
                        {deleteButon
                            ? deleteButon(toggleOpen)
                            : rounded
                                ? <IconButton color="error" onClick={toggleOpen}>
                                    <Delete />
                                </IconButton>
                                : <Button variant="outlined" color="error" onClick={toggleOpen}>
                                    <Delete />  Supprimer
                                </Button>}
                    </div>
                </Tooltip>
                : deleteButon
                    ? deleteButon(toggleOpen)
                    : rounded
                        ? <IconButton color="error" onClick={toggleOpen} disabled={disabled}>
                            <Delete />
                        </IconButton>
                        : <Button variant="outlined" color="error" onClick={toggleOpen} disabled={disabled}>
                            <Delete />  Supprimer
                        </Button>}

            <Dialog
                open={open}
                onClose={toggleOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth

            >
                <DialogTitle id="alert-dialog-title" color="error">
                    {title}
                </DialogTitle>
                <DialogContent>
                    {text
                        ? text.map(t => <DialogContentText key={t} >{t}</DialogContentText>)
                        : <DialogContentText id="alert-dialog-description">Vous êtes sur le point de supprimer un élement.</DialogContentText>}
                    <DialogContentText id="alert-dialog-description" color="error" sx={{ mt: 2 }}>
                        Êtes-vous certains de vouloir continuer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleDelete}>Supprimer</Button>
                    <Button variant="outlined" color="secondary" onClick={toggleOpen} autoFocus>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

export default DeleteButtonConfirmation