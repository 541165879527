import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { TableCell, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { LaboInterface } from "App/_api/_labos"
import React from "react"
import { Examin } from "../Exams"

interface LaboCellProps {
    examin: Examin
    labos: LaboInterface['LaboValues']
}
const LaboCell = ({ examin, labos }: LaboCellProps) => {
    const { min, max } = examin ?? { min: null, max: null }
    const value = labos.find(l => l.type === examin.id)
    return (
        <TableCell align="center">
            <Tooltip title={<React.Fragment>
                <Typography>{examin.name}</Typography>
                {min || max
                    ? <Typography variant="caption">
                        {min && max
                            ? `${min} à ${max}`
                            : min && !max
                                ? `> ${min}`
                                : max && !min
                                    ? `< ${max}`
                                    : ''}
                    </Typography>
                    : ''}
            </React.Fragment>
            }>

                <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', justifyContent: 'center', whiteSpace: 'nowrap' }}>
                    {min && value && value.value < min ? <KeyboardArrowDown color="error" /> : ''}
                    {max && value && value.value > max ? <KeyboardArrowUp color="error" /> : ''}
                    {value && examin.id !== 'serum_aspect' ? value.value : ''}
                    {value && examin.id === 'serum_aspect'
                        ? value.value === 0
                            ? 'Limpide'
                            : value.value === 1
                                ? 'Non hémolysé'
                                : value.value
                        : ''}
                </Box>
            </Tooltip>
        </TableCell>
    )
}

export default LaboCell