import { Box, Grid, TextField, Typography, InputAdornment } from "@mui/material"
import React from "react"
import { useNavigate, useParams } from "react-router-dom"
import AppContext from "../../../../AppContext"
import { ListItemInterface } from "../../../../useApp"
import { ChimiqueInterface, Chimique$ } from "../../../../_api/_chimiques"
import ImageUploader from "../../../ImageUploader"
import Page from "../../../Page/Page"
import { SelectCustomEnum, SelectCustomMultiple } from "../../../SelectCustom"


const EditChimique = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<ChimiqueInterface>()

    const handleChangeName = (event: any) => setData({ ...(data as ChimiqueInterface), name: event.target.value })
    const handleChangeType = (event: string) => setData({ ...(data as ChimiqueInterface), type: event })
    const handleChangeK = (event: any) => setData({ ...(data as ChimiqueInterface), composition: { ...(data as ChimiqueInterface).composition, K: +event.target.value } })
    const handleChangeN = (event: any) => setData({ ...(data as ChimiqueInterface), composition: { ...(data as ChimiqueInterface).composition, N: +event.target.value } })
    const handleChangeP = (event: any) => setData({ ...(data as ChimiqueInterface), composition: { ...(data as ChimiqueInterface).composition, P: +event.target.value } })
    const handleChangeModeUsage = (options: ListItemInterface[]) => setData({ ...(data as ChimiqueInterface), modesUsage: options })
    const handleChangeMomentUsage = (options: ListItemInterface[]) => setData({ ...(data as ChimiqueInterface), momentsUsage: options })
    const handleChangeSol = (event: string) => setData({ ...(data as ChimiqueInterface), ensoleil: event })

    const hasChange = () => {
        if (!data) return false
        let oldChimique = Chimique$.getValue() as ChimiqueInterface;
        if (oldChimique.name !== data.name) return true
        if (oldChimique.type !== data.type) return true
        if (JSON.stringify(oldChimique.composition) !== JSON.stringify(data.composition)) return true
        if (JSON.stringify(oldChimique.modesUsage) !== JSON.stringify(data.modesUsage)) return true
        if (JSON.stringify(oldChimique.momentsUsage) !== JSON.stringify(data.momentsUsage)) return true
        if (oldChimique.ensoleil !== data.ensoleil) return true

        return false;
    }

    const handleSave = () => {
        if (!data) return false
        let newChimique: Partial<ChimiqueInterface> = {}
        let oldChimique = Chimique$.getValue() as ChimiqueInterface;
        if (oldChimique.name !== data.name) newChimique.name = data.name
        if (oldChimique.type !== data.type) newChimique.type = data.type
        if (JSON.stringify(oldChimique.composition) !== JSON.stringify(data.composition)) newChimique.composition = data.composition
        if (JSON.stringify(oldChimique.modesUsage) !== JSON.stringify(data.modesUsage)) newChimique.modesUsage = data.modesUsage
        if (JSON.stringify(oldChimique.momentsUsage) !== JSON.stringify(data.momentsUsage)) newChimique.momentsUsage = data.momentsUsage
        if (oldChimique.ensoleil !== data.ensoleil) newChimique.ensoleil = data.ensoleil

        api.jardin.chimiques.put(data.id, newChimique)
    }

    const handleDelete = () => api.jardin.chimiques.delete(params.id as ChimiqueInterface['id']).then(success => success && navigate(`/jardin/produits-chimiques`))


    React.useEffect(() => {
        Chimique$.subscribe(l => {
            if (l) {
                setData(l)
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (!data || data.id !== params.id) {
            api.jardin.chimiques.get(params.id as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id])
    return (
        <Page
            title={{
                title: data ? data.name : "Produit chimique",
                imageUrl: data && data.imageId ? `/download/${data.imageId}` : undefined,
            }}
            footer={{
                handleSave: handleSave,
                disabledSave: hasChange(),
                handleDelete: handleDelete,
                handleCancel: () => hasChange() ? setData(Chimique$.getValue() as ChimiqueInterface) : navigate(`/jardin/produits-chimiques/${params.id}`),
                cancelPlaceholder: hasChange() ? 'Annuler' : 'Retour'
            }}
            loading={!data}
        >
            {data &&
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                label="Nom du produit chimique"
                                fullWidth
                                value={data.name}
                                onChange={handleChangeName}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectCustomEnum label="Type du produit chimique" value={data.type} options={['Minéral', 'Organique', 'Organo-minéral', 'Vert']} onChange={handleChangeType} />
                        </Grid>
                        <Grid item xs={6}>
                            <ImageUploader data={data} callApi={api.jardin.chimiques} />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="caption" color="text.secondary">Composition</Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Potasse"
                                        fullWidth
                                        value={data.composition.K || ''}
                                        onChange={handleChangeK}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Nitrate"
                                        fullWidth
                                        value={data.composition.N || ''}
                                        onChange={handleChangeN}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        label="Phosphate"
                                        fullWidth
                                        value={data.composition.P || ''}
                                        onChange={handleChangeP}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                        }}
                                    />
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <SelectCustomMultiple label="Mode d'utilisation" value={data.modesUsage?.map(d => d.id) || []} type="jarEngUsage" onChange={handleChangeModeUsage} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectCustomMultiple label="Moment d'utilisation" value={data.momentsUsage?.map(d => d.id) || []} type="jarEngMoment" onChange={handleChangeMomentUsage} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectCustomEnum label="Ensoleillement" value={data.ensoleil} options={['Ensoleillé', 'Non ensoleillé', 'Tous temps']} onChange={handleChangeSol} />
                        </Grid>
                    </Grid>
                </Box>}

        </ Page>
    )
}

export default EditChimique