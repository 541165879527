import { BehaviorSubject } from "rxjs"
import { UserDbInterface } from "../useApp"
import Idb from "../__api_v2/_Idb"
import Http from './http/users'
import { refreshTokens } from "../_rxjs/auth"

export const Users$ = new BehaviorSubject<UserDbInterface[]>([])
export const User$ = new BehaviorSubject<UserDbInterface | null>(null)

export default class ApiUsers {
    private _idb!: Idb<'users'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('users')
        this._http = new Http()
    }



    getAll = async (): Promise<void> => {
        this._idb.getAll().then(data => Users$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: UserDbInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Users$.next(data)))
                }
            }))
    }

    putMe = (data: Partial<UserDbInterface>): Promise<any> => {
        return this._http.putMe(data).then(() => refreshTokens())
    }

    uploadMeImage = (file: File) => {
        var form = new FormData()
        form.append('file', file)
        this._http.uploadMeImage(form).then(() => {
            refreshTokens()
        })
    }

    deleteMeImage = () => this._http.deleteMeImage().then(() => {
        refreshTokens()
    })

    // get = (id: UserDbInterface['id']): void => {
    //     this._idb.get(id).then(data => data && User$.next(data))
    //     this._http.get(id).then(resp => {
    //         if (resp.status === 200) {
    //             resp.json().then((data: UserDbInterface) => {
    //                 this._idb.post(data)
    //                 User$.next(data)
    //             })
    //         }
    //     })
    // }
    // put = (id: UserDbInterface['id'], data: Partial<UserDbInterface>): void => {
    //     const oldData = User$.getValue();
    //     this._http.put(id, data).then(resp => {
    //         if (resp.status === 200) {
    //             return resp.json().then((newData: UserDbInterface) => {
    //                 User$.next(newData)
    //                 this._idb.post(newData)
    //             })
    //         } else {
    //             User$.next(oldData)
    //         }
    //     })
    // }

    // deleteFile = (id: FileInterface['id']) => this._http.deleteFile((User$.getValue() as UserDbInterface).id, id).then(resp => {
    //     if (resp.status === 204) {
    //         User$.next({ ...(User$.getValue() as UserDbInterface), Files: (User$.getValue() as UserDbInterface).Files.filter(f => f.id !== id) })
    //     }
    // })

    // uploadFile = (id: UserDbInterface['id'], file: File) => {
    //     let form = new FormData();
    //     form.append('file', file)
    //     this._http.uploadFile(id, form).then((resp) => {
    //         if (resp.status === 200) {
    //             resp.json().then((file: FileInterface) => {
    //                 let newSite = { ...User$.getValue() as UserDbInterface, Files: [...(User$.getValue() as UserDbInterface).Files, file] }
    //                 this._idb.post(newSite)
    //                 User$.next(newSite)
    //             })
    //         }
    //     })
    // }

    // uploadImage = (id: UserDbInterface['id'], file: File) => {
    //     var form = new FormData()
    //     form.append('file', file)
    //     this._http.uploadImage(id, form).then((resp) => {
    //         if (resp.status === 200) resp.json().then(resp => {
    //             const newSite = { ...User$.getValue() as UserDbInterface }
    //             newSite.image = resp.image
    //             this._idb.post(newSite)
    //             User$.next(newSite)
    //         })
    //     })
    // }

    // deleteImage = (id: UserDbInterface['id']) => this._http.deleteImage(id).then((resp) => {
    //     if (resp.status === 204) {
    //         const newSite = { ...User$.getValue() as UserDbInterface }
    //         newSite.image = ''
    //         this._idb.post(newSite)
    //         User$.next(newSite)
    //     }
    // })

    // setCategories = (siteId: UserDbInterface['id'], categorieIds: ListItemInterface['id'][]) => {
    //     let newCategories = Lists$.getValue().filter(c => categorieIds.includes(c.id))
    //     User$.next({ ...User$.getValue() as UserDbInterface, Categories: newCategories })
    //     this._http.setCategories(siteId, categorieIds)
    //         .then(resp => {
    //             if (resp.status === 200) {
    //                 resp.json().then((site: UserDbInterface) => {
    //                     this._idb.post(site)
    //                     User$.next(site)
    //                 })
    //             }
    //         })
    // }
}

namespace ApiUsers { /* empty */ }