import { LinearProgress } from "@mui/material"
import React from "react"
import { useParams } from "react-router-dom"
import AppContext from "../../../AppContext"
import { CarnetInterface } from "../../../useApp"
import { Carnet$ } from "../../../_api/_carnets"
import Page from "../../Page/Page"
import VueJournal from "./VueJournal"

const VueCarnet = () => {
    const params = useParams()
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<CarnetInterface>()

    const addJournal = () => api.sante.carnets.journals.post((data as CarnetInterface).id)

    React.useEffect(() => {
        Carnet$.subscribe(d => d && setData({ ...d }))
        api.sante.carnets.get(params.id as string)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page
            title={{
                title: data ? data.title : 'Carnet de bord',
                subtitle: data ? `Carnet de bord du ${new Intl.DateTimeFormat('fr-FR', { dateStyle: 'medium' }).format(new Date(data.createdAt))} au ${new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long' }).format(new Date(data.updatedAt))}` : undefined
            }}
            loading={!data}
            searchable={{
                add: addJournal,
                addPlaceholder: 'Ajouter un journal'
            }}
        >
            {!data
                ? <LinearProgress />
                : <>
                    {!data.Journals
                        ? <LinearProgress />
                        : data.Journals.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).map((journal, i) =>
                            <VueJournal key={journal.id} journal={journal} isLast={i + 1 === data.Journals.length} />)}
                </>}
        </Page>
    )
}


export default VueCarnet