import React from "react";

import useApp from "./useApp";
import AppContext from "./AppContext";
import Router from "./Router/Router";
import { Box, CircularProgress, Grid, ThemeProvider, Typography } from "@mui/material";
import useThemes from "./themes/useTheme";
import ThemeContext from "./themes/ThemeContext";


const App = () => {
    const [api, lists, ui, user] = useApp()
    const theme = useThemes()

    return (
        <ThemeContext.Provider value={theme}>
            <ThemeProvider theme={theme.theme}>
                <Box sx={{ position: { xs: 'relative', md: 'fixed' }, top: 0, left: 0, bottom: 0, right: 0, overflowX: 'hidden', }}>
                    <AppContext.Provider value={{
                        api, lists, ui, user
                    }}>
                        {user === undefined
                            ? <WaitingInit />
                            : <Router />}
                    </AppContext.Provider>
                </Box>
            </ThemeProvider>
        </ThemeContext.Provider>
    )
}

const WaitingInit = () => {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ width: '100vw', height: '100vh' }} >
            <Grid>
                <CircularProgress />
            </Grid>
            <Grid>
                <Typography variant="h2" color="primary">Famille Lucas</Typography>
            </Grid>

        </Grid>
    )
}

export default App