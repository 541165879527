import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import AppContext from '../AppContext';
import LeftMenu from './LeftMenu';
import Header from './Header';

interface MainProps extends MuiAppBarProps {
    open?: boolean;
    drawerwidth: number | '100%'
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<MainProps>(({ theme, open, drawerwidth }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#303030' : '#fafafa',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
    maxHeight: '100%',
    // padding: theme.spacing(3),
    overflowX: 'hidden',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerwidth === '100%' ? '-100%' : `-${drawerwidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
    drawerwidth: number | '100%'
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, drawerwidth }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerwidth}px)`,
        marginLeft: `${drawerwidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft(props: any) {
    const { ui } = React.useContext(AppContext)
    return (
        <Box sx={{ display: 'flex', height: '100%', overflowX: 'hidden', }}>
            <CssBaseline />
            <AppBar position="fixed" open={ui.drawer.open} drawerwidth={ui.drawer.width} color={'default'}>
                <Header />
            </AppBar>
            <Drawer
                sx={{
                    width: ui.drawer.width,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: ui.drawer.width,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={ui.drawer.open}
            >
                <LeftMenu />
            </Drawer>
            <Main open={ui.drawer.open} drawerwidth={ui.drawer.width}>
                <React.Fragment>
                    <DrawerHeader />
                    {props.children}
                </React.Fragment>
            </Main>
        </Box>
    );
}
