import React from "react";
import AppContext from "../../../AppContext";
import { LegumeInterface, Legumes$ } from "../../../_api/_legumes";
import CommonList from "../CommonList";

const Image = require('../../../assets/legumes.jpg')

const ListLegumes = () => {
    const { api } = React.useContext(AppContext)
    const [data, setData] = React.useState<LegumeInterface[]>();

    React.useEffect(() => {
        Legumes$.subscribe(l => setData(l))
        api.jardin.legumes.getAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <CommonList
            data={data}
            title={"Legumes"}
            image={Image}
            apiCall={api.jardin.legumes}
            type={'legumes'}
        />
    )
}

export default ListLegumes