import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AppContext from "../AppContext";
import EditCuisine from "../Components/Cuisine/Edit/EditCuisine";
import EditIngredient from "../Components/Cuisine/Ingredients/EditIngredient";
import ListIngredients from "../Components/Cuisine/Ingredients/ListIngredients";
import LandingCuisine from "../Components/Cuisine/LandingCuisine";
import ListCuisine from "../Components/Cuisine/ListCuisine";
import VoirCuisine from "../Components/Cuisine/Voir/VoirCuisine";
import EditChimique from "../Components/Jardin/Chimique/Edition/Edit";
import ListChimiques from "../Components/Jardin/Chimique/ListChimiques";
import VueChimique from "../Components/Jardin/Chimique/Vue/VueChimique";
import EditEngrais from "../Components/Jardin/Engrais/Edition/Edit";
import ListEngrais from "../Components/Jardin/Engrais/ListEngrais";
import VueEngrais from "../Components/Jardin/Engrais/Vue/VueEngrais";
import EditFleur from "../Components/Jardin/Fleurs/Edition/Edit";
import ListFleurs from "../Components/Jardin/Fleurs/ListFleurs";
import VueFleur from "../Components/Jardin/Fleurs/Vue/VueFleur";
import LandingJardin from "../Components/Jardin/LandingJardin";
import EditLegume from "../Components/Jardin/Legumes/Edition/Edit";
import ListLegumes from "../Components/Jardin/Legumes/ListLegumes";
import VueLegume from "../Components/Jardin/Legumes/VueLegume";
import EditPurin from "../Components/Jardin/Purins/Edition/Edit";
import ListPurins from "../Components/Jardin/Purins/ListPurins";
import VuePurin from "../Components/Jardin/Purins/Vue/VuePurin";
import LandingPage from "../Components/MainLanding";
import LoginPage from "../Components/Login";
import EditConstante from "../Components/Sante/Constantes/Edit";
import SanteConstantesLP from "../Components/Sante/Constantes/List/Landing";
import JdBLanding from "../Components/Sante/JdB/Landing";
import VueCarnet from "../Components/Sante/JdB/VueCarnet";
import SanteLanding from "../Components/Sante/SanteLanding";
import SitesLanding from "../Components/Sites/Landing";
import VueSite from "../Components/Sites/VueSite";
import Ui from '../Interface/Ui'
import Profil from "../Components/Profil/Profil";
import List from "App/Components/Sante/Biologie/List/List";

const Router = (props: any) => {
    const { user } = React.useContext(AppContext)
    return (
        <BrowserRouter>
            {user
                ? <Ui >
                    <Routes>
                        <Route path="/cuisine" element={<LandingCuisine />} />
                        <Route path="/cuisine/recettes" element={<ListCuisine />} />
                        <Route path="/cuisine/recettes/:id" element={<VoirCuisine />} />
                        <Route path="/cuisine/recettes/:id/edit" element={<EditCuisine />} />
                        <Route path="/cuisine/ingredients" element={<ListIngredients />} />
                        <Route path="/cuisine/ingredients/:id" element={<EditIngredient />} />

                        <Route path="/jardin" element={<LandingJardin />} />
                        <Route path="/jardin/legumes" element={<ListLegumes />} />
                        <Route path="/jardin/legumes/:id" element={<VueLegume />} />
                        <Route path="/jardin/legumes/:id/edit" element={<EditLegume />} />
                        <Route path="/jardin/fleurs" element={<ListFleurs />} />
                        <Route path="/jardin/fleurs/:id" element={<VueFleur />} />
                        <Route path="/jardin/fleurs/:id/edit" element={<EditFleur />} />
                        <Route path="/jardin/purins" element={<ListPurins />} />
                        <Route path="/jardin/purins/:id" element={<VuePurin />} />
                        <Route path="/jardin/purins/:id/edit" element={<EditPurin />} />
                        <Route path="/jardin/engrais" element={<ListEngrais />} />
                        <Route path="/jardin/engrais/:id" element={<VueEngrais />} />
                        <Route path="/jardin/engrais/:id/edit" element={<EditEngrais />} />
                        <Route path="/jardin/produits-chimiques" element={<ListChimiques />} />
                        <Route path="/jardin/produits-chimiques/:id" element={<VueChimique />} />
                        <Route path="/jardin/produits-chimiques/:id/edit" element={<EditChimique />} />

                        <Route path="/" element={<LandingPage />} />
                        <Route path="/sante" element={<SanteLanding />} />
                        <Route path="/sante/constantes" element={<SanteConstantesLP />} />
                        <Route path="/sante/constantes/:id" element={<EditConstante />} />
                        <Route path="/sante/carnets-de-bord" element={<JdBLanding />} />
                        <Route path="/sante/carnets-de-bord/:id" element={<VueCarnet />} />
                        <Route path="/sante/biologie" element={<List />} />

                        <Route path="/sites" element={<SitesLanding />} />
                        <Route path="/sites/:id" element={<VueSite />} />

                        <Route path="/me" element={<Profil />} />


                        <Route path="*" element={<>404</>} />
                    </Routes>
                </Ui>
                : <LoginPage />}
        </BrowserRouter>
    )
}

export default Router