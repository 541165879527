import React from "react";
import LandingPage from "../LandingPage";

const santeConstantesImg = require('../../assets/sante_constantes.webp')
const santeCarnetsImg = require('../../assets/sante_carnets.png')

const SanteLanding = () =>
    <LandingPage
        title="Santé"
        tuiles={[
            {
                url: `/sante/constantes`,
                img: santeConstantesImg,
                primary: `Constantes`
            },
            {
                url: `/sante/carnets-de-bord`,
                img: santeCarnetsImg,
                primary: `Carnets de bord`
            },
        ]}
    />

export default SanteLanding