import { KeyboardArrowUp, KeyboardArrowDown } from "@mui/icons-material"
import { TableRow, TableCell, Checkbox, IconButton } from "@mui/material"
import DeleteButtonConfirmation from "App/Components/DeleteButtonConfirmation"
import { ConstanteInterface } from "App/_api/_constantes"
import React, { Dispatch, SetStateAction } from "react"
import Cell from "./Cell"
import ConstanteRow from "./ConstanteRow"
import { Row } from "./DataTable"
import GroupeRowDataCells from "./GroupeRowDataCells"

interface GroupRowProps {
    row: Row
    selected: ConstanteInterface['id'][], setSelected: Dispatch<SetStateAction<ConstanteInterface['id'][]>>,
    deleteConstante: (id: ConstanteInterface['id']) => Promise<void>
    defaultOpen?: boolean
}
const GroupRow = ({
    row,
    selected, setSelected,
    deleteConstante,
    defaultOpen = true
}: GroupRowProps) => {
    const [open, toggleOpen] = React.useReducer(o => !o, defaultOpen)

    const isSelected = row.constantes.map(c => c.id).filter(i => selected.includes(i))
    const handleChangeSelected = ({ target: { checked } }: any) => {
        let localSelected: string[] = []
        if (checked === true) {
            localSelected = [...selected, ...row.constantes.map(c => c.id)]
        } else {
            localSelected = [...selected]
            row.constantes.forEach(c => localSelected = localSelected.filter(i => i !== c.id))
        }
        setSelected(localSelected)
    }
    const handleDelete = async () => {
        row.constantes.map(c => deleteConstante(c.id))
    }

    return (
        <React.Fragment>
            <TableRow key={row.date} >
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={isSelected.length === row.constantes.length}
                        indeterminate={isSelected.length > 0 && isSelected.length < row.constantes.length}
                        onChange={handleChangeSelected}
                    />
                </TableCell>
                <TableCell size="small">
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => toggleOpen()}
                    >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell>
                <Cell value={Intl.DateTimeFormat('fr-FR', { weekday: 'long' }).format(new Date(row.date))} />
                <Cell value={Intl.DateTimeFormat('fr-FR', { day: 'numeric', month: 'short', year: 'numeric' }).format(new Date(row.date))} />
                {open && <TableCell colSpan={17} />}
                {!open &&
                    <GroupeRowDataCells constantes={row.constantes} />}

                <TableCell className="hoverTarget">
                    <DeleteButtonConfirmation
                        text={[`Vous-êtes sur le point de supprimer ${row.constantes.length} prise${row.constantes.length > 1 ? 's' : ''} de constantes`]}
                        rounded
                        onDelete={handleDelete} />
                </TableCell>
            </TableRow>
            {open && row.constantes.map(constante =>
                <ConstanteRow
                    key={constante.id}
                    constante={constante}
                    selected={selected}
                    setSelected={setSelected}
                    deleteConstante={deleteConstante}
                />)}
        </React.Fragment>
    )
}

export default GroupRow