import { BehaviorSubject } from "rxjs"
import Http from './http/constantes'
import Idb from "../__api_v2/_Idb"

export interface ConstanteInterface {
    id: string;
    mesureAt: string;
    value: ConstanteValueInterface
}

interface ConstanteValueInterface {
    pni: { d: pniInterface, g: pniInterface };
    pouls: { d: number, g: number };
    masse: number;
    temp: number;
    comment: string;
}

interface pniInterface {
    s: number;
    d: number;
}


export const Constantes$ = new BehaviorSubject<ConstanteInterface[] | null>(null)
export const Constante$ = new BehaviorSubject<ConstanteInterface | null>(null)


export default class ApiConstantes {
    private _idb!: Idb<'constantes'>
    private _http!: Http
    constructor() {
        this._idb = new Idb('constantes')
        this._http = new Http()
    }

    getAll = (): void => {
        this._idb.getAll().then(data => Constantes$.next(data)).then(() =>
            this._http.getAll().then(resp => {
                if (resp.status === 200) {
                    resp.json().then((json: ConstanteInterface[]) => {
                        return this._idb.posts(json)
                    }).then(() => this._idb.getAll().then(data => Constantes$.next(data)))
                }
            }))
    }
    get = (id: ConstanteInterface['id']): void => {
        this._idb.get(id).then(data => data && Constante$.next(data))
        this._http.get(id).then(resp => {
            if (resp.status === 200) {
                resp.json().then((data: ConstanteInterface) => {
                    this._idb.post(data)
                    Constante$.next(data)
                })
            }
        })
    }
    put = async (data: ConstanteInterface): Promise<void> => {
        const oldData = Constante$.getValue();
        this._http.put(data).then(resp => {
            if (resp.status === 200) {
                const newData = { ...oldData, ...data };
                this._idb.post(newData).then(() => {
                    this._idb.getAll().then(d => d.length > 0 && Constantes$.next(d))
                    Constante$.next(newData)
                })

                return
            } else {
                Constante$.next(oldData)
                return
            }

        })
    }
    post = async (): Promise<ConstanteInterface['id']> => this._http.post().then((resp) => {
        if (resp.status === 200) {
            return resp.json().then((json: ConstanteInterface) => {
                this._idb.post(json)
                Constantes$.next([...(Constantes$.getValue() as ConstanteInterface[] || []), json])
                Constante$.next(json)
                return json.id
            })
        } else {
            return ''
        }
    })
    delete = async (id: ConstanteInterface['id']): Promise<void> => {
        const oldValue = Constantes$.getValue() as ConstanteInterface[];
        const newValue = oldValue.filter(f => f.id !== id)
        Constantes$.next([...newValue])
        this._http.delete(id).then(resp => {
            if (resp.status === 204) {
                this._idb.delete(id)
            } else {
                Constantes$.next([...oldValue])
            }
        })
    }
}

namespace ApiConstantes { /* empty */ }