import { CarnetInterface, JournalInterface } from "../../useApp";
import fetchUtil from "./_fetchUtil";

export default class Http {
    private _fetch = new fetchUtil()

    post = (carnetId: CarnetInterface['id']): Promise<Response> => this._fetch.post(`/api/sante/carnets/${carnetId}/journaux/`)

    put = async (journalId: JournalInterface['id'], data: Partial<JournalInterface>): Promise<Response> => this._fetch.put(`/api/sante/carnets/journaux/${journalId}`, data)


    delete = (journalId: JournalInterface['id']): Promise<Response> => this._fetch.delete(`/api/sante/carnets/journaux/${journalId}`)

    // put = (data: CarnetInterface): Promise<Response> => this._fetch.put(`/api/sante/carnets/${data.id}`, data)
    // toggleEi = (journalId: CarnetInterface['id'], listId: ListItemInterface['id']): Promise<Response> => this._fetch.get(`/api/sante/carnets/journaux/${journalId}/list/${listId}`)

}